import React from 'react';
import PricingCard from '../Card/PricingCard';
import Blob from './Blob';

const firstVisitPricing = [
  {
    duration: '70 minutes',
    price: '$159',
  },
  {
    duration: '100 minutes',
    price: '$229',
  },
];

const subsequentPricing = [
  {
    duration: '60 minutes',
    price: '$139',
  },
  {
    duration: '90 minutes',
    price: '$209',
  },
];

const MassagePricing = ({ pricing, route }) => {
  return (
    <div className='landing-component secondary pricing-wrapper'>
      <h2 className='landing-title'>Pricing</h2>
      <Blob />
      <div className='pricing'>
        <div className='pricing-heading'>
          <h4>First Treatment</h4>
          <p>{pricing.firstDescription}</p>
        </div>
        <div className='pricing-cards-wrapper'> 
          {firstVisitPricing.map((item, idx) => <PricingCard route={route} card={item} key={idx} />)}
        </div>
        <div className='pricing-heading'>
          <h4>Subsequent Treatments</h4>
          <p>{pricing.subsequentDescription}</p>
        </div>
        <div className='pricing-cards-wrapper'> 
          {subsequentPricing.map((item, idx) => <PricingCard route={route} card={item} key={idx} />)}
        </div>
      </div>
    </div>
  )
};

export default MassagePricing;