const selfcareData = [
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588877725/How_to_self_massage_upper_back.png',
    title: 'Self-Massage For Upper Back Pain',
    instructor: 'Jessica Noskiewicz',
    url: 'https://player.vimeo.com/video/416956358',
    description: [
      'Got upper back pain? Grab a lacrosse, tennis, yoga tune-up, or acuball! Jessi will teach you how to target the rhomboids specifically with this easy self-massage move for trigger point release.',
      'What is a trigger point? A trigger point is a hypertonic band of muscle that most people classify as “muscle knots!',
    ],
    supplies: [
      'a lacrosse, tennis, yoga tune-up, or acuball'
    ],
    suggestions: [
      'How often/how long? You can hold pressure on a trigger point for up to a minute. (maximum of 2 minutes overall)',
      'Avoid massaging bone during this technique',
      'If it makes your pain worse or changes the pain worse stop immediately',
      'Make sure to wait 24 hours between self-massages',
      'If you are pregnant consult with your healthcare provider before starting this technique',
    ],
    duration: '04:47',
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588878360/C-Section_Scar.jpg',
    title: 'How To Massage Your C-Section Scar',
    instructor: 'Lindsay Finnie',
    url: 'https://player.vimeo.com/video/415718569',
    description: [
      'If you have a c-section scar massage can be a great way to help with healing! Massaging your scare will help easy physical pain in your body and can also improve the appearance of the scar.',
    ],
    suggestions: [
      'When can you begin? You can start massaging your scar once it is completely healed and your healthcare provider has given you the green light. Usually 6-8 weeks.',
      'Is it ever too late to start massage on a scar? Massaging your scar can still be effective even decades later.',
      'How much pressure should I use? A medium amount of pressure.',
      'How often/how long? You should massage your scar for 5 minutes 3-5 times per week for best results.',
    ],
    duration: '04:19',
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588978342/chin_tuck.png',
    title: 'Chin Tuck To Prevent Forward Head Posture (Correct Mommy Hunch)',
    instructor: 'Jessica Noskiewicz',
    url: 'https://player.vimeo.com/video/416462010',
    description: [
      `Forward head posture is caused by slouching, poor posture, texting, breastfeeding, etc.`,
      'Did you know your head weighs 10 pounds? The further your head hangs forward the more weight it puts on the vertebrae in your spine - over time this can lead to long term damage.',
      'This quick exercise will help to correct forward head posture and will relieve pain in your neck and back.'
    ],
    suggestions: [
      'Start with 10 repetitions',
      'Perform 3-5 times a day or until you feel fatigued. ',
    ],
    duration: '02:54',
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588886731/How_to_fix_pelvic_pain.png',
    title: 'Self-Massage For Pelvic Girdle Pain (During Pregnancy And Postpartum)',
    instructor: 'Lindsay Finnie',
    url: 'https://player.vimeo.com/video/415740892',
    description: [
      `Learn more about why you're experiencing pelvic girdle pain during your pregnancy and postpartum.`,
      'Pelvic girdle pain can be described as discomfort in your groin region, hips, and lower back. Lindsay will teach you an easy self-massage technique that will show you how to manage it!',
    ],
    suggestions: [
      'How often/how long? You can massage this area for up 5 minutes 3-5 times per week for best results.',
      'Avoid massaging bone during this technique',
      'If it makes your pain worse or changes the pain worse stop immediately',
      'Make sure to wait 24 hours between self-massages',
      'If you are pregnant consult with your healthcare provider before starting this technique',
    ],
    supplies: [
      'a lacrosse, tennis, yoga tune-up, or acuball.',
    ],
    duration: '02:49',
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588972435/carpal_tunnel.png',
    title: 'How To Prevent And Treat Carpal Tunnel Syndrome',
    instructor: 'Patricia MacNeil',
    url: 'https://player.vimeo.com/video/416460627',
    description: [
      'Carpal tunnel syndrome is a common condition that causes pain, numbness, and tingling in the hand and arm. It is a very common condition for prenatal and postnatal mamas.',
      'The first stretch in this series helps to open up the anterior chest, neck, and shoulders relieving pressure on nerves supplying the wrist.'
    ],
    duration: '06:19',
    suggestions: [
      'Start with sets one set of 5 repetitions.',
      'Repeat 3 times per week.'
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588980043/chest_opening_stretch.png',
    title: 'Chest Opening Stretch For Moms (Pregnancy and Postpartum Stretch)',
    instructor: 'Lindsay Finnie',
    url: 'https://player.vimeo.com/video/415697720',
    description: [
      'Stop rounded shoulders! This exercise will teach you how to improve your posture and lengthen your pectoral (muscles in your chest!)',
    ],
    supplies: [
      'a yoga bolster OR',
      'beach towel or large blanket (rolled lengthwise)',
    ],
    duration: '03:04',
    suggestions: [
      'How often/how long? Perform every day or every other day try to perform for at least 45 seconds for the best results. It is safe for you to lay on your bolster/towel for as long as feels good for you up to 5 minutes!',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588980920/lower_back.png',
    title: 'How To Self-Massage Your Lower Back',
    instructor: 'Lindsay Finnie',
    url: 'https://player.vimeo.com/video/416449995',
    description: [
      'Low back pain is something almost everyone faces at some point! Learn how to target the QL muscle specifically with this easy self-massage move.',
    ],
    supplies: [
      'a lacrosse, tennis, yoga tune-up, or acuball',
      'yoga mat for comfort',
    ],
    duration: '03:31',
    suggestions: [
      'How often/how long? You can perform this technique for up to 3 minutes per side 3x/week',
      'Avoid massaging bone during this technique',
      'If it makes your pain worse or changes the pain worse stop immediately',
      'Make sure to wait 24 hours between self-massages',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588981757/sore_boobs_massage.png',
    title: 'How To Self-Massage Sore Boobs',
    instructor: 'Christina Gouveia',
    url: 'https://player.vimeo.com/video/416047508',
    description: [
      `Let's talk breasts! Feeding struggles? Gorging? Sore? super tender? Every mama goes through this at some point in time.`,
      'In this video breast specialist and RMT, Christina teaches you how to massage your breasts',
    ],
    duration: '04:08',
    suggestions: [
      'How much pressure should I use? Use a gentle pressure like you’re trying to brush some water off a flat surface.',
      'How often/how long? Perform for no longer than 10 minutes at a time no more than 5 times per 24-hour period. You need to make sure to give the breast and surrounding tissue time to adapt.',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588982160/massage_using_toys.png',
    title: 'Self-Massage Using Baby Toys And Random Stuff',
    instructor: 'Christina Gouveia',
    url: 'https://player.vimeo.com/video/415965200',
    description: [
      `You don't need fancy tools to do a self-massage. Christina RMT teaches you how to safely massage yourself using things you already have kicking around the house.`,
    ],
    duration: '03:36',
    supplies: [
      'a few baby toys, a toothbrush holder'
    ],
    suggestions: [
      'How often/how long? I suggest performing self-massage for 10 minutes max. It’s really to the point where you feel relief.',
      'What if I find a muscle knot? If you find a trigger point (muscle knot) you can hold pressure for up to a minute (maximum of 2 minutes overall)'
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588982829/guasha_neck.png',
    title: 'How To Use A Gua Sha Stone To Massage Your Neck',
    instructor: 'Sarah Rennick',
    url: 'https://player.vimeo.com/video/415201060',
    description: [
      `Massaging your neck with a gua sha stone can be a great way to reduce tension through deep tissue massage and relieve trigger points (muscle knots).`,
      'Sarah walks you through how to use oil and a gua sha stone to perform a great self-massage at home.'
    ],
    supplies: [
      'Gua sha stone - we recommend rose quartz. If you need a gua sha stone shoot us an email and we can hook you up!'
    ],
    duration: '04:00',
    suggestions: [
      `How often/how long?I suggest performing self-massage for 10 minutes max. It’s really to the point where you feel relief. `,
      'What if I find a muscle knot? If you find a trigger point (muscle knot) you can hold pressure for up to a minute (maximum of 2 minutes overall)'
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588983303/guasha_face.png',
    title: 'How To Use A Gua Sha Stone To Massage Your Face',
    instructor: 'Sarah Rennick',
    url: 'https://player.vimeo.com/video/415163998',
    description: [
      'Gua sha facial massage has existed in traditional Chinese medicine for centuries. The techniques in this video will reduce inflammation in the face (swelling) boosting lymphatic drainage! This leads to glowing healthy skin.',
      'These techniques are also fantastic for anti-aging by lengthening shortened muscles that led to wrinkles over time.',
      'Sarah walks you through how to use oil and a gua sha stone to perform a great self-massage at home.'
    ],
    supplies: [
      'Gua sha stone - we recommend rose quartz. If you need a gua sha stone shoot us an email and we can hook you up!',
      'Oil - I recommend a cold-pressed cooking oil like coconut oil, grapeseed oil, rosehip oil, etc.'
    ],
    duration: '05:53',
    suggestions: [
      'How often/how long? I suggest performing gua sha facial massage for 3-10 minutes daily.',
      'Should I put the oil over or under my moisturizer?  Always put oil over your moisturizer.',
      'Please note* Your skin may appear a bit red after using the gua sha stone- this is due to increased circulation and blood flow, you may prefer to do this massage before bed.',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588983628/facial_massage.png',
    title: 'How To Massage Your Face',
    instructor: 'Sarah Rennick',
    url: 'https://player.vimeo.com/video/414952880',
    description: [
      'These techniques are fantastic for anti-aging by lengthening shortened muscles that led to wrinkles over time.',
      'Sarah walks you through how to use oil and your own two hands to perform a great self-massage at home.',
    ],
    supplies: [
      'Oil - I recommend a cold-pressed cooking oil like coconut oil, grapeseed oil, rosehip oil, etc.'
    ],
    duration: '05:08',
    suggestions: [
      'How often/how long? I suggest performing facial massage for 3-10 minutes daily.',
      'Should I put the oil over or under my moisturizer?  Always put oil over your moisturizer.',
      'Please note* Your skin may appear a bit red after your massage- this is due to increased circulation and blood flow, you may prefer to do this massage before bed.'
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588983976/meditation.png',
    title: '10 Minute Anywhere Meditation',
    instructor: 'Rebecca Eves',
    url: 'https://player.vimeo.com/video/416519649',
    description: [
      'This guided meditation was created for busy mamas just like you!',
      'Meditation has been scientifically proven to have such benefits as... controlling anxiety, lowering stress, enhancing self-awareness, boosting happiness and so much more.',
      'Sneaking in this mini-meditation is the ultimate act of self-care.',
    ],
    supplies: [
      'Nothing! Try not to overcomplicate the process because chances are you will talk yourself out of it. Grab some headphones and just start.'
    ],
    duration: '10:46',
    suggestions: [
      'What if my mind wanders? That’s totally normal, be kind to yourself, and remember taking this time for you is the perfect step.',
      'How often should I meditate?  Try to build this into your daily routine. Stay tuned for future meditations.',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1589076221/feet_massage.png',
    title: 'How To Self-Massage Your Feet',
    instructor: 'Sarah Rennick',
    url: 'https://player.vimeo.com/video/416541733',
    description: [
      `This video is helpful for those suffering from or looking to prevent pain from fallen arches, plantar fasciitis, or any general foot pain.`,
      `The weakening of the muscles, tendons, and ligaments that normally support the foot's arch can lead to fallen arches.`,
      `During pregnancy, this is very common. It can happen from the additional weight of the baby and the relaxing of your muscles and joints in preparation for childbirth.`,
    ],
    supplies: [
      'a golf ball or small size massage ball'
    ],
    duration: '01:29',
    suggestions: [
      'Avoid massaging bone during this technique.',
      'If it makes your pain worse or changes the pain worse stop immediately.',
      'Make sure to wait 24 hours between self-massages.',
      'If you are pregnant consult with your healthcare provider before starting this technique.',
      'How often/how long? I suggest performing self-massage on the foot for 3 minutes each. It’s really to the point where you feel relief.',
      'What if I find a muscle knot? If you find a trigger point (muscle knot) you can hold pressure until you feel relief  (for up to a minute).',
    ],
  },
  {
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1589076946/wall_angle_slide.png',
    title: 'Wall Angle Slide To Correct Rounded Shoulders',
    instructor: 'Jessica Noskiewicz',
    url: 'https://player.vimeo.com/video/415948551',
    description: [
      'This quick exercise will help to correct forward hunched forward posture and will, in turn, relieve pain in your neck and back.',
      'The focus of this exercise to strengthen the mid-back and correct rounded shoulders.',
    ],
    duration: '03:15',
    suggestions: [
      'How often/how long? I suggest starting with sets of 10 reps 1-2 times/day or until fatigue.',
    ],
  },
];


export default selfcareData;