import React from 'react';
import './Polaroid.css';

const Polaroid = ({ image, alt, quote }) => (
  <div className='polaroid'>
  <img src={image} alt={alt} />
  <div className='polaroid-footer'>
    <h5>{quote}</h5>
  </div>
</div>
);

export default Polaroid;