import React from 'react';
import { useParams } from 'react-router-dom';
import MassageLanding from './MassageLanding';
import PelvicLanding from './PelvicLanding';

const ServiceLanding = () => {
  const { stage, service } = useParams();
  
  return (
    <div>
      {
        service === 'massage' ? <MassageLanding /> : <PelvicLanding />
      }
    </div>
  )
}

export default ServiceLanding;