import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './Hero.css';
import { Button } from '@material-ui/core';
import { AuthUserContext } from '../../context';
import Polaroid from '../Polaroid/Polaroid';

const HeroPolaroid = ({ image, alt, title, buttonText, quote, description, route }) => {
  return (  
    <div className='hero-polaroid-wrapper'>
      <div className='hero hero-polaroid'>
        <div className='hero-body'>
          <h1>{title}</h1>
          <h2>{description}</h2>
          <a href={route}>
            <Button variant="outlined" color="primary">
              {buttonText}
            </Button>
          </a>
        </div>
        <Polaroid image={image} alt={alt} quote={quote} />
      </div>
      <div className='wave-wrap secondary'>
        <div className='wave-inner'></div>
      </div>
    </div>
  )
};

export default HeroPolaroid;