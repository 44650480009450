import React from 'react';
import SignUpForm from './SignUpForm';
import './Login.css';
import { useParams } from "react-router-dom";

const SignUp = () => {
  let { email } = useParams();

  return (
    <div className='page-component' id='login-page'>
      <div id='login-wrapper'>
        <div id='login'>
          <h1>Welcome to Mama Learning</h1>
          <h5>Please create an account to access your courses</h5>
          <p>You will use your email - <b>{email}</b> to log into your account - if you already have an account, you can go straight to the learning tab!</p>
          <SignUpForm email={email} />
        </div>
      </div>
    </div>
  )
}

export default SignUp;