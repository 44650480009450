import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import SEO from '../../components/SEO/SEO';
import './Giftcard.css';

class GiftCard extends React.Component {
  componentWillMount () {
    const existingScript = document.getElementById('giftCardScript');
    if (existingScript) {
      window.location.reload();
    }
  }

  componentDidMount () {
    const existingScript = document.getElementById('giftCardScript');

    if (!existingScript) {
      let script = document.createElement("script");
      script.src = 'https://cdn.giftup.app/dist/gift-up.js';
      script.id = 'giftCardScript'
      script.async = true;
      let f = document.getElementsByTagName('script')[0];
      f.parentNode.insertBefore(script, f);
    }
  }

  render () {
    return (
      <div className='page-component' id='gift-card'>
        <SEO 
          title={`Gift Card | Mama Mobile`}
          description="Spoil a mom with a Mama Mobile giftcard. She can choose from an in-home massage, online pelvic floor physiotherapy, or counselling."
          url={`gift-card`}
        />
        <ScrollToTop />
        <h1>SEND A MAMA SOME LOVE!</h1>
        <div className="gift-up-target" data-site-id="b4505f6e-76b7-4266-baf7-997157d45350" data-platform="Other" />
      </div>
    )
  }
}

export default GiftCard;