import React, { useContext } from 'react';
import IllustrationHero from '../../components/Hero/IllustrationHero';
import Quote from '../../components/Landing/Quote';
import Services from '../../components/Landing/Services';
import Reviews from '../../components/Landing/Reviews';
import Blog from '../../components/Landing/Blog';
import Locations from '../../components/Landing/Locations';
import { AuthUserContext } from '../../context';
import { useHistory, useParams } from 'react-router-dom';
import UpcomingWorkshops from '../../components/Landing/UpcomingWorkshops';
import { prenatalWorkshops, postnatalWorkshops, busymamaWorkshops } from '../../util/upcomingWorkshops';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import SEO from '../../components/SEO/SEO';

const data = {
  prenatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595535819/prenatal.png',
      title: `Let’s make your pregnancy a whole lot more enjoyable with in-home prenatal massages and virtual pelvic floor physiotherapy.`,
      buttonText: 'Book Now'
    }, 
    quote: {
      main: [
        `You just started the amazing `,
        {
          type: 'strike-through',
          content: `and exhausting`,
        },
        ` journey of motherhood. We provide the best hard-to-find services for mamas and have curated them to fit into your busier than ever life.`,
      ],
      sub: `Lean on us, we got you!`
    },
    reviews: [
      {
        review: 'If Only I can give 10 Stars! I am in my 3rd trimester and started to experience lower back and some other muscle pains so having a Prenatal Massage ... plus at your own place & comfort is just amazing. I am beyond glad to have known Mama Mobile Massage from the Baby Show.',
        name: 'Charmae Mancego',
        source: 'Google Review',
        link: 'https://goo.gl/maps/yxmdr4xcXtT1ApDz9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597173086/Untitled_design_4_4_l1k5wc.png',
        alt: 'Ottawa in-home prenatal massage',
        type: 'image'
      },
      {
        review: `We’ve had Mama Mobile come to our home a few times already, during pregnancy and post-partum. They came with all the equipment & special pillows for my belly (during pregnancy) and to accommodate my breasts (from nursing). It’s so nice to stay in the comfort of my own home in such a vulnerable time to have a massage.`,
        name: 'Josianne Krucker',
        source: 'Google Review',
        link: 'https://goo.gl/maps/7XZEd4qhfYLycCUv6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Ontario in-home prenatal massage',
        type: 'image'
      },
      {
        review: `Five stars! I’m 37 weeks pregnant and have been dealing with serious chronic pain. It’s so amazing to have a massage in my own home from someone who has speciality training in prenatal massage. Sarah is so lovely and friendly, asked lots of thoughtful questions and made sure I was comfortable and really helped to alleviate pain and discomfort. Highly recommended!!`,
        name: 'Kaitlin Mackenzie',
        source: 'Google Review',
        link: 'https://goo.gl/maps/eG61oycAZ3q8nihz9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_29_v4iopz.png',
        alt: 'mama mobile in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I’ve been feeling a lot of pain and uncomfortable sensations throughout this pregnancy. I feel more relaxed and plan on getting a few more prenatal massages before I moved to postnatal massages.  It's so convenient to have them come to your home especially the fact that I'm still working. It felt as if I were at a spa.`,
        name: 'Krystelle Patag',
        source: 'Google Review',
        link: 'https://goo.gl/maps/xvuc92tuLQvCgGj59',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_25_iafjc9.png',
        alt: 'Toronto in-home prenatal massage',
        type: 'image'
      },
      {
        review: `Amazing if you're pregnant or have a little one.`,
        name: 'Kiruthiga Sinnarajah',
        source: 'Google Review',
        link: 'https://goo.gl/maps/dAtCViE2bqmZybAB7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_20_pvosyc.png',
        alt: 'Brampton in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I had Samantha yesterday evening for my prenatal massage and it was absolute perfection.  She was on time, explained everything and I had such a great feeling afterward. She even took some time at the end for self-care. Loved her attitude and personality! Would definitely recommend Mama Mobile Massage to anyone else.`,
        name: 'Givy Pavananthan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GXaU5MDtbq3TcQQB9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_24_plc1fn.png',
        alt: 'Mississauga in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I was delighted to hear that there was a service which comes to your home to provide a massage for pregnant woman. I have two dogs and in the middle of moving and she worked around the mess and made me feel very comfortable. I recommend this service to anyone especially expecting moms.`,
        name: 'Monika P',
        source: 'Google Review',
        link: 'https://goo.gl/maps/EqPNmD23EiFnrjgN7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_15_nx4mtx.png',
        alt: 'Ottawa prenatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `At 32 weeks, it takes a lot to feel truly relaxed and this did just the trick :o)`,
        name: 'Colleen McGurran',
        source: 'Google Review',
        link: 'https://goo.gl/maps/rA8jYfk91gwwTPDE6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Toronto prenatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `This service is amazing. Not only is it SO convenient - they come right to you - the RMTs are so professional, skilled, patient, kind and overall great at what they do! I truly look forward to this massage every few weeks. It was also very important to me to get a massage from an RMT with experience in prenatal and MMM definitely met my expectations in that regard. I feel lucky to have found them and would highly recommend!`,
        name: 'Miriam Anbar',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GsM2ETN7sc7VdTSKA',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_10_qulce0.png',
        alt: 'Ontario prenatal pelvic floor physiotherapy',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What To Expect During Your First Prenatal Massage',
        type: 'prenatal massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587009574/mama_experience.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
  },
  postnatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595536036/postnatal.png',
      title: `Let’s sprinkle more selfcare into your new normal with in-home postnatal massages and virtual pelvic floor physiotherapy.`,
      buttonText: 'Book Now'
    }, 
    quote: {
      main: [
        `You just started the amazing `,
        {
          type: 'strike-through',
          content: `where is the f*ck is my time going`,
        },
        ` journey of motherhood. We provide the best hard-to-find services for mamas and have curated them to fit into your busier than ever life.`,
      ],
      sub: `Lean on us, we got you!`
    },
    reviews: [
      {
        review: 'The thought of getting a babysitter, organizing milk, getting dressed, driving to get a massage made it so unappetizing to me. Until I had a pretty bad injury that I needed fixed. Then I discovered mama mobile. Not only did I appreciate the convenience of not leaving my home, I got an amazing relaxing massage and MOST importantly an extremely competent RMT that resolved my ongoing injury! I am a fan for LIFE! oh and the HUGE win was they finished before the kids finished their nap!!',
        name: 'Akira Seuradge',
        source: 'Google Review',
        link: 'https://goo.gl/maps/hwSneTaofJwf66Xj9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380238/reviews/review_images_1_8_putlvc.png',
        alt: 'Toronto in-home postnatal massage',
        type: 'image'
      },
      {
        review: `She focused on all of the right areas, and payed special attention to those problem some areas that come from holding and nursing a baby all day. I have never felt more relaxed during and after receiving a massage and then being able to melt right onto your bed after is the cherry on top! I am so grateful for Mama Mobile and am so impressed with everything they encompass as a company. Thank you for giving this Mama exactly what she needed!!!`,
        name: 'Mighty Mom Movement',
        source: 'Google Review',
        link: 'https://goo.gl/maps/APzcPZvMScgTuUXZ7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_5_gwbkvo.png',
        alt: 'Ottawa in-home postnatal massage',
        type: 'image'
      },
      {
        review: `My baby is a month old and without a set schedule yet for nap times or feedings, it’s so hard to predict when a good time to go out or book a specific time that I know will work yet. This service made it so easy to schedule a session as we were told that extra time is allotted for feedings or fussiness so I felt comfortable knowing there would be some flexibility there and we’re at home with all the conveniences baby may need and where we’re all most comfortable.`,
        name: 'ModellaMedia',
        source: 'Google Review',
        link: 'https://goo.gl/maps/jbvkbxPnP86bx5X16',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/google_review_1_iloco9.png',
        alt: 'Mississauga in-home postnatal massage',
        type: 'image'
      },
      {
        review: `I’ve been feeling a lot of pain and uncomfortable sensations throughout this pregnancy. I feel more relaxed and plan on getting a few more prenatal massages before I moved to postnatal massages.  It's so convenient to have them come to your home especially the fact that I'm still working. It felt as if I were at a spa.`,
        name: 'Krystelle Patag',
        source: 'Google Review',
        link: 'https://goo.gl/maps/xvuc92tuLQvCgGj59',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597173086/Untitled_design_4_4_l1k5wc.png',
        alt: 'Ontario in-home postnatal massage',
        type: 'image'
      },
      {
        review: `One word: LIFESAVER! With an old/recurring back injury, a busy lifestyle with a rambunctious kid and a new baby, I was in a lot of pain and yet couldn’t find the time to get it treated.  That was, until I stumbled across mama mobile massage!  The fact that an rmt will come to your house, at a time convenient to you, meaning you don’t have to drive somewhere, figure out what to do with your kids, only operate during business hours... game-changer for moms, seriously!`,
        name: 'Andria Hinton',
        source: 'Google Review',
        link: 'https://goo.gl/maps/Dbfxwwte4FWo9C427',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Ottawa postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I had Samantha yesterday evening for my prenatal massage and it was absolute perfection.  She was on time, explained everything and I had such a great feeling afterward. She even took some time at the end for self-care. Loved her attitude and personality! Would definitely recommend Mama Mobile Massage to anyone else.`,
        name: 'Givy Pavananthan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GXaU5MDtbq3TcQQB9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_21_u1hejr.png',
        alt: 'Toronto postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I’ve used mama mobile services a few times and am continuously blown away by the company. The therapists are professional and friendly and give great massages! I’m home with an infant and this is such a great way to get some self-care. I highly recommend mama mobile massage.`,
        name: 'Rachel',
        source: 'Google Review',
        link: 'https://goo.gl/maps/C6o6zKKALmd1Gg7r6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_24_plc1fn.png',
        alt: 'Mississauga postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `This company is so amazing that they support mamas who might not be able to make an appointment for a massage because we are at home with our little ones. Katelyn was friendly, checked in with me, and so respectful of me needing to tend to my baby during the massage at any time. I’ve recommended this to all my mama friends and will be continuing this as part of my self-care plan.`,
        name: 'Nicole W',
        source: 'Google Review',
        link: 'https://goo.gl/maps/ibDW4krZ92yQfKUH7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Ontario postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `My husband and I are adjusting to our 10 day old son, and after the thralls this new little man including the labour, nursing, and diaper changes we were both feeling the need for knead. We got to tag team the baby while the other got relief and relaxation.`,
        name: 'Kathryn Douthart',
        source: 'Google Review',
        link: 'https://goo.gl/maps/V5V8EKvf4hzwV3xb8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_27_gliroz.png',
        alt: 'Brampton postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `this service is amazing! super convenient, easy booking, and helpful staff that make it a great experience. their RMTs are professional and excellent at what they do. both my husband and i had massages a few months into being postpartum and we were impressed. highly recommend and can't wait for our next massage!`,
        name: 'Michelle Halligan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/1QoweMoKiZFLkqn48',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_14_ojvv1o.png',
        alt: 'Toronto in-home postnatal massage',
        type: 'image'
      },
      {
        review: `Great for moms who have a hard time getting out of the house. I had a great massage with Jessi who showed up on time and gave me a great massage after my baby had gone to bed for the night. What a dream! 100% would recommend.`,
        name: 'Marina Kamalova',
        source: 'Google Review',
        link: 'https://goo.gl/maps/uEyTxRNV6QdFFqzZ8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_13_gxclsl.png',
        alt: 'Ottawa in-home postnatal massage',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What To Expect During Your First Postpartum Massage',
        type: 'Postnatal Massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183693/postnatal_massage.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
  },
  busymama: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595536704/busymama.png',
      title: `Let’s sprinkle more selfcare with in-home massages and virtual pelvic floor physiotherapy.`,
      buttonText: 'Book Now'
    }, 
    quote: {
      main: [
        `We provide the best selfcare services that fit into your busy life. Whether it’s around work, between soccer practices, or a late night glass of wine in hand.`,
      ],
      sub: `Lean on us, we got you!`
    },
    reviews: [
      {
        review: `I'm so happy I found mama mobile. This is an amazing service for any parent who feels that they cannot take time out of their busy schedules to take care of themselves, and who would appreciate maintaining their health from within the comfort of their home.`,
        name: 'Mélanie Pronovost',
        source: 'Google Review',
        link: 'https://goo.gl/maps/6YZ73K7gCy971RV57',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Toronto in-home massage for moms',
        type: 'image'
      },
      {
        review: `I enjoyed being in the comfort of my own home with my newborn. Anjulee set up her work station quickly and made it super comfortable with soft blankets and calming aromas. She was very personable and interacted well with myself and adolescent children. I will definitely be doing this again.`,
        name: 'Kelly Koenig',
        source: 'Google Review',
        link: 'https://goo.gl/maps/9C2UgxpKFf515KGM9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Ottawa in-home massage for moms',
        type: 'image'
      },
      {
        review: `Angela was on time and came prepared with everything to make the massage perfect. She had read my intake form and asked intelligent questions and my issues and most importantly gave me a fantastic massage! Love the convenience of not having to find a babysitter to get an amazing massage!`,
        name: 'Melanie Greenspoon',
        source: 'Google Review',
        link: 'https://goo.gl/maps/42HeYd2JzytsUJKp6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_3_ggoo1x.png',
        alt: 'Mississauga in-home massage for moms',
        type: 'image'
      },
      {
        review: `The massage itself was as good as any spa massage. But the really amazing part was to have it in my home. Kids were in bed, I didn't have to leave the comfort of my robe and a glass of wine and hot shower were waiting for me as soon as I was done. Will definitely book again!`,
        name: 'Lauren Binks',
        source: 'Google Review',
        link: 'https://goo.gl/maps/kmcKqTJovvodNUTk7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_6_ehxy5t.png',
        alt: 'Ontario in-home massage for moms',
        type: 'image'
      },
      {
        review: `I love Mama Mobile. I have had consistently stellar service. Every therapist they have sent has been awesome. Great technical skills, friendly and punctual. Really enjoy their massages!`,
        name: 'Dr. Dina Kulik',
        source: 'Google Review',
        link: 'https://goo.gl/maps/YUmWVMC8c5LRGWWn6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_19_rsdjd8.png',
        alt: 'Ottawa pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `Love this company! All of the RMTs are wonderful and really take great care in listening to my problem areas and give me great after-care advice. As a busy mom, having them come to me is so convenient. Booking appointments online is so simple, and they have excellent customer service. I’ve recommended them to many family and friends.`,
        name: 'Jennifer Mach',
        source: 'Google Review',
        link: 'https://goo.gl/maps/CmXiBq4Mq3ZTmuGG9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Toronto pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I am always ranting and raving to friends and family how genius this service is! Getting a massage in the comfort of your own home, even at late hours like 8 or 9pm. The RMTs are very professional and friendly. The entire experience is always great. I plan to continue to use them for all future massages.`,
        name: 'Simply Stunning Decor and Events',
        source: 'Google Review',
        link: 'https://goo.gl/maps/8usE7K77VU9rtgkU8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_16_owyzzm.png',
        alt: 'Mississauga pelvic floor physiotherapy',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What to expect during your first in-home massage experience: For busy mamas and partners',
        type: 'Busy Mama Massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183693/postnatal_massage.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
  }
}

const seo = {
  postnatal: {
    title: 'Postnatal',
    description: 'In-home postnatal massage, online pelvic floor physiotherapy and counselling to support a mom through her postpartum recovery.',
  },
  prenatal: {
    title: 'Pregnant',
    description: 'In-home prenatal massage, online pelvic floor physiotherapy and counselling to support a mom through her pregnancy journey.',
  },
  busymama: {
    title: 'Busy',
    description: 'In-home massage, online pelvic floor physiotherapy and counselling to support a mom through her journey of motherhood.',
  },
}

const StageLanding = () => {
  const Auth = useContext(AuthUserContext);
  const history = useHistory();
  const { stage } = useParams();
  let workshops = prenatalWorkshops;
  
  if (!Auth.stage) {
    history.push('/');
  }

  if (stage === 'postnatal') {
    workshops = postnatalWorkshops;
  } else if (stage === 'busymama') {
    workshops = busymamaWorkshops;
  }

  return (
    <div>
      <SEO 
        title={`Services for ${seo[stage].title} Moms in Ontario | Mama Mobile`}
        description={seo[stage].description}
        url={`stage/${stage}`}
      />
      <ScrollToTop />
      <IllustrationHero 
        background={data[stage].hero.background}
        title={data[stage].hero.title}
        buttonText={data[stage].hero.buttonText}
      />
      <Quote secondary main={data[stage].quote.main} sub={data[stage].quote.sub} />
      <Services />
      <UpcomingWorkshops workshops={workshops} />
      <Reviews reviews={data[stage].reviews} />
      <Blog blogs={data[stage].blogs} />
      <Locations />
    </div>
  )
};

export default StageLanding;