import React from 'react';

const Instructor = ({ instructor }) => {

  const { img, name, title, bio } = instructor;
  return (
    <div className='learning-section'>
      <h3>Instructor</h3>
      <div className='instructor-bio-body'>
        <img src={img} alt={`${name} - ${title}`}/>
        <div>
          <p className='instructor-bio-name'>{name}</p>
          <p className='instructor-bio-title'>{title}</p>
          <p className='instructor-bio-description'>{bio}</p>
        </div>
      </div>
    </div>
  )
};

export default Instructor; 