import React from 'react';
import BlogPostHeader from './BlogPostHeader';
import './blogPost.css';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import BlogData from '../../pages/Blog/blogData';
import { withRouter } from "react-router";
import BabyMassageForm from '../Form/BabyMassageForm';
import SEO from '../SEO/SEO';

const BlogPost = ({ match }) => {
  let title = match.params.title;
  const blog = BlogData.filter(blog => blog.title.split(' ').join('-') === title)[0];

  return (
    <div>
      <SEO 
        title={`${blog.title} | Mama Mobile Blog`}
        description={`Mama Mobile Blog - ${blog.title}`}
        url={`blog/${title}`}
      />
      <ScrollToTop />
      <BlogPostHeader blog={blog} />
      <div className='blog-post-body'>
        {blog.blogPost.map(component => {
          switch(component.type) {
            case 'h3':
              return <h3>{component.content}</h3>;
            case 'br':
              return <div className='br' />;
            case 'baby_massage_form':
              return <BabyMassageForm />
            case 'p_with_link':
              return component.content.map((text, idx) => {
                if (typeof(text) === 'string') {
                  return <span key={idx} className='blog-post-p'>{text}</span>
                } else if (text.type === 'br') {
                  return <div className='br' />
                } else if (text.type === 'b') {
                  return <b key={idx}>{text.content}</b>
                }
                return <a key={idx} className='highlight' target='_blank' href={text.link}>{text.content}</a>
              });
            case 'h5':
              return <h5>{component.content}</h5>
            case 'p':
              return <p>{component.content}</p>;
            case 'p_bold':
              return <p><b>{component.content}</b></p>;
            case 'p_bold_noMargin':
            return <p className='noMargins'><b>{component.content}</b></p>;
            case 'p_noMargin':
            return <p className='noMargins'>{component.content}</p>;
            case 'p_arr':
              return component.content.map(paragraph => <p>{paragraph}</p>);
            case 'video':
              return (
                <div className='blog-post-video'>
                  <iframe style={{position:'absolute', top:0, left:0, width:'100%', height:'100%'}} src={component.content} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              )
            case 'li': 
                return component.content.map(list => <p className='blog-post-li'>{list}</p>);
            case 'img':
              return <img className='blog-post-img' src={component.content} alt={component.alt} />;
            case 'quote':
              return <h5 className='blog-post-quote'>{component.content}</h5>
              case 'a':
              return <a className='underline' href={component.content} target='_blank'>{component.text}</a>        
            case 'img-fullSize':
              return <img className='blog-post-imgFull' src={component.content} alt={component.alt} />   
            default:
              return;
          }
        })}
      </div>
    </div>
  )
}

export default withRouter(BlogPost);
