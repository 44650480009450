import React from 'react';
import List from '@material-ui/core/List';
import ListChapter from './ListChapter';

const MENU = [
  {
    title: 'Introduction',
    chapterItems: [
      {
        title: 'Welcome',
        duration: '5m 41s',
        url: 'https://player.vimeo.com/video/410726565',
      }
    ]
  },
  {
    title: 'Relaxation Routine',
    chapterItems: [
      {
        title: 'Welcome',
        duration: '2m 16s',
        url: 'https://player.vimeo.com/video/411258714'
      }, 
      {
        title: 'Feet and legs',
        duration: '7m 55s',
        url: 'https://player.vimeo.com/video/410734808'
      },
      {
        title: 'Tummy and chest',
        duration: '3m 12s',
        url: 'https://player.vimeo.com/video/410743880'
      },
      {
        title: 'Arms and hands',
        duration: '4m 24s',
        url: 'https://player.vimeo.com/video/410751071',
      },
      {
        title: 'Face, back and bum',
        duration: '4m 42s',
        url: 'https://player.vimeo.com/video/410756516',
      },
    ]
  },
  {
    title: 'Constipation Routine',
    chapterItems: [
      {
        title: 'Introduction',
        duration: '1m 10s',
        url: 'https://player.vimeo.com/video/411149165'
      },
      {
        title: 'Constipation massage - full routine',
        duration: '6m 8s',
        url: 'https://player.vimeo.com/video/410760951'
      },
      {
        title: 'Mini-Review: I Love You Technique',
        duration: '3m 10s',
        url: 'https://player.vimeo.com/video/410765284'
      }
    ]
  },
  {
    title: 'Teething Routine',
    chapterItems: [
      {
        title: 'Introduction',
        duration: '22s',
        url: "https://player.vimeo.com/video/412039994"
      },
      {
        title: 'Teething massage - full routine',
        duration: '1m 40s',
        url: 'https://player.vimeo.com/video/410772293'
      }
    ]
  },
  {
    title: 'Cold and Sinus Routine',
    chapterItems: [
      {
        title: 'Introduction',
        duration: '0m 28s',
        url: 'https://player.vimeo.com/video/411224071'
      },
      {
        title: 'Nasal congestion massage - full routine',
        duration: '1m 15s',
        url: 'https://player.vimeo.com/video/410769836'
      }
    ]
  },
];

const ListMenu = ({ setActiveVideo, closeMenu }) => {
  return (
    <List
      aria-labelledby="menu-list"
    >
      {
        MENU.map(chapter => (
          <ListChapter setActiveVideo={setActiveVideo} key={chapter.title} chapter={chapter} closeMenu={closeMenu} />
        ))
      }
    </List>
  );
}

export default ListMenu;