import React from 'react';

const STARS = [
  'https://d1qlfgph2pirrd.cloudfront.net/publicassets/icons/shapes/stars/star-100.svg',
  'https://d1qlfgph2pirrd.cloudfront.net/publicassets/icons/shapes/stars/star-100.svg',
  'https://d1qlfgph2pirrd.cloudfront.net/publicassets/icons/shapes/stars/star-100.svg',
  'https://d1qlfgph2pirrd.cloudfront.net/publicassets/icons/shapes/stars/star-100.svg',
  'https://d1qlfgph2pirrd.cloudfront.net/publicassets/icons/shapes/stars/star-80.svg',
]

const Stars = () => {
  return (
    <div className='flex' style={styles.root}>
      <div>
        {STARS.map((star, idx) => <img key={idx} style={styles.stars} src={star} alt='rating star' />)}
      </div>
      <p style={styles.text}>56 reviews</p>
    </div>
  )
}

export default Stars;

const styles = {
  root: {
    marginTop: '10px',
    marginBottom: '40px',
  },
  stars: {
    width: '18px',
  },
  text: {
    marginLeft: '10px',
    fontSize: '0.9rem',
  }
};