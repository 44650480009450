import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { FirebaseContext, AuthUserContext } from '../../context';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { NavLink } from 'react-router-dom';
import capitalize from '../../util/capitalize';
import emailCreateLearningAccount from '../../util/emailCreateLearningAccount';
import { Button } from '@material-ui/core';


const CreateAccountForm = () => {
  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [em, setEm] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthUserContext);
  const history = useHistory();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      const firstName = capitalize(first);
      const lastName = capitalize(last);
  
      const email = em.toLowerCase();
      const name = `${firstName} ${lastName}`

      const login = await Firebase.createUserWithEmailAndPassword(email, password);
      const user = await Firebase.findOrCreateUser('users', email, {email, firstName, lastName, name});
      const emailRes = await emailCreateLearningAccount({ name, email });

      localStorage.setItem('user', user.email);
      localStorage.setItem('name', user.firstName);
      Auth.setState('user', user.email);
      Auth.setState('name', user.firstName);
      setLoading(false);
      history.push('/more-info');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <>
    <form onSubmit={handleSubmit}>
      {loading && <Loading />}
      <label>FIRST NAME</label>
      <input
        type='first name'
        required
        value={first}
        onChange={e => setFirst(e.target.value)}
        placeholder='First Name'
      />
      <label>LAST NAME</label>
      <input
        type='last name'
        required
        value={last}
        onChange={e => setLast(e.target.value)}
        placeholder='Last Name'
      />
      <label>EMAIL</label>
      <input
        type='email'
        required
        value={em}
        onChange={e => setEm(e.target.value)}
        placeholder='Email'
      />
      <label>PASSWORD</label>
      <input 
        type='password'
        required
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder='Password'
      />
      <Button type='submit' variant="contained" color="secondary">Create Account</Button>
      {error && <Error errMsg={error} />}
    </form>
    <p className='sign-up-link'>
      {`Already have an account? `}
      <NavLink to='/login'>Login</NavLink>
    </p>
    </>
  )
}

export default CreateAccountForm;
