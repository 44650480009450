const FEEDBACKS = [
  {
    rating: 5,
    review: 'Great digestible info, I loved going at my own pace.',
    name: 'Susan M.',
    date: '05/25/2020',
  },  
  {
    rating: 5,
    review: "I wasn’t really sure at first if I wanted to learn baby massage online… but because of Covid, I didn't have a choice. I was pretty hesitant at first because I really wanted hands on learning... but this has exceeded my expectations because Sarah has explained and demonstrated everything so clearly in the videos. She emailed me multiple times to check-in and answered all my questions. I even printed the pdfs so I can reference everytime I massage baby Noah. Thanks Sarah.",
    name: 'Jules M.',
    date: '05/16/2020',
  },
  {
    rating: 5,
    review: 'I used to think my baby hated massage, but it turns out I just wasn’t doing the right things! Massage is now part of our bedtime routine and it makes me feel so connected to my baby.',
    name: 'Jenna T. ',
    date: '05/07/2020',
  },
  {
    rating: 5,
    review: "I actually had signed up for a course at my local rec center before but my baby was so fussy we had to cancel! Being able to learn baby massage in an online format was perfect. The best part - it has made my baby less fussy 😅.",
    name: 'Cheryl S.',
    date: '05/03/2020',
  },
  {
    rating: 4.5,
    review: 'Great content, I feel much more confident massaging my baby now',
    name: 'Jessica B.',
    date: '05/02/2020',
  },
  {
    rating: 5,
    review: 'There is SO much value in this course. so happy I found it.',
    name: 'Anna L.',
    date: '05/01/2020',
  },
  {
    rating: 5,
    review: 'Baby massage is a staple for us post bathtime! Before this course I didnt’ really know what I was doing but now I feel really confident!',
    name: 'Sarah T.',
    date: '04/30/2020',
  }, 
  {
    rating: 5,
    review: 'Baby is loving the massages! I just wish I could teach him to massage me now ;)',
    name: 'Claire B.',
    date: '04/23/2020',
  },
  {
    rating: 5,
    review: 'I like that the course has several routines. I use the sleep routine daily but LOVE having the constipation routine in my back pocket for times we need it.',
    name: 'Melissa T.',
    date: '04/18/2020',
  },
  {
    rating: 4,
    review: 'The 5-minute chunks really helps - we did a little bit each day',
    name: 'Paige R.',
    date: '04/17/2020',
  },
  {
    rating: 5,
    review: 'I wish I found this course with my first baby! We took an in person course actually but it didn’t cover as much stuff so I decided to take this. It was worth it!!',
    name: 'Sammy C.',
    date: '04/12/2020',
  },
  {
    rating: 5,
    review: 'My baby had a really stuffy nose so I tried the routine for cold and sinus today- it was like magic! Thank you!',
    name: 'Samantha C.',
    date: '04/11/2020',
  },
  {
    rating: 5,
    review: 'Mama Mobile is the best. Thank you!',
    name: 'Allison K.',
    date: '04/09/2020',
  },
  {
    rating: 5,
    review: "I love the instructor! Her instructions are so clear and she even emailed me personally to check-in. I felt like I had a personal baby massage coach lol.",
    name: 'Lucy T.',
    date: '04/08/2020',
  }, 
  {
    rating: 5,
    review: 'New moms need this course! I feel really safe and empowered massaging my baby now.',
    name: 'Steph A.',
    date: '04/02/2020',
  },
  {
    rating: 5,
    review: 'Easy to understand.',
    name: 'Lily K.',
    date: '03/30/2020',
  },
  {
    rating: 5,
    review: 'Even though this was online I felt super supported. The instructor was so helpful and checked in with me! I was able to ask a few more questions to her directly. Would recommend.',
    name: 'Cheryl P.',
    date: '03/29/2020',
  },
  {
    rating: 5,
    review: 'I was a bit skeptical at first to take a baby massage course online but due to COVID I decided to give it a shot. It was so easy to follow (I did a bit each day) and I really loved the instructor. I’m really happy I did this!',
    name: 'Anna Z.',
    date: '03/23/2020',
  },
  {
    rating: 5,
    review: 'I love this course. Would reccomend.',
    name: 'Jamie K.',
    date: '03/20/2020',
  },
  {
    rating: 5,
    review: 'My baby had really bad constipation and this actually helped so much. We reference the e-book alot when I’m having a forgetful day.',
    name: 'Vanessa R.',
    date: '03/17/2020',
  },
  {
    rating: 5,
    review: 'Loved it!',
    name: 'Maggie C.',
    date: '03/11/2020',
  },
  {
    rating: 5,
    review: 'I actually use the e-book the most. I have it saved on my phone and open it up every time we do the routines. The photos are so clear and easy to follow.',
    name: 'Brianna T.',
    date: '03/10/2020',
  }, 
  {
    rating: 5,
    review: 'Informative without being overwhelming!',
    name: 'Jennie P.',
    date: '03/06/2020',
  },
  {
    rating: 5,
    review: 'This is great.',
    name: 'Brook N.',
    date: '03/02/2020',
  },
  {
    rating: 4,
    review: 'Really simple and easy to follow',
    name: 'Beth R.',
    date: '02/24/2020',
  },
  {
    rating: 5,
    review: "So clear, love the e-book that I can now reference after watching the videos. It wasn't overwhelming which is really appreciated.",
    name: 'Noelle G.',
    date: '02/19/2020',
  },
  {
    rating: 5,
    review: "Great info.",
    name: 'Tammy C.',
    date: '02/18/2020',
  }, 
  {
    rating: 5,
    review: 'None of the posted times for baby massage classes in my area seemed to work with my baby’s schedule. This format was perfect because we could do a little bit each day at the time that worked best for us! I loved the personalized check-ins along the way. Everything was really easy to follow and fun.',
    name: 'Clara A.',
    date: '02/17/2020',
  },
  {
    rating: 5,
    review: "I love that I can go back to the videos whenever I need- totally using this for baby #2!",
    name: 'Suzie K.',
    date: '02/11/2020',
  },
  {
    rating: 5,
    review: "Super helpful course, thanks Sarah!",
    name: 'Raven S.',
    date: '02/09/2020',
  },
  {
    rating: 5,
    review: "So happy to finally learn how to properly massage my baby!",
    name: 'Abby G.',
    date: '02/02/2020',
  },
  {
    rating: 5,
    review: "Omg a must for all new moms!",
    name: 'Annie P.',
    date: '01/31/2020',
  }, 
  {
    rating: 5,
    review: "The instructor was really well-spoken and I loved the way it was organized into chunks. It was easy to stop/start without losing your place. I can tell this was made with moms in mind.",
    name: 'Lauren L.',
    date: '01/28/2020',
  },  
  {
    rating: 5,
    review: "Worth every penny.",
    name: 'Laura L.',
    date: '01/27/2020',
  },
  {
    rating: 5,
    review: "I gifted one to my friend for her shower! She loved it.",
    name: 'Hannah B.',
    date: '01/21/2020',
  },
  {
    rating: 4,
    review: 'Good course',
    name: 'Jamie K.',
    date: '01/16/2020',
  },
  {
    rating: 5,
    review: 'I think even if you take an in person course this is good. I took one and babies were crying the whole time I couldn’t focus. It’s nice to be able to go at your own pace.',
    name: 'Chloe B.',
    date: '01/15/2020',
  }, 
  {
    rating: 5,
    review: 'Loved it!',
    name: 'Leah S.',
    date: '01/11/2020',
  },
  {
    rating: 5,
    review: 'I felt really supported even though it was online! The instructor checked in with me every few weeks!!',
    name: 'Tanya R.',
    date: '01/10/2020',
  },
  {
    rating: 5,
    review: 'I just finished the course! Baby and I did it over the course of a month and it was perfect! I felt like being able to stop the videos and come back was so nice - especially when my baby chose to have a tantrum midway through lol.',
    name: 'Tammy J.',
    date: '01/06/2020',
  },
  {
    rating: 5,
    review: 'A friend told me about this course and I love having hands-on tools I can use when my baby is struggling!',
    name: 'Brittany C.',
    date: '01/02/2020',
  },
  {
    rating: 5,
    review: 'Really easy to follow.',
    name: 'Amanda A.',
    date: '12/29/2019',
  },
  {
    rating: 5,
    review: 'My sleep consultant recommended I try this course! It has become a staple in our bedtime routine!',
    name: 'Brianna K.',
    date: '12/27/2019',
  },
  {
    rating: 5,
    review: 'Great!',
    name: 'Sarah S.',
    date: '12/23/2019',
  },
  {
    rating: 5,
    review: 'I really liked how it is broken up by a specific concern. It made it easy to decide which routine I wanted to start with! So far we have focused on sleep but I will go back to learn constipation routine soon, feel like this will come in handy.',
    name: 'Paige T.',
    date: '12/21/2019',
  }, 
  {
    rating: 5,
    review: 'It was good...',
    name: 'Sierra C.',
    date: '12/18/2019',
  },
  {
    rating: 5,
    review: 'very informative',
    name: 'Mary K.',
    date: '12/17/2019',
  },
  {
    rating: 5,
    review: 'I love that I have access to this course for baby number 2! I was so worried I would forget what I learned.',
    name: 'Jessica S.',
    date: '12/10/2019',
  },
  {
    rating: 5,
    review: 'Thanks Mama Mobile!',
    name: 'Sue M.',
    date: '12/07/2019',
  },
  {
    rating: 5,
    review: 'Super helpful and easy to follow. I love being able to do it from my phone.',
    name: 'Sarah T.',
    date: '12/07/2019',
  }, 
  {
    rating: 5,
    review: 'I’m a long time Mama Mobile client and was so eager to try this course. It did not disappoint!',
    name: 'Nikki P.',
    date: '12/04/2019',
  },
  {
    rating: 5,
    review: 'I tried to search YouTube for courses to help with gas but none of them were detailed enough. My baby was really struggling so I decided it was time to get some real help. This course fit my busy schedule and I feel confident knowing I’m doing everything properly. I even emailed the instructor and we had a phone call! It really helped.',
    name: 'Alexis C.',
    date: '11/29/2019',
  },
  {
    rating: 5,
    review: 'I actually love following along with the videos! Even though I feel confident in the massage moves now we still play the videos during massage time from my phone!',
    name: 'Jessica R.',
    date: '11/26/2019',
  },
  {
    rating: 5,
    review: 'My baby is so lucky lol',
    name: 'Mira K.',
    date: '11/21/2019',
  },
  {
    rating: 5,
    review: 'I loved this!',
    name: 'Susanne R.',
    date: '11/20/2019',
  },
  {
    rating: 5,
    review: 'I took this course when I was prego to be prepared! I can’t wait to try it out when baby comes.',
    name: 'Jenna C.',
    date: '11/17/2019',
  },
  {
    rating: 5,
    review: 'Great course!',
    name: 'Lilly P.',
    date: '11/15/2019',
  },
  {
    rating: 5,
    review: 'Awesome 👏',
    name: 'Kelsey M.',
    date: '11/06/2019',
  },
  {
    rating: 5,
    review: 'Really easy to follow but had everything I wanted to know!',
    name: 'Taylor M.',
    date: '11/02/2019',
  },
  {
    rating: 5,
    review: 'First time mom here, I was really excited to learn baby massage! We do a little bit every night now!',
    name: 'Mary-Ann K.',
    date: '10/30/2019',
  },
]; 


export default FEEDBACKS;