import React from 'react';
import Instructor from './Instructor';
import CourseDetails from './CourseDetails';
import CourseBenefits from './CourseBenefits';
import CourseEbook from './CourseEbook';

const LEARNINGOBJECTIVES = [
  `Learn how to perform a relaxation massage on your baby to improve sleep`,
  `Learn techniques that you can apply to your baby's tummy to aid in digestion, alleviate colic, and constipation.`,
  `Learn techniques that can help relieve blocked sinuses and boost your baby's immune system.`,
  `Gain confidence in massaging your baby by learning how to apply all massage techniques safely.`,
  `Learn how to make baby massage part of your everyday routine`,
  `Learn techniques to help relieve baby of pain due to teething`,
];

const instructor = {
  name: 'Sarah Rennick',
  shortTitle: 'RMT',
  img: 'https://lh5.googleusercontent.com/ZaeIeeKLspzHHvFM5p2O0K6RU2KjoMa8m4IcFcJ8DZzNOfbgPH5ft4BrO0F8efCRyYyjO3EvRdwN5cLEf4OPsWA2-yoNfC1AkJhyT6S1AwkfFY-Ei9CJuu_xZy8sSniTBDJzr1iy',
  title: 'Registered Massage Therapist (RMT), Co-Founder of Mama Mobile',
  bio: 'Hi, I’m Sarah one of the founders of Mama Mobile! I became a Registered Massage Therapist 6 years ago and it has always been my life work to support moms. I’m a certified doula, master infant massage instructor, personal trainer and my current role is to train/lead our incredible team. The idea for Mama Mobile came to me when I saw a mama trying to breastfeed in her car before she saw me in the clinic for an appointment. I wondered why there wasn’t a mobile clinic of specialists that could support her…. So I created it. We’ve since helped over 3000 mamas.',
};
const ebook = {
  title: 'Baby Massage 101 E-Book',
  img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588059092/baby_massage_ebook.png',
  url: "https://firebasestorage.googleapis.com/v0/b/mama-mobile-massage.appspot.com/o/baby_massage.pdf?alt=media&token=dfa3572f-0b2c-4d9d-9089-b5ba59c72910"
};

const Overview = () => {
  return (
    <div id='learning-overview'>
      <Instructor instructor={instructor} />
      <CourseEbook ebook={ebook} />
      <CourseDetails 
        title='Course Details'
        description={['Out of your babies, five senses touch is the one that is most developed from birth, this is one of the many reasons babies crave a soothing massage just as much as you do! We want to empower you with tactile massage techniques that can help baby through common issues that come up like constipation, colic, nasal congestion, and sleep issues!']}
      />
      <CourseBenefits 
        title='Learning Objective'
        description={LEARNINGOBJECTIVES}
      />
      <div className='learning-section'>
        <h3>Section Overview</h3>
        <h5>Relaxation Massage</h5>
        <p>
          Infant massage provides a great opportunity for you to get to know your baby better. You will begin to connect through intentional touch. Massaging your baby provides an opportunity for you both to slow down and relax together. You will learn to read your baby’s cues better as you focus on her response to the massage. By making this part of your daily routine you will both be able to look forward to this special time together.
        </p>
        <p>
          Many new parents use baby massage as a tool to help improve the quality of your baby’s sleep- research has proven that it is an effective tool. All of the techniques in this routine are soothing and can be used before bedtime or naptime. 
        </p>
        <h5>Constipation, Colic, and Digestion Massage</h5>
        <p>
          It can be challenging as a mom to not have a solution for your baby’s constipation. We want to change that by giving you specific step-by-step tactile massage techniques to apply to your baby’s tummy. These techniques will aid in digestion also helping with constipation and colic! We will teach you how to safely apply the techniques and advise you on the duration and frequency of this routine for the best results. 
        </p>
        <h5>Cold and Sinus Massage</h5>
        <p>
          There are specific massage techniques that you can perform around your baby's sinuses to help relieve nasal congestion. Regular infant massage will also help to improve circulation for your baby which in turn will help the immune system become stronger!
        </p>
        <h5>Teething relief massage</h5>
        <p>
          Teething is something every baby will struggle with at some point in time. The techniques in this routine will help to ease the associated pain empowering you with the tools to help your baby feel his best.
        </p>
      </div>
    </div>
  )
};

export default Overview;