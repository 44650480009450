import React from 'react';
import TeamCard from './TeamCard';
import Members from './TeamMembers';
import './Team.css';

const Team = () => (
  <div id='team-cards'>
    {Members.map((member, idx) => {
      return (
        <TeamCard 
          key={member.name}
          description={member.description}
          funFact={member.funFact}
          title={member.title}
          name={member.name}
          img={member.img} 
        />
      )
    })}
  </div>
);

export default Team;