/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import BlogCard from './BlogCard';
import blogData from './blogData';
import './blog.css';
import SEO from '../../components/SEO/SEO';

const Blog = () => (
  <div id='blog-page' className='page-component'>
    <SEO 
      title={`Blogs | Mama Mobile`}
      description="Enjoy the Mama Mobile blog for prenatal and postnatal wellness resources, and tips for a healthy lifestyle for every stage of motherhood."
      url={`blog`}
    />
    <ScrollToTop />
    <h1>WELCOME TO MAMA BLOG</h1>
    <div className='center'>
      {blogData.map((blog, idx) => {
        return (
          <BlogCard image={blog.img} title={blog.title} type={blog.type} />
        )
      })}
    </div>
  </div>
)

export default Blog;