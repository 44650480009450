import React, { useContext } from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { useHistory } from "react-router-dom";
import './ThankYou.css';
import { AuthUserContext } from '../../context';

const ThankYouBuyBMC = () => {
  const Auth = useContext(AuthUserContext);
  const history = useHistory();

  const handleButtonClick = () => {
    history.push(`/sign-up/${Auth.purchaser}`);
  };

  return (
    <div className='landing-component-col thankyou' id='thankyou-baby-massage-enroll'>
      <ScrollToTop />
      <h1>Your baby massage training is ready for you! </h1>
      <button onClick={handleButtonClick} className='button-outline mid mint'>Register to Access the Course!</button>
      <p>Oh, and check your email - I've sent over your E-Book. Save me to your contacts so you don't miss anything!</p>
      <h4>See you inside 👋</h4>
    </div>
  )
};

export default ThankYouBuyBMC;