import React, { useState } from 'react';
import Blob from './Blob';
import { Icon } from 'semantic-ui-react';
import BenefitsCard from '../Card/BenefitsCard';

const Benefits = ({ benefits }) => {
  const [displayIdx, setDisplayIdx] = useState(0);

  const handleLeft = () => {
    if (displayIdx > 0) {
      setDisplayIdx(displayIdx - 1);
    }
  };

  const handleRight = () => {
    if (displayIdx === cards.length - 1) {
      setDisplayIdx(0);
    } else {
      setDisplayIdx(displayIdx + 1);
    }
  };

  const { title, cards } = benefits;
  return (
    <div className='landing-component benefits'>
      <h2 className='landing-title'>{title}</h2>
      <div className='benefits-body'>
        <div onClick={handleLeft} className='control-icon'>
          <Icon name='chevron left' />
        </div>
        <div className='benefits-cards-wrapper'>
          {cards.slice(displayIdx).map((item, idx) => (
            <BenefitsCard key={idx} card={item} />
          ))}
          {cards.map((item, idx) => (
            <BenefitsCard key={idx} card={item} />
          ))}
        </div>
        <div onClick={handleRight} className='control-icon'>
          <Icon name='chevron right' />
        </div>
      </div>
    </div>
  )
}

export default Benefits;