import React from 'react';
import WhyUsCard from '../Card/WhyUsCard';
import Lottie from 'react-lottie';
import animationData from '../../components/Lottie/lightbulb-icon.json';

const WhyPelvic = ({ whyPelvic }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='landing-component secondary why-pelvic'>
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
        isClickToPauseDisabled={true} 
      />
      <h2 className='landing-title'>Why Do I Need It?</h2>
      <div className='service-wrapper'>
        {
          whyPelvic.map((card, idx) => <WhyUsCard key={idx} card={card} />)
        }
      </div>
    </div>
  )
}

export default WhyPelvic;