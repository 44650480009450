import emailError from './emailError.js';

const handleSubscribeBabyCourse = (e, email, name, history) => {
  e.preventDefault();
  const url = `https://api.sendgrid.com/v3/marketing/contacts`;
  const nameArr = name.trim().split(' ');
  const firstName = nameArr[0].charAt(0).toUpperCase() + nameArr[0].substring(1);

  const data = {
    list_ids: [
      "87a1d8f6-c4aa-4361-951d-2f157e5b8974"
    ],
    contacts: [
      {
        email,
        first_name: firstName,
        unique_name: nameArr.join(' ')
      }
    ]
  };

  const api = 'Bearer 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';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', api);

  const fetchData = { 
    method: 'PUT', 
    body: JSON.stringify(data),
    headers,
  };

  window.analytics.identify({
    name: nameArr.join(' '),
    firstName,
    email,
  });

  window.analytics.track('Baby Course Subscribe', {
    firstName,
    email,
  });

  fetch(url, fetchData)
  .then((res) => {
    if (res.statusText === 'Accepted') {
      emailError('Baby Massage Subscriber', data);
    } else {
      emailError('Baby Massage Error', data);
    }
    history.push('/thankyou-baby-massage');
    return res.json();
  })
  .then(data => console.log(data))
  .catch(err => console.log('err', err));
};

export default handleSubscribeBabyCourse;