import React from 'react'
import Lottie from 'react-lottie';
import animationData from './stroller.json';
 

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className='loading-overlay'>
      <Lottie 
        options={defaultOptions}
        height={300}
        width={300}
        isClickToPauseDisabled={true} 
      />
    </div>
  )
}

export default Loading;

 