import React from 'react';
import LearningTabs from './LearningTabs';

const Main = ({ video, course }) => {
  return (
    <div id='learning-main'>
      <iframe 
        title='baby-massage'
        src={video.url} 
        frameBorder="0" 
        allow="autoplay; 
        fullscreen" 
        allowFullScreen
      />
      <LearningTabs video={video} course={course} />
    </div>
  )
}

export default Main;