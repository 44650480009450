import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import './FAQ.css';

const FAQ = ({ question, answer, location }) => {
  const [open, setOpen] = useState(false);
  const renderAnswer = () => {
    if (location) {
      return (
        <ul>
          {answer.map(item => <li>{item}</li>)}
        </ul>
      )
    } else {
      return <p>{answer}</p>
    }
  }

  return (
    <div onClick={() => setOpen(!open)} className='faq'>
      <div className='faq-question'>
        <h5>{question}</h5>
        {open ? <Icon>remove</Icon> : <Icon>add</Icon>}
      </div>
      {open && renderAnswer()}
    </div>
  )
}

export default FAQ;