import React, { useState } from 'react';
import Stars from './Stars';
import Rating from '@material-ui/lab/Rating';
import FEEDBACKS from './FEEDBACKS';

const styles = {
  stars: {
    color: '#F9D265',
    fontSize: '1.1rem',
  }
}
const FeedbackCard = ({ feedback }) => {
  const {rating, review, name, date} = feedback;
  return (
    <div className='feedback-card'>
      <div className='feedback-user'>
        <p>{name}</p>
        <div className='small'>{date}</div>
        <div className='verified'>Verified Purchase</div>
      </div>
      <div className='feedback-review'>
        <Rating style={styles.stars} value={rating} precision={0.5} readOnly/>
        <p>{review}</p>
      </div>
    </div>
  )
};

const Feedback = () => {
  const [reviews, setReviews] = useState(5);

  return (
    <div id='feedback'>
      <div className='center col'>
        <h3>Let customers speak for us</h3>
        <Stars />
      </div>
      <div className='feedback-card-wrapper'>
        {FEEDBACKS.slice(0, reviews).map(feedback => <FeedbackCard key={feedback.name} feedback={feedback} />)}
      </div>
      {(FEEDBACKS.length > reviews) && <p className='pagination-button' onClick={() => setReviews(reviews + 5)}>Show 5 more reviews</p>}
    </div>
  )
}

export default Feedback;