import React, { useContext, useState } from 'react';
import { AuthUserContext, FirebaseContext } from '../../context';
// import { useHistory } from "react-router-dom";
import { Redirect } from "react-router-dom";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import './Learning.css';
import CourseCard from './CourseCard';
import selfcareData from './selfcareData';

const BABY101 = {
  title: 'Baby Massage 101 Series',
  img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1589088843/baby_massage_how_to_course.png',
  instructor: 'Sarah Rennick',
  duration: 'Video Series'
};

const BABY101Premium = {
  title: 'Baby Massage 101',
  img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1589088843/baby_massage_how_to_course.png',
  instructor: 'Sarah Rennick',
  duration: 'Premium Content'
};

const Learning = () => {
  const Auth = useContext(AuthUserContext);
  const Firebase = useContext(FirebaseContext);
  const [baby101, setBaby101] = useState(false);

  if (!Auth.user) {
    return <Redirect to='/login' />
  } else {
    Firebase.findUser('users', Auth.user)
    .then(doc => {
      if (doc.exists) {
        if (doc.data().baby101) {
          setBaby101(true);
        }
      }
    })

    return (
      <div id='learning'>
        <ScrollToTop />
        <h1>Mama Learning</h1>
        <div className='flex center'>
          {
            baby101 && <CourseCard key={BABY101.title} course={BABY101} />
          }
          {
            selfcareData.map(course => <CourseCard key={course.title} course={course} />)
          }
          {
            !baby101 && <CourseCard key={BABY101Premium.title} course={BABY101Premium} />
          }
        </div>
      </div>
    )
  }
}

export default Learning;