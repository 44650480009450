import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { AuthUserContext } from '../../context';
import { Link } from 'react-router-dom';
import Polaroid from '../Polaroid/Polaroid';

const ServiceCard = ({ title, description, list, image, alt, buttonText, opposite, quote, route, href }) => {
  const Auth = useContext(AuthUserContext);

  return (
    <div className={opposite ? 'service-card opposite' : 'service-card'}>
      <div className='service-card-body'>
        <div className='service-card-content'>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
        {list.map((item, idx) => {
          return (
            <div key={idx}>
              {/* <svg width="16" height="16" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                <path d="M30.0205 21.5625C30.0205 23.8867 29.1953 25.874 27.5449 27.5244C25.8945 29.1748 23.9072 30 21.583 30C20.1963 30 18.9072 29.6875 17.7158 29.0625C16.5244 28.4375 15.5381 27.5781 14.7568 26.4844C12.9014 27.4023 11.2217 27.9395 9.71777 28.0957C8.21387 28.252 7.12988 27.9883 6.46582 27.3047C5.68457 26.543 5.45508 25.2295 5.77734 23.3643C6.09961 21.499 6.90527 19.4922 8.19434 17.3438C9.40527 18.2812 10.7432 18.75 12.208 18.75C14.0244 18.75 15.5723 18.1104 16.8516 16.8311C18.1309 15.5518 18.7705 14.0039 18.7705 12.1875C18.7705 10.7227 18.3018 9.38477 17.3643 8.17383C19.5127 6.88477 21.5195 6.0791 23.3848 5.75684C25.25 5.43457 26.5635 5.66406 27.3252 6.44531C28.0088 7.10938 28.2725 8.19336 28.1162 9.69727C27.96 11.2012 27.4229 12.8809 26.5049 14.7363C27.5986 15.5176 28.458 16.5039 29.083 17.6953C29.708 18.8867 30.0205 20.1758 30.0205 21.5625ZM24.542 17.959C23.6436 19.248 22.6475 20.4395 21.5537 21.5332C20.46 22.627 19.2686 23.623 17.9795 24.5215C18.9365 25.6738 20.1377 26.25 21.583 26.25C22.8721 26.25 23.9756 25.791 24.8936 24.873C25.8115 23.9551 26.2705 22.8516 26.2705 21.5625C26.2705 20.1172 25.6943 18.916 24.542 17.959ZM12.208 16.875C11.6221 16.875 11.0996 16.7773 10.6406 16.582C10.1816 16.3867 9.83008 16.1719 9.58594 15.9375C9.3418 15.7031 9.03906 15.4883 8.67773 15.293C8.31641 15.0977 7.93066 15 7.52051 15C5.4502 15 3.68262 14.2676 2.21777 12.8027C0.75293 11.3379 0.0205078 9.57031 0.0205078 7.5C0.0205078 5.42969 0.75293 3.66211 2.21777 2.19727C3.68262 0.732422 5.4502 0 7.52051 0C9.59082 0 11.3584 0.732422 12.8232 2.19727C14.2881 3.66211 15.0205 5.42969 15.0205 7.5C15.0205 7.91016 15.1182 8.2959 15.3135 8.65723C15.5088 9.01855 15.7236 9.32129 15.958 9.56543C16.1924 9.80957 16.4072 10.1611 16.6025 10.6201C16.7979 11.0791 16.8955 11.6016 16.8955 12.1875C16.8955 13.4766 16.4365 14.5801 15.5186 15.498C14.6006 16.416 13.4971 16.875 12.208 16.875Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0">
                <rect width="30.03" height="30" fill="white"/>
                </clipPath>
                </defs>
              </svg> */}
              {item.title && <h5>{item.title}</h5>}
              <p>{item.description}</p>
            </div>
          )
        })}
        {route ? <Link to={route}>
          <Button variant="contained" color="secondary">{buttonText}</Button>
        </Link> : 
        <a href={href} target='__blank'>
          <Button variant="contained" color="secondary">{buttonText}</Button>
        </a>}
      </div>
      <Polaroid image={image} alt={alt} quote={quote} />
    </div>
  )
}

export default ServiceCard;