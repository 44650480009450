import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// pages
import BabyMassageCourse from './pages/BabyMassageCourse/BabyMassageCourse';
import ThankYouBabyMassage from './pages/ThankYou/ThankYouBabyMassage';
import Blog from './pages/Blog';
import BlogPost from './components/BlogPost/index';
import GiftCard from './pages/Giftcard/Giftcard';
import About from './pages/About/About';
import BabyMassageFreeVideo from './pages/BabyMassageVideo/BabyMassageFreeVideo';
import Career from './pages/Career/Career';
import Checkout from './pages/Checkout/Checkout';
import Learning from './pages/Learning/Learning';
import Login from './pages/Login/Login';
import ThankYouBuyBMC from './pages/ThankYou/ThankYouBuyBMC';
import SignUp from './pages/Login/SignUp';
import Course from './pages/Learning/Course';
import StageLanding from './pages/Landing/StageLanding';
import ServiceLanding from './pages/Landing/ServiceLanding';
import UpcomingWorkshops from './components/Landing/UpcomingWorkshops';

import './components/Landing/Landing.css';

import { allWorkshops } from './util/upcomingWorkshops';
import WorkshopLanding from './pages/WorkshopLanding/WorkshopLanding';
import ThankYouWorkshop from './pages/ThankYou/ThankYouWorkshop';
import HomeSecondary from './pages/Home/HomeSecondary';

const Routes = () => (
  <Switch>
    <Route exact path="/" component={HomeSecondary} />
    <Route path="/stage/:stage" component={StageLanding} />
    <Route path="/service/:stage/:service" component={ServiceLanding} />
    <Route path="/about" component={About} />
    <Route path="/baby-massage-course/teething-techniques" component={BabyMassageFreeVideo} />
    <Route path="/baby-massage-course" component={BabyMassageCourse} />
    <Route path="/thankyou-baby-massage" component={ThankYouBabyMassage} />
    <Route path='/thankyou-baby-massage-enroll' component={ThankYouBuyBMC} />
    <Route exact path="/blog" component={Blog} />
    <Route path="/blog/:title" children={<BlogPost />} />
    <Route exact path='/upcoming-workshops' render={() => <UpcomingWorkshops workshops={allWorkshops} />} />
    <Route path="/upcoming-workshops/:id" children={<WorkshopLanding />} />
    <Route path="/thankyou-upcoming-workshops/:id" children={<ThankYouWorkshop />} />
    <Route path="/gift-card" component={GiftCard} />
    <Route path="/career" component={Career} />
    <Route path="/checkout" component={Checkout} />
    <Route exact path="/learning" component={Learning} />
    <Route path="/learning/:title" component={Course} /> 
    <Route path="/login" render={() => <Login login title='Login' description='Already have an account? Sign-in to access your courses!' />} />
    <Route exact path="/password-reset" render={() => <Login password title='Reset your Password' description='Enter the email address associated with your account and we’ll email you a link to reset your password.' />} />
    <Route exact path="/sign-up" render={() => <Login signup title='Create an Account' description='Register an account so you can access mama learning!' />} />
    <Route path="/more-info" render={() => <Login moreInfo title='Hey, do you have a sec?' description='We want to get to know you a little better!' />} />
    <Route path="/sign-up/:email" children={<SignUp />} />
    <Route render={() => <Redirect to="/" />} />
    {/* <Route path="/contact" component={Contact} />
    <Route path="/group-booking" component={MassageParty} />
    <Route path="/booking-request" component={BookingRequestPage} /> */}
  </Switch>
)

export default Routes;