import React from 'react';

const WhyUsCard = ({ card }) => {
  const { title, description, line } = card;

  return (
    <div className='why-us-card'>
      <div className='why-us-card-divider'>
        <svg width="28" height="28" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M49.9842 35.9375C49.9842 39.8112 48.6103 43.1234 45.8624 45.874C43.1145 48.6247 39.8056 50 35.9358 50C33.6269 50 31.4806 49.4792 29.4969 48.4375C27.5132 47.3958 25.871 45.9635 24.5702 44.1406C21.4808 45.6706 18.6841 46.5658 16.1801 46.8262C13.6761 47.0866 11.8713 46.6471 10.7656 45.5078C9.46485 44.2383 9.08275 42.0492 9.61932 38.9404C10.1559 35.8317 11.4973 32.487 13.6436 28.9062C15.6598 30.4688 17.8874 31.25 20.3264 31.25C23.3507 31.25 25.9279 30.1839 28.0579 28.0518C30.1879 25.9196 31.253 23.3398 31.253 20.3125C31.253 17.8711 30.4725 15.6413 28.9116 13.623C32.4887 11.4746 35.8301 10.1318 38.9357 9.59473C42.0413 9.05762 44.2283 9.4401 45.4965 10.7422C46.6347 11.849 47.0737 13.6556 46.8136 16.1621C46.5534 18.6686 45.6591 21.4681 44.1307 24.5605C45.9518 25.8626 47.3827 27.5065 48.4233 29.4922C49.4639 31.4779 49.9842 33.6263 49.9842 35.9375ZM40.8625 29.9316C39.3666 32.0801 37.7081 34.0658 35.887 35.8887C34.0659 37.7116 32.0822 39.3717 29.9359 40.8691C31.5294 42.7897 33.5293 43.75 35.9358 43.75C38.0821 43.75 39.9194 42.985 41.4478 41.4551C42.9763 39.9251 43.7405 38.0859 43.7405 35.9375C43.7405 33.5286 42.7811 31.5267 40.8625 29.9316ZM20.3264 28.125C19.3508 28.125 18.4809 27.9622 17.7167 27.6367C16.9525 27.3112 16.3671 26.9531 15.9606 26.5625C15.5541 26.1719 15.0501 25.8138 14.4485 25.4883C13.8469 25.1628 13.2046 25 12.5217 25C9.07462 25 6.1316 23.7793 3.69263 21.3379C1.25366 18.8965 0.0341797 15.9505 0.0341797 12.5C0.0341797 9.04948 1.25366 6.10352 3.69263 3.66211C6.1316 1.2207 9.07462 0 12.5217 0C15.9688 0 18.9118 1.2207 21.3508 3.66211C23.7897 6.10352 25.0092 9.04948 25.0092 12.5C25.0092 13.1836 25.1718 13.8265 25.497 14.4287C25.8222 15.0309 26.1799 15.5355 26.5701 15.9424C26.9604 16.3493 27.3181 16.9352 27.6433 17.7002C27.9685 18.4652 28.1311 19.3359 28.1311 20.3125C28.1311 22.4609 27.3669 24.3001 25.8385 25.8301C24.31 27.36 22.4727 28.125 20.3264 28.125Z" fill="#322030"/>
        </svg>
        <div className={line ? 'line' : 'line hidden'}></div>
      </div>
      <div>
        <h5>{title}</h5>
        {description.map(item => <p>{item}</p>)}
      </div>
    </div>
  )
}

export default WhyUsCard;