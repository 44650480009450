import React from 'react';
import './Marquee.css';

const Marquee = () => {
  return (
    <div className='marquee'>
      <h3>virtual services? cool I’m not wearing a bra!</h3>
      <h3>A massage in my living room? say no more!</h3>
      <h3>virtual services? cool I’m not wearing a bra!</h3>
      <h3>A massage in my living room? say no more!</h3>
    </div>
  )
};

export default Marquee;