import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { FirebaseContext, AuthUserContext } from '../../context';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';


const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthUserContext);
  const history = useHistory();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      const loginRes = await Firebase.signInWithEmailAndPassword(email, password);
      const userRes = await Firebase.findUser('users', loginRes.user.email);
  
      if (userRes.exists) {
        const user = userRes.data();
        console.log(user);
        localStorage.setItem('user', user.email);
        localStorage.setItem('name', user.firstName);
        Auth.setState('user', user.email);
        Auth.setState('name', user.firstName);
        setLoading(false);
        history.push('/learning');
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <>
    <form onSubmit={handleSubmit}>
      {loading && <Loading />}
      <label>EMAIL</label>
      <input
        type='email'
        required
        value={email}
        onChange={e => setEmail(e.target.value)}
        placeholder='Email'
      />
      <label>PASSWORD</label>
      <input 
        type='password'
        required
        value={password}
        onChange={e => setPassword(e.target.value)}
        placeholder='Password'
      />
      <Button type='submit' variant="contained" color="secondary">Sign In</Button>
      {error && <Error errMsg={error} />}
    </form>
    <NavLink className='forgot-password-link' to='/password-reset'>Forgot password</NavLink>
    <p className='sign-up-link'>
      {`Don't have an account? `}
      <NavLink to='/sign-up'>Sign Up</NavLink>
    </p>
    </>
  )
}

export default LoginForm;
