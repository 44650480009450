import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { FirebaseContext } from '../../context';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { NavLink } from 'react-router-dom';


const PasswordResetForm = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Firebase = useContext(FirebaseContext);
  const history = useHistory();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    try {
      await Firebase.passwordReset(email);
      setLoading(false);
      alert('Your password reset has been sent to your email - Please follow the instruction in your email to reset your password!');
      history.push('/login');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading && <Loading />}
        <label>EMAIL</label>
        <input
          type='email'
          required
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder='Email'
        />
        <button className='button-outline yellow'>Submit</button>
        {error && <Error errMsg={error} />}
      </form>
      <NavLink to='/login'>Back to Login</NavLink>
    </>
  )
}

export default PasswordResetForm;
