import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import instructorData from './instructorData';

const styles = {
  paper: {
    width: '300px',
    height: '280px',
    margin: '20px',
    cursor: 'pointer',
    borderRadius: 0,
  }
}
const CourseCard = ({ course }) => {
  const history = useHistory();
  const { img, title, instructor, duration } = course;

  const name = instructor && instructorData[instructor].name;
  const shortTitle = instructor && instructorData[instructor].shortTitle;

  const handleClick = () => {
    if (title === 'Baby Massage 101') {
      history.push('/baby-massage-course');
    } else {
      history.push(`/learning/${title.split(' ').join('-')}`)
    }
  }

  return (
    <Paper onClick={handleClick} style={styles.paper} elevation={3}>
      <div className='course-card-img'>
        <img src={img} alt={title}/>
        <div className='course-card-duration'>{duration}</div>
      </div>
      <div className='course-card-detail'>
        <div className='course-card-title'>
          <h2>{title}</h2>
        </div>
        <p>{`By: ${name} - ${shortTitle}`}</p>
      </div>
    </Paper>
  )
};

export default CourseCard;