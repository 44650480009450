import React from 'react';

const CourseBenefits = ({ title, description }) => {
  return (
    <div className='learning-section'>
      <h3>{title}</h3>
      <ul>
        {
          description.map((item, idx) => (
            <li key={idx}>
              {item}
            </li>
          ))
        }
      </ul>
    </div>
  )
};

export default CourseBenefits;