import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Icon } from 'semantic-ui-react'

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,.35)',
  },
  chapterTitle: {
    fontFamily: 'galano-semibold, sans-serif',
    color: 'white',
    fontSize: '15px',
  },
  listItem: {
    padding: '20px' 
  },
  title: {
    fontSize: '14px',
    color: 'white',
    marginBottom: 0,
    fontFamily: 'galano-reg, sans-serif',
  },
  duration: {
    fontSize: '12px',
    color: 'rgba(255,255,255,.7)',
  }
}));

const ListChapter = ({ chapter, setActiveVideo, closeMenu }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem className={classes.listItem} button onClick={handleClick}>
        <ListItemText >
          <p className={classes.chapterTitle}>{chapter.title}</p>
        </ListItemText>
        {open ? <Icon name='angle down' /> : <Icon name='angle right' />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            chapter.chapterItems.map(item => (
              <ListItem key={item.title} button className={classes.nested} onClick={() => {setActiveVideo(item); closeMenu()}}>
                <ListItemText>
                  <p className={classes.title}>{item.title}</p>
                  <p className={classes.duration}>{item.duration}</p>
                </ListItemText>
              </ListItem>
            ))
          }
        </ List>
      </Collapse>
      <Divider className={classes.divider} />
    </>
  );
}

export default ListChapter;