import React, { useContext, useEffect } from 'react';
import TripleImageCard from '../../components/Card/TripleImageCard';
import '../../components/Card/card.css';
import './Home.css';
import { AuthUserContext } from '../../context';
import { useHistory, Link } from 'react-router-dom';
import TripleBasicCard from '../../components/Card/TripleBasicCard';
import Marquee from '../../components/Marquee/Marquee';

const STAGES = [
  {
    title: 'Prenatal',
    value: 'prenatal',
    // description: '“I have a bun in the oven”',
  },
  {
    title: 'Postnatal',
    // description: '“I have a rugrat from 0 to 12 months”',
    value: 'postnatal',
  },
  {
    title: 'Busy Mama',
    // description: '“I’m juggling a full circus”',
    value: 'busymama',
  }
];

const HomeSecondary = () => {
  const Auth = useContext(AuthUserContext);
  const history = useHistory();

  useEffect(() => {
    const stage = localStorage.getItem('stage');
    
    if (stage) {
      Auth.setState('stage', stage);
      history.push(`/stage/${stage}`);
    }
  }, []);

  const handleSelect = (stage) => {
    Auth.setState('stage', stage);
    localStorage.setItem('stage', stage);
    history.push(`/stage/${stage}`);
  }
  
  return (
    <div id='home'>
      <div className='home-header'>
        <h2>mama mobile</h2> 
        {/* <Link to='/gift-card'>I want to buy a giftcard</Link> */}
      </div>
      <div className='home-body'>
        <div className='flex'>
          <div className='home-body-content'>
            <h1><b>this is</b> wellness at home made for <b>motherhood</b>.</h1>
            <h3>the services to support your busy life.</h3>
            <h3>virtually or in-home</h3>
          </div>
          <div className='home-body-img'>
            <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1607627178/Untitled_design_29_r8p0ha.png' />
          </div>
        </div>
        <h4>tell us, what stage are you in?</h4>
        <div className='card-wrapper'>
          {STAGES.map(stage => (
            <TripleBasicCard key={stage.value} data={stage} handleSelect={handleSelect} />
            ))}
        </div>
      </div>
      <Marquee />
      <div className='wave-wrap secondary'>
        <div className='wave-inner'></div>
      </div>
    </div>
  )
};



export default HomeSecondary;