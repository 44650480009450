import React from 'react';
import HowItWorksCard from '../Card/HowItWorksCard';
import Blob from './Blob';

const howItWorksPelvic = [
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1607972354/Untitled_design_31_jzodzd.png',
    alt: 'book your pelvic floor physio online',
    number: 1,
    title: 'Book your appointment online.'
  },
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1607972354/Untitled_design_32_g4esh6.png',
    alt: 'pelvic floor physiotherapy over secured network',
    number: 2,
    title: 'Your virtual appointment will take place over our secure network.',
    opposite: true,
  },
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1607972354/Untitled_design_33_ggiubs.png',
    alt: 'physiotherapist create treatment plan',
    number: 3,
    title: 'Your physiotherapist will curate a treatment plan just for you.'
  }
];

const HowItWorksPelvic = () => {
  return (
    <div className='landing-component how-it-works secondary'>
      <Blob />
      <h2 className='landing-title'>How It Works</h2>
      <div className='how-it-works-card-wrapper'>
        {howItWorksPelvic.map((item, idx) => <HowItWorksCard key={idx} card={item} />)}
      </div>
    </div>
  )
}

export default HowItWorksPelvic;