const handlePaymentIntent = (paymentMethod, user, charge) => {
  const url = 'https://us-central1-mama-mobile-238622.cloudfunctions.net/api/stripe/paymentIntent';
  // const url = 'http://localhost:5000/mama-mobile-238622/us-central1/api/stripe/paymentIntent';

  const data = {
    paymentMethod: paymentMethod,
    user,
    charge
  };

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });

};

export default handlePaymentIntent;