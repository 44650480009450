import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import {useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Form, Icon } from 'semantic-ui-react';
import { FirebaseContext, AuthUserContext } from '../../context';
import handlePaymentIntent from '../../util/handlePaymentIntent';
import { useHistory } from "react-router-dom";
import emailBabyMassageCourse from '../../util/emailBabyMassageCourse';
import emailError from '../../util/emailError';
import capitalize from '../../util/capitalize';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { Button } from '@material-ui/core';

const Payment = ({total, promo}) => {
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthUserContext);
  const [first, setFirst] = useState('');
  const [last, setLast] = useState('');
  const [em, setEm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Lato, Arial, Helvetica, sans-serif',
        fontSize: "16px",
        "::placeholder": {
          color: "rgba(34,36,38,.25)"
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    },
    hidePostalCode: true,
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      const firstName = capitalize(first);
      const lastName = capitalize(last);
  
      const email = em.toLowerCase();
      const name = `${firstName} ${lastName}`
  
      const charge = (total - promo) * 100;

      const user = await Firebase.findOrCreateUser('users', email, {email, firstName, lastName, name});
      if (charge === 0) {
        emailBabyMassageCourse({
          email,
          firstName,
          name,
          price: total,
          promo,
        });
        Firebase.updateUser(email, 'baby101', true);
        Auth.setState('purchaser', email);
        setLoading(false);
        setError(false);
        history.push('/thankyou-baby-massage-enroll');
      } else {
        const result = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement),
          billing_details: {
            email,
            name,
          },
        });

        if (result.error) {
          setLoading(false);
          setError(`I'm sorry, there was a problem with the charge - we will contact you asap to fix the problem!`);
          emailError('purchase failed', {
            email,
            firstName,
            name,
            price: total,
            promo,
          });
        } else {
          const chargeResponse = await handlePaymentIntent(result.paymentMethod, user, charge);
          
          if (chargeResponse.status === 200) {
            Firebase.updateUser(email, 'baby101', true);
            window.analytics.identify({
              name,
              firstName,
              email,
            });
          
            window.analytics.track('Baby Course Purchased', {
              name,
              email,
              total: total - promo,
            });

            emailBabyMassageCourse({
              email,
              firstName,
              name,
              price: total,
              promo,
            });
            Auth.setState('purchaser', email);
            setLoading(false);
            setError(false);
            history.push('/thankyou-baby-massage-enroll');
          } else {
            setLoading(false);
            setError(`I'm sorry, there was a problem with the charge - we will contact you asap to fix the problem!`);
            emailError('purchase failed', {
              email,
              firstName,
              name,
              price: total,
              promo,
            });
          }
        }
      }
    } catch (err) {
      setLoading(false);
      setError(`I'm sorry, there was a problem with the charge - we will contact you asap to fix the problem!`);
      emailError(err.message, {first, last, em, total, promo});
    }
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError(false);
    }
  };

  return (
    <div id='payment'>
      {loading && <Loading />}
      <h3>Payment Information</h3>
      <div className='list-with-icon'>
        <Icon name='lock'/>
        <p>This is a secure 128-bit SSL encrypted payment</p>
      </div>
      <Paper elevation={2}>
        <Form onSubmit={handleSubmit}>
          <Form.Input 
            type='first name'
            fluid
            required
            value={first}
            onChange={(e) => setFirst(e.target.value)}
            placeholder='Frist Name'
          />
          <Form.Input 
            type='last name'
            fluid
            required
            value={last}
            onChange={(e) => setLast(e.target.value)}
            placeholder='Last Name'
          />
          <Form.Input
            type='email' 
            fluid 
            required 
            value={em}
            onChange={(e) => setEm(e.target.value)}
            placeholder='Email' 
          />
          <div id='card-element'>
            <CardElement onChange={handleCardChange} options={cardStyle} />
          </div>
          {error && <Error errMsg={error} />}
          <Button variant="contained" color="secondary" type='submit'>Enroll in Course!</Button> 
        </Form>
        <div className='payment-footer'>
          <div className='flex col center'>
            <p><b>Accepted Payements</b></p>
            <div className='payment-type'>
              <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588203694/mastercard.png' alt='mastercard'/>
              <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588203694/visa.png' alt='visa'/>
              <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588203694/amex.png' alt='american express'/>
              <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588203694/discover.png' alt='discover'/>
            </div>
          </div>
          <img width='50%' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588203287/30_days_guarantee_inverse.png' alt='30 days money back guarantee' />
          <p className='small'>Powered by Stripe</p>
        </div>
      </Paper>
    </div>
  )
};

export default Payment;