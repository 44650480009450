import React from 'react';
import WhyUsCard from '../Card/WhyUsCard';

const WhyUs = ({ whyUs, title, secondary }) => {
  return (
    <div className={secondary ? 'landing-component why-us secondary' : 'landing-component why-us'}>
      <h2 className='landing-title'>{title}</h2>
      <div className='why-us-cards-wrapper'>
        {whyUs.map((item, idx) => <WhyUsCard key={idx} card={item} />)}
      </div>
    </div>
  )
}

export default WhyUs;