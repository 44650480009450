/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import { Button } from '@material-ui/core';

const PROMOS = {
  // babymassage: 34.68,
  // Babymassage: 34.68,
  Gillian: 68,
  MamaSquad: 68,
  zoomsesh: 12,
  Zoomsesh: 12,
};

const PromoCode = ({ setPromo }) => {
  const [code, setCode] = useState('');
  const [display, setDisplay] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (PROMOS[code]) {
      setError(false);
      setPromo(PROMOS[code]);
    } else {
      setError(`Sorry! We can't find the promo code - ${code}`)
    }
  }

  return (
    display ? (
      <div className="promo-code">
        <div className="item">
          <p>Promo Code</p>
          <div className='flex'>
            <Input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              id="promo-code-input"
              type="text"
              placeholder="Enter your promo code"
            />
            <Button
              onClick={handleSubmit}
              id="promo-code-button"
              variant="contained" color="secondary"
            >
              APPLY
            </Button>
          </div>
        </div>
        {error ? <div className="promo-error">{error}</div> : null}
      </div>
    ) : <div id='promo-code-clickable' onClick={() => setDisplay(true)}>Apply a promo code</div>
  );
};

export default PromoCode;
