import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { FirebaseContext, AuthUserContext } from '../../context';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { Checkbox } from 'semantic-ui-react';
import { Button } from '@material-ui/core';

const postnatalOptions = [
  { text: 'Sleep training (mamas tired)', value: 'sleepTraining' },
  { text: 'Boob stuff: breastfeeding?  Not breastfeeding but really sore?)', value: 'breastfeeding'},
  { text: 'My body hurts', value: 'physicalPain' },
  { text: 'Um will my vag go back to normal?', value: 'vagina'},
  { text: 'My mind is racing', value: 'mental' },
  { text: 'Not sure what I even need tbh', value: 'uncertain' },
  { text: 'C-section recovery', value: 'csection' },
  { text: 'I wanna start moving my body!', value: 'physical' },
  { text: 'Making mama friends', value: 'community' },
];

const prenatalOptions = [
  { text: 'Do I need a doula?', value: 'doula' },
  { text: 'Boob stuff: My boobs are really sore!)', value: 'soreBreasts'},
  { text: 'My body hurts', value: 'physicalPain' },
  { text: 'What can I do about my pelvic floor now?', value: 'pelvicFloor'},
  { text: 'My mind is racing', value: 'mental' },
  { text: 'I just want more info tbh - I feel lost', value: 'uncertain' },
  { text: 'I wanna start moving my body!', value: 'physical' },
  { text: 'Making mama friends', value: 'community' },
];

const busymamaOptions = [
  { text: 'Thinking of getting pregnant again soon', value: 'prePregnancy'},
  { text: 'My body hurts', value: 'physicalPain' },
  { text: 'Tips on parenting', value: 'parenting'},
  { text: 'My mind is racing', value: 'mental' },
  { text: 'Not sure what I even need tbh', value: 'uncertain' },
  { text: 'I wanna start moving my body!', value: 'physical' },
  { text: 'Making mama friends', value: 'community' },
];

const MoreInfoForm = () => {
  const [city, setCity] = useState('');
  const [stage, setStage] = useState('');
  const [trimester, setTrimester] = useState('');
  const [firstPregnancy, setFirstPregnancy] = useState('');
  const [classes, setClasses] = useState('');
  const [babyAge, setBabyAge] = useState('');
  const [youngest, setYoungest] = useState('');
  const [postnatal, setPostnatal] = useState({
    sleepTraining: false,
    breastfeeding: false,
    physicalPain: false,
    vagina: false,
    mental: false,
    uncertain: false,
    csection: false,
    physical: false,
    community: false,
  });
  const [prenatal, setPrenatal] = useState({
    doula: false,
    soreBreasts: false,
    physicalPain: false,
    pelvicFloor: false,
    mental: false,
    uncertain: false,
    physical: false,
    community: false,
  });
  const [busymama, setBusymama] = useState({
    prePregnancy: false,
    parenting: false,
    physicalPain: false,
    mental: false,
    uncertain: false,
    physical: false,
    community: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthUserContext);
  const history = useHistory();
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    const data = {
      city,
      stage,
    };

    if (stage === 'prenatal') {
      data.trimester = trimester;
      data.firstPregnancy = firstPregnancy;
      data.classes = classes;
      data.prenatal = prenatal;
    } else if (stage === 'postnatal') {
      data.babyAge = babyAge;
      data.postnatal = postnatal;
      data.firstPregnancy = firstPregnancy;
    } else {
      data.busymama = busymama;
      data.youngest = youngest;
    }

    try {
      await Firebase.updateDoc('users', Auth.user, data)
      setLoading(false);
      history.push('/learning');
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  const renderPrenatal = () => {
    return (
      <>
        <label>Is this your first rodeo (Umm I mean... pregnancy)?</label>
        <select
          required
          onChange={e => setFirstPregnancy(e.target.value)}
          selected={firstPregnancy}
        >
          <option value=''>--Please choose an option--</option>
          <option value="yes">Yes!</option>
          <option value="no">Nah</option>
        </select>
        <label>How far along are you?</label>
        <select
          required
          onChange={e => setTrimester(e.target.value)}
          selected={trimester}
        >
          <option value=''>--Please choose an option--</option>
          <option value="1">First trimester</option>
          <option value="2">Second trimester</option>
          <option value="3">Third trimester</option>
          <option value="4">Get the dang baby out of me!</option>
          </select>
          <label>What are the classes you have taken or want to take (if there are any)?</label>
          <textarea
            rows={2}
            type='text'
            value={classes}
            onChange={e => setClasses(e.target.value)}
          />
          <label>Which topics could we help with?</label>
          {prenatalOptions.map(item => <Checkbox 
            key={item.value}
            label={item.text}
            value={item.value} 
            onChange={() => {
              setPrenatal({
                ...prenatal,
                [item.value]: !prenatal[item.value],
              });
            }}
          />)}
      </>
    )
  };

  const renderPostnatal = () => {
    return (
      <>
        <label>Is this your first rodeo (Umm I mean... baby)?</label>
        <select
          required
          onChange={e => setFirstPregnancy(e.target.value)}
          selected={firstPregnancy}
        >
          <option value=''>--Please choose an option--</option>
          <option value="yes">Yes!</option>
          <option value="no">Nah, I'm experienced!</option>
        </select>
        <label>How old is baby?</label>
        <select
          required
          onChange={e => setBabyAge(e.target.value)}
          selected={babyAge}
        >
          <option value=''>--Please choose an option--</option>
          <option value="under 8 weeks">Newborn- Under 8 weeks</option>
          <option value="2-4 months">2-4 months</option>
          <option value="4-6 months">4-6 months</option>
          <option value="6-8 months">6-8 months</option>
          <option value="8 months+">8 months+</option>
        </select>
        <label>Which topics could we help with?</label>
        {postnatalOptions.map(item => <Checkbox 
          key={item.value}
          label={item.text}
          value={item.value} 
          onChange={() => {
            setPostnatal({
              ...postnatal,
              [item.value]: !postnatal[item.value],
            });
          }}
        />)}
      </>
    )
  }

  const renderBusyMama = () => {
    return (
      <>
        <label>Tell us about your kiddos - how old is your youngest?</label>
        <select
          required
          onChange={e => setYoungest(e.target.value)}
          selected={youngest}
        >
          <option value=''>--Please choose an option--</option>
          <option value="toddler">Toddler</option>
          <option value="kids">Kid</option>
          <option value="teen">Teen</option>
          <option value="adult">I’m a grand-mama</option>
        </select>
        <label>Which topics could we help with?</label>
        {busymamaOptions.map(item => <Checkbox 
          key={item.value}
          label={item.text}
          value={item.value} 
          onChange={() => {
            setBusymama({
              ...busymama,
              [item.value]: !busymama[item.value],
            });
          }}
        />)}
      </>
    )
  }

  return (
    <>
    <form onSubmit={handleSubmit}>
      {loading && <Loading />}
      <label>Which city are you in?</label>
      <input
        type='city'
        required
        value={city}
        onChange={e => setCity(e.target.value)}
      />
      <label>What stage of mama-hood are you in?</label>
      <select
        required
        onChange={e => setStage(e.target.value)}
      >
        <option value=''>--Please choose an option--</option>
        <option value="prenatal">I have a bun in the oven (Prenatal)</option>
        <option value="postnatal">I had a baby 0-12 months ago (Postnatal)</option>
        <option value="busymama">I’m a seasoned mama. My kids are anywhere from 1-100 (Busy Mama)</option>
      </select>
      {stage === 'prenatal' && renderPrenatal()}
      {stage === 'postnatal' && renderPostnatal()}
      {stage === 'busymama' && renderBusyMama()}
      <Button type='submit' variant="contained" color="secondary">Submit</Button>
      {error && <Error errMsg={error} />}
    </form>
    </>
  )
}

export default MoreInfoForm;
