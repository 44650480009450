import React from 'react';
import Countdown from '../../components/Countdown/Countdown';
import { Icon } from 'semantic-ui-react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import './ThankYou.css';

const ThankYouBabyMassage = () => {
  return (
    <div className='landing-component-col thankyou' id='thankyou-baby-massage'>
      <ScrollToTop />
      <h1>YOU'RE OFFICIALLY ON THE WAITLIST!</h1>
      <h4>The course will be launching on April 28</h4>
      <Countdown />
      <div className='list-with-icon'>
        <Icon name='arrow alternate circle right'/>
        <p>PS. I will be sending you a mini-course for FREE in the next few days! EEK... Check your inbox mama!</p>
      </div>
    </div>
  )
};

export default ThankYouBabyMassage;