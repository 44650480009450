import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import './Hero.css';
import { Button } from '@material-ui/core';
import { AuthUserContext } from '../../context';

const IllustrationHero = ({ background, title, buttonText, linkText, }) => {
  const history = useHistory();
  const Auth = useContext(AuthUserContext);
  let greeting = 'Hi, Busy Mama!';

  if (Auth.stage === 'prenatal') {
    greeting = 'Hi, Prenatal Mama!';
  } else if (Auth.stage === 'postnatal') {
    greeting = 'Hi, Postnatal Mama!';
  }

  const handleClick = () => {
    localStorage.removeItem('stage');
    Auth.setState('stage', null);
    history.push('/');
  };

  const handleLinkClick = () => {

  }

  return (  
    <div className='hero-illustration-wrapper'>
      <div className='hero hero-illustration'>
        <img src={background} alt={title} />
        <div className='hero-body illustration-body'>
          <h2>{greeting}</h2>
          <h1>{title}</h1>
          <a href={`https://www.booking.mamamobile.com/select-service?stage=${Auth.stage}`}>
            <Button variant="outlined" color="primary">
              {buttonText}
            </Button>
          </a>
          <p className='link' onClick={handleClick}>{`Not a ${Auth.stage === 'busymama' ? 'busy': Auth.stage} mama? Click me to reselect your stage!`}</p>
        </div>
      </div>
      <div className='wave-wrap secondary'>
        <div className='wave-inner'></div>
      </div>
    </div>
  )
};

export default IllustrationHero;