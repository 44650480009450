import React from 'react';
import { Modal } from 'semantic-ui-react'
import './Modal.css';
import CareerForm from '../Form/CareerForm';

const CareerApplicationModal = () => {
  return (
    <Modal trigger={<button className='button-outline mid'>Join Our Team!</button>} closeIcon>
      <Modal.Content className='subscribe-modal' id='career-subscribe-modal'>
        <h3>Join the Mama Mobile Family!</h3>
        <h5>We can't wait to meet you :)</h5>
        <div className='form-wrapper'>
          <CareerForm />
        </div>
      </Modal.Content>
    </Modal>
  )
};

export default CareerApplicationModal;