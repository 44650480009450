import React from 'react';

const Display = ({ time, title }) => (
  <div className='countdown-display-wrapper'>
    <div className='countdown-display'>
      {time}
    </div>
    <p>{title}</p>
  </div>
);

export default Display;