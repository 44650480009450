const emailBabyMassageCourse = (data) => {
  const urlReceipt = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/babyMassageCourseReceipt`;
  const urlThanks = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/babyMassageCourseEnroll`;

  const fetchData = { 
    method: 'POST', 
    body: JSON.stringify(data),
  };

  fetch(urlReceipt, fetchData);
  fetch(urlThanks, fetchData);
};

export default emailBabyMassageCourse;