const emailCourseQuestion = (data) => {
  const url = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/courseQuestion`;

  const fetchData = { 
    method: 'POST', 
    body: JSON.stringify(data),
  };

  return fetch(url, fetchData);
};

export default emailCourseQuestion;