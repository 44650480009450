const createStripeCustomer = (user) => {
  const url = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/stripe/createCustomer`;
  // const url = 'http://localhost:5000/mama-mobile-238622/us-central1/api/stripe/createCustomer';
  
  return new Promise(resolve => fetch(url, {
    method: 'POST',
    body: JSON.stringify(user)
  }).then(res => resolve(res.json())));
};

export default createStripeCustomer;