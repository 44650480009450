import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import MaterialButton from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { AuthUserContext } from '../../context';

const styles = {
  sideList: {
    width: '240px',
  },
  divider: {
    width: '100%',
    margin: '0 auto',
  },
  icon: {
    color: 'var(--color-navy)',
  },
  phone: {
    margin: '30px auto',
  },
  phoneNumber: {
    fontWeight: 500,
  }
}

// const routes = ['about', 'gift-card', 'group-booking', 'blog', 'contact', 'careers'];
const routes = ['upcoming-workshops', 'baby-massage-course', 'blog', 'learning', 'career', 'login'];

class MobileNav extends React.Component {
  state = {
    right: false,
  };

  static contextType = AuthUserContext;

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const sideList = (
      <div id='nav-side-list' style={styles.sideList}>
        <div id='nav-side-list-logo'>
          <NavLink to='/'>
            mama mobile
          </NavLink>
        </div>
        <Divider style={styles.divider} />
        <List>
          {routes.map((text, index) => {
            let endPoint = text.split('-').join(' ');
            return (
              <NavLink key={text} to={`/${text}`}>
                <ListItem button>{endPoint}</ListItem>
              </NavLink>
            )
          })}
          <a href='https://www.alli.io/?utm_source=mamamobile&utm_medium=website&utm_campaign=navbar' target='__blank'><ListItem>Therapy</ListItem></a>
        </List>
        <Divider style={styles.divider}/>
        {/* <div style={styles.phone}>
          <Icon name='phone' style={{color: 'rgb(168, 168, 168)', paddingRight: '20px'}} />
          <a style={styles.phoneNumber} href='tel:866-266-3779'>1 (866) 266 - 3779</a>
        </div> */}
          <a href={`https://www.booking.mamamobile.com/select-service?stage=${this.context.stage}`}>
            <Button style={{margin: '20px'}} type='submit' variant="contained" color="primary">book now</Button>
          </a>
        <Divider style={styles.divider}/>
        {/* <div id='mobile-nav-social'>
          <a href='https://www.instagram.com/mamamobilemassage/' target='_blank'>
            <Icon name='instagram' size='big' style={{color: '#D7AED1'}} />
          </a>
          <a href='https://www.facebook.com/mamamobilemassagetherapy/' target='_blank'>
            <Icon name='facebook' size='big' style={{color: '#D7AED1'}} />
          </a>
        </div> */}
      </div>
    );

    return (
      <div id='mobile-nav'>
        <MaterialButton onClick={this.toggleDrawer('right', true)}>
          <i style={styles.icon} className="material-icons">menu</i>
        </MaterialButton>
        <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('right', false)}
            onKeyDown={this.toggleDrawer('right', false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default MobileNav;