import React from 'react';

const ReviewCard = ({ card }) => {
  const { type, image, review, alt, name, source, link } = card;

  if (type === 'image') {
    return (
      <div className='review-card image'>
        <img src={image} alt={alt} /> :
      </div>
    )
  }

  return (
    <div className='review-card'>
      <div className='review-card-body'>
        <img className='open-quote' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1597269535/quote_open.png' alt='open quote' />
        <div className='review-card-content'><p>{review}</p></div>
        <img className='close-quote' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1597269535/quote_close.png' alt='close quote' />
      </div>
      <div className='review-card-footer'>
        <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1597273210/5stars.png' alt='5 star review'/>
        <p>- {name}</p>
        <a href={link} target="_blank">
          <label>({source})</label>
        </a>
      </div>
    </div>
  )
}

export default ReviewCard;