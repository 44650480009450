const theme = {
  "palette": {
    "common": {
      "black": "#000",
      "white": "#fff"
    },
    "background": {
      "paper": "#fafafa",
      "default": "#fafafa"
    },
    "primary": {
      "light": "#c2bcc1",
      "main": "#322030",
      "dark": "#1f131e",
      "contrastText": "#fff"
    },
    "secondary": {
      "light": "#efcbb9",
      "main": "#ca5116",
      "dark": "#b7370c",
      "contrastText": "#fff"
    },
    "info": {
      "light": "#e6e9ee",
      "main": "#abb4c5",
      "dark": "#919cb0",
      "contrastText": "#000"
    },
    "error": {
      "light": "#e3ccd1",
      "main": "#a15467",
      "dark": "#853a4a",
      "contrastText": "#fff"
    },
    "text": {
      "primary": "#000",
      "secondary": "#444",
      "disabled": "rgba(209, 209, 209, 1)",
      "hint": "rgba(202, 81, 22, 1)"
    },
  },
  "typography": {
    "fontFamily": "Galano-reg, Roboto, Helvetica, Arial, sans-serif",
  },
  "shape": {
    "borderRadius": "0",
  },
  "overrides": {
    "MuiDrawer": {
      "paperAnchorDockedRight": {
        "width": "100%",
        "maxWidth": '400px',
      },
    },
    "MuiInput": {
      "root": {
        "width": "100%",
        "border": "1px solid #bbbbbb",
        "fontFamily": "Galano-reg, Roboto, Helvetica, Arial, sans-serif",
      },
    },
    "MuiIcon": {
      "colorPrimary": {
        "color": "#abb4c5",
      },
    },
  },
}

export default theme;