import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

const UpcomingWorkshopCard = ({ card }) => {
  const { title, shortDescription, img, alt, date  } = card;
  const eventDate = moment(date);

  return (
    <div className='upcoming-event-card'>
      <div className='upcoming-event-body'>
        <div className='upcoming-event-card-img'>
          <img src={img} alt={alt} />
          {/* <div className='date-avatar'>
            <div className='date-avatar-month'>{eventDate.format('MMM')}</div>
            <div className='date-avatar-day'>{eventDate.format('D')}</div>
          </div> */}
        </div>
        <h5>{title}</h5>
        {shortDescription.map((item, idx) => <p key={idx}>{item}</p>)}
      </div>
      {/* <Link to={`/upcoming-workshops/${card.id}`}> */}
      <a href='https://www.alli.io/workshops/banish-mom-guilt?utm_source=website&utm_medium=mama_mobile' target='_blank' >
        <Button variant="contained" color="secondary">View Workshop</Button>
      </a>
      {/* </Link> */}
    </div>
  )
}

export default UpcomingWorkshopCard;