import React from 'react';
import { Button } from '@material-ui/core';

const PricingCard = ({ card, route }) => {
  const { price, duration } = card;
  return (
    <div className='pricing-card'>
      <h4>{duration}</h4>
      <h3>{price}</h3>
      <a href={route}>
        <Button variant="contained" color="secondary">Book Now</Button>
      </a>
    </div>
  )
}

export default PricingCard;