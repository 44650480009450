import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from 'semantic-ui-react'
import ListMenu from './ListMenu';
import Main from './Main';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuText: {
    color: 'white',
    fontFamily: 'Galano-semibold, sans serif',
    fontSize: '18px',
    marginLeft: '8px',
  },
  appBar: {
    top: '70px',
    backgroundColor: '#000000ab',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    borderRight: '1px solid #0000002e',
    borderRadius: 0,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#3a3a3a',
    color: 'white',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
    fontSize: '18px',
  },
  drawerHeaderContent: {
    paddingLeft: '15px',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  divider: {
    backgroundColor: 'rgba(255,255,255,.35)',
  },
  icon: {
    color: 'white',
  }
}));

const ACTIVE_VIDEO = {
  title: 'Welcome',
  duration: '5m 41s',
  url: 'https://player.vimeo.com/video/410726565',
};

const CourseSeries = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [activeVideo, setActiveVideo] = useState(ACTIVE_VIDEO);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ScrollToTop />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Icon className={classes.icon} name='list ul' />
            <p className={clsx(classes.menuText, open && classes.hide)}>
              content
            </p>
          </IconButton>
          <div className='learning-nav-title'>
            <h1>Baby Massage Course</h1>
            <p>{activeVideo.title}</p>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.drawerHeaderContent}>
            <Icon className={classes.icon, classes.HeaderContentIcon} name='list ul' />
            <p className={classes.menuText}>
              content
            </p>
          </div>
          <IconButton onClick={handleDrawerClose}>
            <Icon className={classes.icon} name='close' />
          </IconButton>
        </div>
        <Divider className={classes.divider} />
        <ListMenu setActiveVideo={setActiveVideo} closeMenu={handleDrawerClose}/>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Main video={activeVideo} />
      </main>
    </div>
  );
}

export default CourseSeries;