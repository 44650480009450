import React, { useContext, useState } from 'react';
import emailCourseQuestion from '../../util/emailCourseQuestion';
import { AuthUserContext, FirebaseContext } from '../../context';
import Error from '../../components/Error/Error';
import emailError from '../../util/emailError';

const QandA = ({ course }) => {
  const Auth = useContext(AuthUserContext);
  const Firebase = useContext(FirebaseContext);
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    const instructorFirstName = course.instructor ? course.instructor.split(' ')[0] : 'Sarah';

    const data = {
      email: Auth.user,
      name: Auth.name,
      instructor: course.instructor ? course.instructor : 'Sarah Rennick',
      courseTitle: course.instructor ? course.title : 'Baby Massage 101',
      question,
    };
    
    try {
      await Firebase.createDoc('learning_questions', data);
      await emailCourseQuestion(data);
      alert(`Your question has been sent to ${instructorFirstName} - the answer will be emailed to you!`);
      setQuestion('');
    } catch (err) {
      emailError(err.message, data);
      setError(`There seems to be a problem on our end, but we are trying to send your question to ${instructorFirstName}! You can alway email us at hello@mamamobile.com`);
    }
    setLoading(false);
  };

  return (
    <div id='learning-overview'>
      <form onSubmit={handleSubmit}>
        <textarea
          rows={4}
          placeholder='Ask your question here to share with the instructor and other mamas!'
          onChange={(e) => setQuestion(e.target.value)}
          value={question}
        />
        <button className='button-outline yellow'>{loading ? 'sending...' : 'Ask'}</button>
        {error && <Error errMsg={error} />}
      </form>
    </div>
  )
}

export default QandA;