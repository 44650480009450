import React from 'react';
import { useParams } from 'react-router-dom';
import Quote from '../../components/Landing/Quote';
import WhyPelvic from '../../components/Landing/WhyPelvic';
import PelvicJourney from '../../components/Landing/PelvicJourney';
import Blog from '../../components/Landing/Blog';
import FAQs from '../../components/Landing/FAQs';
import PelvicCall from '../../components/Landing/PelvicCall';
import HeroPolaroid from '../../components/Hero/HeroPolaroid';
import HowItWorksPelvic from '../../components/Landing/HowItWorksPelvic';
import WhyUs from '../../components/Landing/WhyUs';
import PelvicPricing from '../../components/Landing/PelvicPricing';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import SEO from '../../components/SEO/SEO';

const faqs = [
  {
    question: 'Are virtual pelvic floor physiotherapy sessions covered under my insurance?',
    answer: 'Yes, most insurance companies cover virtual appointments the same way as in person! Make sure to contact your provider regarding your coverage.',
  },
  {
    question: 'Will I receive an insurance receipt?',
    answer: 'Heck yes mama! Within 24 hours following your pelvic floor physiotherapy appointment you will be emailed an insurance receipt. Please note that your file is updated with the correct name that you wish to appear on it.',
  },
  {
    question: 'How do I know if pelvic floor physiotherapy is right for me?',
    answer: 'Not sure if pelvic floor physio is the right next step? Set up a 15-minute consultation with one of our pros to discuss your current stage - we’re happy to dive deeper and explore options with you!',
  },
  {
    question: 'Do I need a doctor’s note to see a pelvic floor physiotherapist?',
    answer: 'Nah! You don’t need a doctor’s note to be assessed and treated by one of our pelvic floor physiotherapists - you can book anytime! Some insurance companies do require one so it’s always good to check in first!',
  },
  {
    question: 'What equipment do I need for my appointment?',
    answer: 'A computer or tablet with video and microphone.',
  },
  {
    question: 'Can I use my phone for the appointments?',
    answer: 'We suggest using a computer or tablet when possible, however, a phone could work as well.',
  },
  {
    question: 'Is telehealth technology difficult to use?',
    answer: "We made the process super simple for you! We will provide you with a link to the appointment and all you have to do is click the link to join 5 minutes before your appointment!",
  },
  // {
  //   question: 'Is the quality of care the same as an in-person visit?',
  //   answer: '',
  // },
  // {
  //   question: 'Could I get general physiotherapy as well?',
  //   answer: '',
  // },
  // {
  //   question: 'Could my partner get physiotherapy?',
  //   answer: '',
  // },
  {
    question: 'Do I have to be a mom to receive services from Mama Mobile?',
    answer: 'We totally understand that parents come in all different forms - aunts, grandmas, close-friends, dads, grandpas; Mama Mobile is for anyone who identifies as a parent.',
  },
  {
    question: 'Should all mamas see a pelvic floor physio after birth?',
    answer: 'Yes! Every person male or female would benefit from seeing a pelvic floor physiotherapist however those that carried a baby at some point in their lives should heavily consider it.',
  }
];

const data = {
  prenatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1599078987/_K3A9693_ftebd4.jpg',
      title: 'Pelvic Floor Physiotherapy - it’s like regular physiotherapy but for internal pelvic muscles, low back, and core!',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `One of the biggest misconceptions about pelvic floor physiotherapy is that you should only see a therapist if you are experiencing symptoms.`,
        {
          type: 'br'
        },
        {
          type: 'br'
        },
        {
          type: 'underline',
          content: 'If you are pregnant you should be seeing a pelvic floor physiotherapist.'
        }
      ],
      // sub: `If you are pregnant you should be seeing a pelvic floor physiotherapist.`
    },
    review: {
      main: ['"I see a significant difference for the moms who get prenatal pelvic floor physiotherapy. They are more in control of their pelvic floor when pushing and they experience less tearing."'],
      sub: '- Amy Owen (Birth Doula)'
    },
    whyPelvic: [
      {
        title: 'Preparation for Birth and Delivery',
        line: true,
        description: [
          'You will learn how to perform proper perineal massage (yes, down there) to prevent tearing, discover the delivery positions that will work best for your body, develop strategies for breath control during labor and so much more.',
        ],
      },
      {
        title: 'Prevent tearing, urinary leakage, diastasis, and so much more',
        description: [
          `Learn how to use your pelvic floor muscles properly during pregnancy enables you to connect to and use your pelvic floor much more easily post-partum. This helps mitigate common post-partum symptoms (think urinary leakage with sneezing, diastasis rectus abdominis, or a feeling like something is falling out, yikes!)`
        ],
        line: true,
      },
      {
        title: 'Injury and Pain Reduction',
        line: true,
        description: ['Low back, hip and pelvic pain are just a few areas that commonly get addressed during your sessions to help you achieve a pain free pregnancy.'],
      },
      {
        title: 'Functional Concerns',
        description: ['Your initial assessment will screen, treat and prevent you from experiencing common functional concerns like bladder, constipation and low mobility.'],
      },
    ],
    whyUs: [
      {
        line: true,
        title: 'Empowerment',
        description: ['Virtual pelvic floor physiotherapy empowers you to become more active with the tools and education needed to take control of your pelvic health!'],
      },
      {
        line: true,
        title: 'Access from anywhere',
        description: ['Virtual care breaks down barriers and allows you to access the top prenatal pelvic floor physiotherapists in the country no matter where you are.'],
      },
      {
        line: true,
        title: 'Save time and hassle',
        description: ['You are busy. Why spend hours in traffic and parking when you can have it in the comfort of your home?'],
      },
      {
        title: 'Secure and safe',
        line: true,
        description: ["We have developed a secure and reliable system so you can communicate with your pelvic floor physiotherapist with ease and assurance. Best of all, there's no need to download anything!"],
      },
      {
        title: 'Insurance covered',
        description: ['All Mama Mobile physiotherapists are Registered in Ontario. Our services are covered under extended health insurance and you will receive a receipt after your pelvic floor physiotherapy treatment.'],
      },
    ],
    pricing: {
      firstDescription: [`Your first session begins with a virtual assessment that helps us identify the root cause of any discomfort you are feeling during your pregnancy. We will develop a personalized treatment plan that will prepare your body for labour and postpartum recovery.`],
      subsequentDescription: [
        `Sessions include pelvic floor education, guided stretches, exercises, and self/partner guided treatment techniques. Our goal is to empower you with the tools and education needed to take control of your pelvic health!`,
        `After every session you will be given a personalized overview of the session from your therapist, this includes home care exercises and stretches to ensure you are on track with your goals.`,
      ],
    },
  },
  postnatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1599078987/_K3A9693_ftebd4.jpg',
      title: 'Pelvic Floor Physiotherapy - it’s like regular physiotherapy but for internal pelvic muscles, low back, and core!',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `In Europe, pelvic-floor physiotherapy is offered as a routine part of public health for women to recover after giving birth.`,
      ],
      sub: `Why are we not prioritizing it here?`
    },
    review: {
      main: [
        `"After a "fast and furious" delivery of my beautiful baby, I had ongoing vaginal discomfort for several months. I was also worried about my pelvic weakness and how that might impact future pregnancies. I had planned on going for pelvic physiotherapy after my pregnancy, but due to the pandemic (and with a new baby at home), I was worried that I would not be able to get the help I needed.  When I found out that Mama Mobile was offering virtual pelvic physiotherapy I was so relieved. With the expert guidance of their physiotherapist Ellie, I was able to have several physiotherapy sessions which helped to reduce my discomfort and improve my pelvic strength. What a difference it has made for me!`,
        {
          type: 'br'
        },
        {
          type: 'br'
        },
        `In addition, Ellie helped to improve my posture for carrying my baby and gave me excellent advice on how to protect my back. I would highly recommend these services to other new moms!"`,
      ],
      sub: `- Kate S, Toronto ON`
    },
    whyPelvic: [
      {
        title: 'Vaginal Birth Recovery',
        line: true,
        description: [
          'Your pelvic floor muscles are stretched, strained and often torn postpartum - properly rehabilitating these muscles is important.',
        ],
      },
      {
        title: 'Core Recovery',
        description: [
          `Your physiotherapist will check for diastasis recti (abdominal muscle separation) and work with you to restore your core to avoid injury!`
        ],
        line: true,
      },
      {
        title: 'C-Section Recovery',
        line: true,
        description: ['Pelvic floor physiotherapy is for c-section mamas too! Restore your core back to health and work on sensitive scars to avoid future dysfunction.'],
      },
      {
        title: 'Prevention + Treatment',
        description: ['Prolapse, sexual dysfunction, low back/hip pain, bladder issues, and constipation are just a few of the complications you may face but don’t have to!'],
      },
    ],
    whyUs: [
      {
        line: true,
        title: 'Empowerment',
        description: ['Virtual pelvic floor physiotherapy empowers you to become more active with the tools and education needed to take control of your pelvic health!'],
      },
      {
        line: true,
        title: 'Access from anywhere',
        description: ['Virtual care breaks down barriers and allows you to access the top prenatal pelvic floor physiotherapists in the country no matter where you are.'],
      },
      {
        line: true,
        title: 'Save time and hassle',
        description: ['You are busy. Why spend hours in traffic and parking when you can have it in the comfort of your home?'],
      },
      {
        title: 'Secure and safe',
        line: true,
        description: ["We have developed a secure and reliable system so you can communicate with your pelvic floor physiotherapist with ease and assurance. Best of all, there's no need to download anything!"],
      },
      {
        title: 'Insurance covered',
        description: ['All Mama Mobile physiotherapists are Registered in Ontario. Our services are covered under extended health insurance and you will receive a receipt after your pelvic floor physiotherapy treatment.'],
      },
    ],
    pricing: {
      firstDescription: [`Your first session begins with a virtual assessment that helps us identify the root cause of any discomfort you are feeling. We will develop a personalized treatment plan that will help your postpartum recovery.`],
      subsequentDescription: [
        `Sessions include pelvic floor education, guided stretches, exercises, and self/partner guided treatment techniques. Our goal is to empower you with the tools and education needed to take control of your pelvic health!`,
        `After every session you will be given a personalized overview of the session from your therapist, this includes home care exercises and stretches to ensure you are on track with your goals.`,
      ],
    },
  },
  busymama: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1599078987/_K3A9693_ftebd4.jpg',
      title: 'It’s never too late to restore the function and strength of your pelvic floor!',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `Many mamas begin pelvic floor physiotherapy years after giving birth vaginally or through a c-section. Our pelvic floor physiotherapists are trained to work with you no matter your stage of motherhood from pregnancy through to menopause.`,
      ],
      sub: `Now is the perfect time to gain control of your pelvic floor!`
    },
    review: {
      main: [
        `Fear not, it’s never too late to take action to strengthen your pelvic floor and take control of your pelvic health.`,
      ]
    },
    whyPelvic: [
      {
        title: 'Injury and Pain Reduction',
        line: true,
        description: [
          'Persistent low back pain? Pain on your c-section scar? Hip pain? Your pelvic floor physiotherapist will take time to assess your posture and ensure your body is moving in the most optimal way to resolve aches and pains.',
        ],
      },
      {
        title: 'Functional Concerns (bladder issues or constipation?)',
        description: [
          `Have you been experiencing ongoing bladder issues? Constipation? Functional concerns are very common and can be successfully resolved through pelvic floor physiotherapy.`
        ],
        line: true,
      },
      {
        title: 'Improve Your Sex Life',
        line: true,
        description: ['Do you experience painful intercourse? Have you been having difficulty achieving an orgasm? Pelvic floor physiotherapy can address any functional concerns like pain with sex. Addressing any general weakness and strengthing your pelvic floor will help to increase pleasure and satisfaction.'],
      },
      {
        title: 'Menopausal Support',
        description: ['Menopause causes the pelvic floor muscles to weaken for many women and a result may be the flare-up of functional concerns or the presentation of new ones. Proper pelvic floor exercise instruction can strengthen pelvic floor muscles and prevent/treat functional concerns such as frequent urination, bowel concerns, or prolapse.'],
      },
    ],
    whyUs: [
      {
        line: true,
        title: 'Empowerment',
        description: ['Virtual pelvic floor physiotherapy empowers you to become more active with the tools and education needed to take control of your pelvic health!'],
      },
      {
        line: true,
        title: 'Access from anywhere',
        description: ['Virtual care breaks down barriers and allows you to access the top prenatal pelvic floor physiotherapists in the country no matter where you are.'],
      },
      {
        line: true,
        title: 'Save time and hassle',
        description: ['You are busy. Why spend hours in traffic and parking when you can have it in the comfort of your home?'],
      },
      {
        title: 'Secure and safe',
        line: true,
        description: ["We have developed a secure and reliable system so you can communicate with your pelvic floor physiotherapist with ease and assurance. Best of all, there's no need to download anything!"],
      },
      {
        title: 'Insurance covered',
        description: ['All Mama Mobile physiotherapists are Registered in Ontario. Our services are covered under extended health insurance and you will receive a receipt after your pelvic floor physiotherapy treatment.'],
      },
    ],
    pricing: {
      firstDescription: [`Your first session begins with a virtual assessment that helps us identify the root cause of any discomfort you are feeling. We will develop a personalized treatment plan that will help your recovery.`],
      subsequentDescription: [
        `Sessions include pelvic floor education, guided stretches, exercises, and self/partner guided treatment techniques. Our goal is to empower you with the tools and education needed to take control of your pelvic health!`,
        `After every session you will be given a personalized overview of the session from your therapist, this includes home care exercises and stretches to ensure you are on track with your goals.`,
      ],
    },
  },
}

const seo = {
  postnatal: {
    title: 'Postnatal',
    description: 'Your pelvic floor muscles are stretched, strained and often torn postpartum - properly rehabilitating these muscles is important. Let us guide you through it.',
  },
  prenatal: {
    title: 'Pregnant',
    description: 'Prep for delivery, prevent tearing, urinary leakage, diastasis with prenatal pelvic floor physiotherapy.',
  },
  busymama: {
    title: 'Busy',
    description: 'Persistent low back pain? Pain on your c-section scar? Bladder issues? Your pelvic floor physiotherapist will take time to assess your postpartum pelvic floor issues and guide you to a pain free life.',
  },
}

const PelvicLanding = () => {
  const { stage } = useParams();

  return (
    <div>
      <SEO 
        title={`Online Pelvic Floor Physiotherapy for ${seo[stage].title} Moms in Ontario | Mama Mobile`}
        description={seo[stage].description}
        url={`service/${stage}/pelvic`}
      />
      <ScrollToTop />
      <HeroPolaroid
        image='https://res.cloudinary.com/dhze7gimq/image/upload/v1603494201/_K3A9695_1_rynlt9.jpg'
        title='Virtual Pelvic Floor Physiotherapy'
        description='It’s like regular physiotherapy but for internal pelvic muscles, low back, and core!'
        buttonText={data[stage].hero.buttonText}
        quote='I’ve got 99 problems, but pelvic floor physio will fix 98 of them.'
        route={`https://www.booking.mamamobile.com/select-service?stage=${stage}`}
      />
      <Quote main={data[stage].quote.main} sub={data[stage].quote.sub} secondary />
      <WhyPelvic whyPelvic={data[stage].whyPelvic} />
      <Quote main={data[stage].review.main} sub={data[stage].review.sub} secondary />
      {/* <PelvicJourney journey={data[stage].journey} /> */}
      <HowItWorksPelvic />
      <PelvicPricing pricing={data[stage].pricing} route={`https://www.booking.mamamobile.com/select-service?stage=${stage}`} />
      {/* <Quote main={[`"I'm so happy I got ahead of it! Every mom needs it!"`]} sub='- Steph' /> */}
      <WhyUs whyUs={data[stage].whyUs} title='Why are we virtual?' />
      {/* <PelvicCall /> */}
      <FAQs faqs={faqs} />
      {/* <Blog blogs={data[stage].blogs} /> */}
    </div>
  )
}

export default PelvicLanding;