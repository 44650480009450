import React from 'react';
import HowItWorksCard from '../Card/HowItWorksCard';

const howItWorks = [
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597276686/how_it_works_massage_1.png',
    alt: 'book your massage online',
    number: 1,
    title: 'Book Your Massage Online.'
  },
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597276686/how_it_works_massage_2.png',
    alt: 'registered massage therapist come to your house',
    number: 2,
    title: 'Your massage therapist will arrive at your location with all the supplies!',
    opposite: true,
  },
  {
    image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597276686/how_it_works_massage_3.png',
    alt: 'time for massage',
    number: 3,
    title: 'Massage time!'
  }
];

const HowItWorks = () => {
  return (
    <div className='landing-component how-it-works secondary'>
      <h2 className='landing-title'>How It Works</h2>
      <div className='how-it-works-card-wrapper'>
        {howItWorks.map((item, idx) => <HowItWorksCard key={idx} card={item} />)}
      </div>
    </div>
  )
}

export default HowItWorks;