import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { AuthUserContext } from '../../context';
import './Header.css';
import Nav from './Nav';
import MobileNav from './MobileNav';
import AvatarLogout from '../../pages/Login/Logout';

const Header = () => {
  const Auth = useContext(AuthUserContext);
  const location = useLocation();
  const [top, setTop] = useState(true);

  const handleScroll = () => {
    const scrollTop = window.scrollY;

    if (scrollTop > 160) {
      setTop(false);
    } 
    
    if (scrollTop < 160) {
      setTop(true);
    }
  }
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      {location.pathname !== '/' && <div id='header' className={top ? 'no-background' : ''}>
        <div className="logo"> 
          <Link to='/'>
            mama mobile
          </Link>
        </div>
        <div className='center'>
          {Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 700 ? <Nav user={Auth.user} />: <MobileNav user={Auth.user}/>}
          {Auth.user && <AvatarLogout />}
        </div>
        <div id='social-media'>
          <a href='https://www.instagram.com/mamamobile.co/' target='_blank'>
            <Icon name='instagram' size='big' />
          </a>
          <a href='https://www.facebook.com/mamamobile.co/' target='_blank'>
            <Icon name='facebook' size='big' />
          </a>
        </div>
      </div>}
    </div>
  )
};

export default Header;