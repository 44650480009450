import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Footer.css';
import { Icon } from 'semantic-ui-react';

const Footer = () => {
  const location = useLocation();
  return (
    <div>
      {location.pathname !== '/' && 
        <div id='footer'>
          <div className='footer-content'>
            <div className='footer-subscribe'>
              <label>Wanna be pals?</label>
              <div className='input-submit'>
                <input 
                  placeholder='Enter your e-mail here!'
                />
                <div className='input-submit-button'>SUBMIT</div>
              </div>
            </div>
            <div className='footer-links-wrapper'>
              <div className='footer-links'>
                {/* <Link to='/about'>Massage Therapy</Link> */}
                {/* <Link to='/baby-massage-course'>Physiotherapy</Link> */}
                {/* <Link to='/baby-massage-course'>Pelvic Floor Physiotherapy</Link> */}
                {/* <Link to='/gift-card'>Baby Massage Course</Link> */}
                <a href='https://www.alli.io/?utm_source=mamamobile&utm_medium=website&utm_campaign=footer' target='__blank'>Therapy/Counselling</a>
                <Link to='/upcoming-workshops'>Workshops</Link>
                {/* <Link to='/gift-card'>Gift Card</Link> */}
                <Link to='/blog'>Blog</Link>
                <Link to='/career'>Careers</Link>
              </div>
              <div className='footer-links'>
                <Link to='/blog/Your-Health-Is-Our-Top-Priority'>Covid Protocols</Link>
                <Link to='/service/prenatal/massage'>Prenatal Massage</Link>
                <Link to='/service/postnatal/massage'>Postnatal Massage</Link>
                <Link to='/service/busymama/massage'>Busy Mama Massage</Link>
                <Link to='/baby-massage-course'>Baby Massage Course</Link>
              </div>
            </div>
          </div>
          <a className='footer-contact' mailto='hello@mamamobile.com'>hello@mamamobile.com</a>
          <p className='copy-right'>© Mama Mobile - 2020 all rights reserved</p>
          {/* <div>
            <div className='footer-contact-info'>
              <Icon name='mail' style={{color: 'rgb(168, 168, 168)', paddingRight: '20px'}} />
              <a className='underline' href='mailto:hello@mamamobile.com'>hello@mamamobile.com</a>
            </div>
            
          </div> */}
          <div className='wave-wrap footer-wave'>
            <div className='wave-inner'></div>
          </div>
        </div>
      }
    </div>
  )
}

export default Footer;