import React from 'react';
import './WorkshopLanding.css';
import moment from 'moment';
import WorkshopForm from './WorkshopForm';
import AddToCal from '../../components/AddToCal/AddToCal';
import { allWorkshops } from '../../util/upcomingWorkshops';
import { withRouter } from "react-router";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';


const WorkshopLanding = ({ match }) => {
  let id = match.params.id;
  const { title, date, time, img, alt, instructor, longDescription, shortDescription, startTime, endTime } = allWorkshops[id];
  const event = {
    title,
    description: shortDescription[0],
    startTime,
    endTime,
  };
  const eventDate = moment(date);

  return (
    <div className='workshop-landing'>
      <ScrollToTop />
      <div className='workshop-left'>
        <div className='upcoming-event-card-img'>
          <img src={img} alt={alt} />
          <div className='date-avatar'>
            <div className='date-avatar-month'>{eventDate.format('MMM')}</div>
            <div className='date-avatar-day'>{eventDate.format('D')}</div>
          </div>
        </div>
        <AddToCal event={event} buttonText='Add workshop to your calendar' />
      </div>
      <div className='workshop-landing-body'>
        <h1>{title}</h1>
        <div>
          <h5>Registration Information</h5>
          <p><b>Date:</b>{eventDate.format('dddd, MMMM Do')}</p>
          <p><b>Time:</b>{time}</p>
          <p><b>Workshop Info:</b>This lesson will be held online, details on how to access will be sent to you prior to.</p>
          <p><b>Instructor:</b>{instructor}</p>
          <p><b>Cost:</b>FREE</p>
          <h5>About This Workshop</h5>
          {longDescription.map((item, idx) => {
            return <p key={idx}>{item}</p>
          })}
        </div>
        <WorkshopForm workshop={allWorkshops[id]} />
      </div>
    </div>
  )
}

export default withRouter(WorkshopLanding);