import React from 'react';

const CourseEbook = ({ ebook }) => {
  const { img, url, title } = ebook;
  return (
    <div className='learning-section ebook'>
      <a target="_blank" href={url} download={title}>
        <h3>{title}</h3>
        <img src={img} alt={title} />
        <p><b>{`Download the ${title}`}</b></p>
      </a>
    </div>
  )
};

export default CourseEbook;