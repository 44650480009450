import React, { useState } from 'react';
import Blob from './Blob';
import { Icon } from 'semantic-ui-react';
import ReviewCard from '../Card/ReviewCard';

const Reviews = ({ reviews, secondary }) => {
  const [displayIdx, setDisplayIdx] = useState(0);

  const handleLeft = () => {
    if (displayIdx > 0) {
      setDisplayIdx(displayIdx - 1);
    }
  }

  const handleRight = () => {
    if (displayIdx === reviews.length - 1) {
      setDisplayIdx(0);
    } else {
      setDisplayIdx(displayIdx + 1);
    }
  }

  console.log(displayIdx);

  return (
    <div className={secondary ? 'landing-component reviews secondary' : 'landing-component reviews'}>
      <h2 className='landing-title'>Look mom, people love us...</h2>
      <div className='reviews-body'>
        <div className='control-icon' onClick={handleLeft}>
          <Icon name='chevron left' />
        </div>
        <div className='reviews-wrapper'>
          {reviews.slice(displayIdx).map((review, idx) => (
            <ReviewCard key={idx} card={review} displayIdx={displayIdx} />
            ))}
          {reviews.map((review, idx) => (
            <ReviewCard key={idx} card={review} displayIdx={displayIdx} />
            ))}
        </div>
        <div className='control-icon' onClick={handleRight}>
          <Icon name='chevron right' />
        </div>
      </div>
    </div>
  )
};

export default Reviews;