import React, { useContext } from 'react';
import Blob from './Blob';
import ServiceCard from '../Card/ServiceCard';
import { AuthUserContext } from '../../context';

const data = {
  prenatal: {
    physio: {
      title: 'Prenatal Pelvic Floor Physiotherapy',
      route: '/service/prenatal/pelvic',
      description: 'It’s like regular physiotherapy but for internal pelvic muscles, low back, and core! All our certified Physiotherapists have received additional specialized training in pelvic floor physiotherapy.',
      list: [
        {
          title: `Preparation for birth and delivery `,
          description: `Become empowered with how to engage and relax the related muscles for birth and learn different delivery positions to increase your confidence for your upcoming birth or c-section.`,
        },
        {
          title: `Prevention of vaginal tearing during labor`,
          description: `Learn how to perform proper perineal massage to prevent tearing during labor!`,
        },
        {
          title: `Injury and Pain Reduction`,
          description: `Ease you of low back, hip, and pelvic pain for a more comfortable pregnancy.`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9695_keywsr.jpg',
      buttonText: 'Learn more about prenatal pelvic floor physiotherapy',
      quote: `I've got 99 problems and pelvic floor physio solved 86 of them.`
    },
    massage: {
      title: 'In-Home Prenatal Massage Therapy',
      description: 'Get a prenatal massage that suits your schedule in the comfort of your own home. Insurance covered.',
      list: [
        {
          title: `Get time back`,
          description: `Save hours of time commuting to the massage and back. We’ll come to you at the comfort of your home! No traffic jams, no parking pains.`,
        },
        {
          title: `Labour Prep`,
          description: `Our prenatal massage services are often recommended by doctors and midwives to prepare moms for labour, even with high-risk pregnancies at the hospital.`,
        },
        {
          title: `Prenatal Massage Experts`,
          description: `Our Registered Massage Therapists are all certified through a 2-year+ massage therapy program in Ontario, and have all completed additional training to support prenatal mamas.`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      buttonText: 'Learn more about prenatal massage',
      route: '/service/prenatal/massage',
      quote: 'We respect the hustle of mama-hood!'
    }
  },
  postnatal: {
    physio: {
      title: 'Postnatal Pelvic Floor Physiotherapy Therapy',
      route: '/service/postnatal/pelvic',
      description: 'It’s like regular physiotherapy but for internal pelvic muscles, low back, and core! All services performed by Registered Physiotherapists specialized in helping mamas.',
      list: [
        {
          title: `Vaginal Birth Recovery`,
          description: `Your pelvic floor muscles are stretched, strained and often torn postpartum- properly rehabilitating these muscles is important.`,
        },
        {
          title: `Core Recovery`,
          description: `Your physiotherapist will check for diastasis recti (abdominal muscle separation) and work with you to restore your core to avoid injury!`,
        },
        {
          title: `C-Section Recovery`,
          description: `Pelvic floor physio is for c-section mamas too! Restore your core back to health and work on sensitive scars to avoid future dysfunction.`,
        },
        {
          title: `Prevention + Treatment`,
          description: `Prolapse, sexual dysfunction, low back/hip pain, bladder issues, and constipation are just a few of the complications you may face but don’t have to!`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9695_keywsr.jpg',
      buttonText: 'Learn more about postnatal pelvic floor physiotherapy',
      quote: `I've got 99 problems and pelvic floor physio solved 86 of them.`
    },
    massage: {
      title: 'In-Home Postnatal Massage Therapy',
      description: 'Get a postnatal massage that suits your schedule in the comfort of your own home. Insurance covered.',
      list: [
        {
          title: `Hold the babysitter and awkward scheduling`,
          description: `Save money by not having a babysitter. Mamas often bring their baby monitor or breastfeed during the massage. We’ll work with what’s easiest for you!`,
        },
        {
          title: `Postpartum Recovery`,
          description: `Breast massage, leaky boobs, c-section scars, breast pain, whatever it is...we got you covered!`,
        },
        {
          title: `Postnatal Massage Experts`,
          description: `Our Registered Massage Therapists are all certified through a 2-year+ massage therapy program in Ontario, and have all completed additional training to support postnatal mamas and babies.`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      buttonText: 'Learn more about postnatal massage',
      route: '/service/postnatal/massage',
      quote: 'We respect the hustle of mama-hood!'
    }
  },
  busymama: {
    physio: {
      title: 'Pelvic Floor Physiotherapy',
      route: '/service/busymama/pelvic',
      description: 'It’s like regular physiotherapy but for internal pelvic muscles, low back, and core! All services performed by Registered Physiotherapists specialized in helping women at all stages of life.',
      list: [
        {
          title: `Bladder Dysfunctions`,
          description: `Your pelvic floor muscles are stretched, strained and often torn postpartum- properly rehabilitating these muscles is important.`,
        },
        {
          title: `Core Rehabilitation`,
          description: `C-section scar, abdominal separation, and difficulties engaging your core are some of the common issues moms face. It is important to see a pelvic floor physiotherapist sometime after giving birth (even years later!) to ensure your core has recovered.`,
        },
        {
          title: `Prevention + Treatment`,
          description: `Prolapse, sexual dysfunction, low back/hip pain, nocturia, and constipation are just a few of the complications you may face but don’t have to!`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9695_keywsr.jpg',
      buttonText: 'Learn more about pelvic floor physiotherapy',
      quote: `I've got 99 problems and pelvic floor physio solved 86 of them.`
    },
    massage: {
      title: 'In-Home Registered Massage Therapy',
      description: 'Get a Registered massage that suits your schedule in the comfort of your own home. Insurance covered.',
      list: [
        {
          title: `Get Time Back and Save the Babysitter`,
          description: `Save hours of time commuting to the massage and back. We’ll come to you at the comfort of your home! No babysitter, no traffic jams, no parking pains.`,
        },
        {
          title: `Injury Prevention and Improved Posture`,
          description: `Prevent injuries and improve posture by relaxing tight muscles and receiving personalized stretches for ongoing improvement. All the aches and pains in being a busy mama!`,
        },
        {
          title: `Registered Massage Therapists Experts`,
          description: `Our Registered Massage Therapists are all certified through a 2-year+ massage therapy program in Ontario, and are required to complete on-going education. Many are massage instructors and also mamas!`,
        },
      ],
      image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      buttonText: 'Learn more about busy mama massage',
      route: '/service/busymama/massage',
      quote: 'We respect the hustle of mama-hood!'
    }
  }
}

const therapy = {
  title: 'Therapy and Counselling Services',
  description: `Do you feel overwhelmed and lonely at times as you navigate parenthood? You don’t have to do it alone, we're here to help you thrive! Explore your first therapy session for free. (All therapy sessions are through our sister company Alli)`,
  list: [
    {
      title: `Improve Your Mental Wellbeing`,
      description: `Therapy is a decision to exercise your mind. Think of your therapist as your "mental health trainer" helping to bring you clarity, happiness, and a push towards your goals.`,
    },
    {
      title: `Our Therapists speak “parent”`,
      description: `Our dedicated team of psychotherapists, social workers, and psychologists have been handpicked due to their combined life experiences and education related to parenthood!`,
    },
    {
      title: `Virtual - Anywhere in Ontario`,
      description: `Our therapy sessions are secure and easy to access- whether it be from your home office or your closet. You will meet with your therapist over secure video conferencing or via telephone.`,
    },
    {
      title: `We Find Your Most Compatible Therapist`,
      description: `It can be tough to open up to someone before knowing if they are compatible with you. We’ve created a series of questions to best match you with a therapist that fits within your insurance plan and current goals.`,
    },
  ],
  image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1612751358/alli/landing/Edited_Brain_5_awqutv.png',
  buttonText: 'Learn More About Therapy',
  href: 'https://www.alli.io/?utm_source=mamamobile&utm_medium=website&utm_campaign=service',
  quote: `Let's prioritize exercising our minds.`,
}
const Services = () => {
  const Auth = useContext(AuthUserContext);
  const physio = data[Auth.stage].physio;
  const massage = data[Auth.stage].massage;
  
  return (
    <div className='landing-component services secondary'>
      <Blob />
      <h2 className='landing-title'>Our Services</h2>
      <div className='service-wrapper'>
        <ServiceCard 
          title={massage.title} 
          description={massage.description}
          list={massage.list}
          image={massage.image}
          alt={massage.title}
          quote={massage.quote}
          buttonText={massage.buttonText}
          route={massage.route}
        />
        <ServiceCard 
          title={physio.title} 
          description={physio.description}
          list={physio.list}
          image={physio.image}
          alt={physio.title}
          buttonText={physio.buttonText}
          quote={physio.quote}
          route={physio.route}
          opposite
        />
        <ServiceCard 
          title={therapy.title} 
          description={therapy.description}
          list={therapy.list}
          image={therapy.image}
          alt={therapy.title}
          quote={therapy.quote}
          buttonText={therapy.buttonText}
          href={therapy.href}
        />
      </div>
    </div>
  )
}

export default Services;