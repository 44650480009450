import React from 'react';
import './Career.css';
import FamilyCarousel from '../../components/Carousel/FamilyCarousel';
import CareerForm from '../../components/Form/CareerForm';
import CareerApplicationModal from '../../components/Modal/CareerApplicationModal';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import SEO from '../../components/SEO/SEO';

const jessi = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587252110/jessi_noBackground.png';
const angela = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587252110/angela_noBackground.png';
const christina = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587252110/christina_noBackground.png';
const rebecca = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587252110/rebecca_noBackground.png';
const lindsay = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587257789/lindsay_portrait.jpg';

const Career = () => {
  return (
    <div className='page-component' id='career'>
      <SEO 
        title={`Career | Mama Mobile`}
        description={`Earn great money working the hours you choose — without giving up your independence. We find you clients, ensure your safety, provide free training AND direct deposit your pay into your account!`}
        url={`career`}
      />
      <ScrollToTop />
      <div id='career-hero'>
        <h1>
          Join The Mama Mobile Team!
        </h1>
        <h5>
          Earn great money working the hours you choose — without giving up your independence. We find you clients, ensure your safety, provide free training AND direct deposit your pay into your account!
        </h5>
        <CareerApplicationModal />
        {/* <button className='button-outline mid'>Join Our Team!</button> */}
        <div className='background-flex-img-set'>
          <img src={jessi} alt='jessi - mama mobile registered massage therapist'/>
          <img src={angela} alt='angela - mama mobile registered massage therapist'/>
          <img src={christina} alt='christina - mama mobile registered massage therapist'/>
          <img src={rebecca} alt='rebecca - mama mobile registered massage therapist'/>
        </div>
      </div>
      <div id='career-review' className='career-component'>
        <img className='headshot' src={lindsay} alt='lindsay - mama mobile registeredd massage therapist'/>
        <div className='career-body'>
          <h3>"I feel incredibly empowered"</h3>
          <p>
            What I love about working with Mama Mobile is the flexibility. I feel incredibly empowered as a therapist and in control of my practice. I love that we're making healthcare more accessible to new and expecting moms.
          </p>
          <p><b>
            - Lindsay, RMT (Toronto)
          </b></p>
        </div>
        <img className='asset' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1587255720/asset_leaf.png' alt='leaf blue'/>
      </div>
      <div className='career-component' id='career-who' >
        <div className='career-body'>
          <h3>
            Who we are looking for?
          </h3>
          <p>
            We are looking for Registered Massage Therapists who want to connect with moms in a big way! If you are obsessed with personal and professional growth a career with Mama Mobile might be perfect for you. "It will feel like your own practice but you will have the saftey and support of a larger network" 
          </p>
          <h4>
            Is this you? So happy we found each other, let's chat!
          </h4>
          <CareerForm />
          {/* <button className='button-outline'>We want to meet you!</button> */}
        </div>
        <img className='headshot' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1587277160/team_fun_gif.gif' alt='mama mobile massage therapists' />
      </div>
      <div id='career-carousel'>
        <h2>We're not just a team. We're kinda like a big <span className='underline-brush'>family</span>.</h2>
        <FamilyCarousel/>
      </div>
    </div>
  )
};

export default Career;