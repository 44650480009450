import { gql } from 'apollo-boost';

const ADD_WORKSHOP_USER = gql`
  mutation AddNewUser(
    $workshopId: String
    $firstName: String
    $lastName: String
    $email: String
    $tel: String
    $city: String
  ) {
    addWorkshopUser(
      workshopId: $workshopId
      firstName: $firstName
      lastName: $lastName
      email: $email
      tel: $tel
      city: $city
    ) {
      success
      message
    }
  }
`;

export default ADD_WORKSHOP_USER;