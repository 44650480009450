import emailError from './emailError';

const handleCareerApplication = (e, email, name, history) => {
  e.preventDefault();
  const url = `https://api.sendgrid.com/v3/marketing/contacts`;
  const nameArr = name.trim().split(' ');
  const firstName = nameArr[0].charAt(0).toUpperCase() + nameArr[0].substring(1);

  const data = {
    list_ids: [
      "2d8f14f3-5aa2-4b58-8b56-2d17a1e72475"
    ],
    contacts: [
      {
        email,
        first_name: firstName,
        unique_name: nameArr.join(' ')
      }
    ]
  };

  const api = 'Bearer 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';
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', api);

  const fetchData = { 
    method: 'PUT', 
    body: JSON.stringify(data),
    headers,
  };

  window.analytics.identify({
    name: nameArr.join(' '),
    firstName,
    email,
  });

  window.analytics.track('Career Application', {
    firstName,
    email,
  });

  fetch(url, fetchData)
  .then((res) => {
    if (res.statusText === 'Accepted') {
      emailError('Career Application', data);
    } else {
      emailError('Career Application Error', data);
    }
    history.push('/thankyou-career');
    return res.json();
  })
  .then(data => console.log(data))
  .catch(err => console.log('err', err));
};

export default handleCareerApplication;