import React from 'react';
import LoginForm from './LoginForm';
import './Login.css';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import PasswordResetForm from './PasswordResetForm';
import CreateAccountForm from './CreateAccountForm';
import MoreInfoForm from './MoreInfoForm';

const Login = ({title, description, signup, login, password, moreInfo}) => {
  return (
    <div className='page-component' id='login-page'>
      <ScrollToTop />
      <div id='login'>
        <h1>{title}</h1>
        <p>{description}</p>
        {login && <LoginForm />}
        {password && <PasswordResetForm />}
        {signup && <CreateAccountForm />}
        {moreInfo && <MoreInfoForm />}
      </div>
    </div>
  )
}

export default Login;