import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Form } from 'semantic-ui-react';
import handleCareerApplication from '../../util/handleCareerApplication';
import { Button } from '@material-ui/core';

const CareerForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const history = useHistory();

  const handleSubmit = (e) => {
    handleCareerApplication(e, email, name, history);
  };

  return (
    <Form onSubmit={handleSubmit} style={{maxWidth: '450px'}}>
      <Form.Input 
        type='name'
        fluid
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder='Your name here'
      />
      <Form.Input
        type='email' 
        fluid 
        required 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Your email address here' 
      />
      <Button variant="contained" color="secondary" type='submit'>Apply Now!</Button>
    </Form>
  )
};

export default CareerForm;
