import React from 'react';

const TripleBasicCard = ({ data, handleSelect }) => {
  const { title, value, description } = data;

  return ( 
    <div onClick={() => handleSelect(value)} className='triple-image-card'>
      <h5>{title}</h5>
      {description && <p>{description}</p>}
    </div>
  );
}


export default TripleBasicCard;