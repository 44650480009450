import React from 'react';

const Quote = ({ main, secondary, sub }) => (
  <div className={secondary ? 'landing-component quote secondary' : 'landing-component quote'}>
    <h4>
      {main.map((segment, idx) => {
        if (typeof (segment) === 'string') {
          return <span key={idx}>{segment}</span>
        } else {
          if (segment.type === 'br') {
            return <br />
          }
          return <span key={idx} className={segment.type}>{segment.content}</span>
        }
      })}
    </h4>
    <h3>{sub}</h3>
  </div>
);

export default Quote;
