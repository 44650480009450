import React, { useContext } from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { Redirect } from "react-router-dom";
import { AuthUserContext } from '../../context';
import selfcareData from './selfcareData';
import Main from './Main';
import CourseSeries from './CourseSeries';

const Course = ({ match }) => {
  const Auth = useContext(AuthUserContext);
  let vidTitle = match.params.title;
  const video = selfcareData.filter(video => video.title.split(' ').join('-') === vidTitle)[0];

  if (!Auth.user) {
    return <Redirect to='/login' />
  } else {
    if (vidTitle === 'Baby-Massage-101-Series') {
      return <CourseSeries />
    } else {
      return (
        <div>
          <ScrollToTop />
          <div className='learning-nav'>
            <h1>{video && video.title}</h1>
          </div>
          <Main video={video} course />
        </div>
      );
    }
  }

}

export default Course;