import React from 'react';

const CourseDetails = ({ title, description }) => {
  return (
    <div className='learning-section'>
      <h3>{title}</h3>
      {description.map((item, idx) => (
        <p key={idx}>
          {item}
        </p>
      ))}
  </div>
  )
};

export default CourseDetails; 