import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useMutation } from '@apollo/react-hooks';
import ADD_WORKSHOP_USER from '../../graphql/mutation/addWorkshopUser';
import { useHistory } from 'react-router-dom';


const WorkshopForm = ({ workshop }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [city, setCity] = useState('');
  const [addWorkshopUser, { data }] = useMutation(ADD_WORKSHOP_USER);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addWorkshopUser({
      variables: {
        firstName,
        lastName,
        email,
        tel,
        city,
        workshopId: workshop.id,
      }
    });

    setLoading(false);
    history.push(`/thankyou-upcoming-workshops/${workshop.id}`)
  }

  return (
    <div className='workshop-form'>
      <h5>Register For The Workshop:</h5>
      <form>
        <label for='fname'>First Name*</label>
        <input name='fname' type='text' required value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        <label for='lname'>Last Name*</label>
        <input name='lname' type='text' required value={lastName} onChange={(e) => setLastName(e.target.value)} />
        <label for='email'>Email*</label>
        <input name='email' type='email' required value={email} onChange={(e) => setEmail(e.target.value)} />
        <label for='tel'>Phone Number*</label>
        <input name='tel' type='tel' required value={tel} onChange={(e) => setTel(e.target.value)} />
        <label for='city'>City*</label>
        <input name='city' type='text' required value={city} onChange={(e) => setCity(e.target.value)} />
        <Button disabled={loading} onClick={handleSubmit} variant="contained" color="secondary" type='submit'>Sign me up</Button>
      </form>
    </div>
  )
};

export default WorkshopForm;