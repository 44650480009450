import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AuthUserContext } from '../../context';

const activeStyle = {
  backgroundImage: 'linear-gradient(180deg,var(--color-secondary-background),var(--color-secondary-background))',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '4px bottom',
  backgroundSize: '100% 10px',
}

const Nav = ({ user }) => {
  const Auth = useContext(AuthUserContext);

  return (
    <div id='nav'>
      {/* <NavLink className='underline' activeStyle={activeStyle} to='/upcoming-workshops'>Workshops</NavLink> */}
      <a href='https://www.alli.io/?utm_source=mamamobile&utm_medium=website&utm_campaign=navbar' target='__blank'>Therapy</a>
      <NavLink className='underline' activeStyle={activeStyle} to='/baby-massage-course'>Baby Massage</NavLink>
      {/* <NavLink className='underline' activeStyle={activeStyle} to='/gift-card'>Gift Card</NavLink> */}
      <NavLink className='underline' activeStyle={activeStyle} to='/blog'>Blog</NavLink>
      <NavLink className='underline' activeStyle={activeStyle} to='/learning'>Learning</NavLink>
      {!user && <NavLink className='underline' activeStyle={activeStyle} to='/login'>Login</NavLink>}
      <a href={`https://www.booking.mamamobile.com/select-service?stage=${Auth.stage}`}>
        <Button variant="contained" color="primary">book now</Button>
      </a>
    </div>
  )
};

export default Nav;