import app from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

import createStripeCustomer from './util/createStripeCustomer';
import emailError from "./util/emailError";

const config = {
  apiKey: "AIzaSyD2JuSLe394raTlmslZj-rpE8P2v1YWCvo",
  authDomain: "mama-mobile-massage.firebaseapp.com",
  databaseURL: "https://mama-mobile-massage.firebaseio.com",
  projectId: "mama-mobile-massage",
  storageBucket: "mama-mobile-massage.appspot.com",
  messagingSenderId: "259524002695",
  appId: "1:259524002695:web:c7ae171479023fe786c922",
  measurementId: "G-VX09HXH63V"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  };

  downloadEbook = () =>
    this.storage.ref().child('baby_massage.pdf').getDownloadURL();

  // *** Auth API ***
  createUserWithEmailAndPassword = (email, password) => 
    this.auth.createUserWithEmailAndPassword(email, password);

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => this.auth.signOut();

  passwordReset = email => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
  
  // firestore 

  createDoc = (collection, data) => {
    const ref = this.db.collection(collection);
    return ref.add(data);
  }

  updateDoc = (collection, id, data) => {
    const ref = this.db.collection(collection).doc(id);
    return ref.update(data);
  }

  findUser = (collection, id) => {
    const ref = this.db.collection(collection).doc(id);
    return ref.get();
  };

  updateUser = (id, key, value) => {
    const ref = this.db.collection('users').doc(id);
    return ref.update({[key]: value});
  }

  findOrCreateUser = async (collection, id, user) => {
    try {
      const ref = this.db.collection(collection).doc(id);
      let doc = await ref.get();
      if (doc.exists) {
        return doc.data();
      } else {
        const stripe = await createStripeCustomer(user);
        user.stripe = stripe.id;
        await ref.set(user);
        return user;
      }
    } catch (err) {
      emailError(err.message, user);
      return err;
    }
  };
}

export default Firebase;
