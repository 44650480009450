import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Form } from 'semantic-ui-react';
import { FirebaseContext, AuthUserContext } from '../../context';
import Error from '../../components/Error/Error';
import Loading from '../../components/Lottie/Loading';
import { Button } from '@material-ui/core';


const SignUpForm = ({ email }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const Firebase = useContext(FirebaseContext);
  const Auth = useContext(AuthUserContext);
  const history = useHistory();
  
  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError(false);
      const loginRes = await Firebase.createUserWithEmailAndPassword(email, password);
      const userRes = await Firebase.findUser('users', loginRes.user.email);
  
      if (userRes.exists) {
        const user = userRes.data();
        localStorage.setItem('user', user.email);
        localStorage.setItem('name', user.firstName);
        Auth.setState('user', user.email);
        Auth.setState('name', user.firstName);
        setLoading(false);
        history.push('/more-info');
      }
    } catch (err) {
      setLoading(false);
      setError(err.message);
    }
  }

  return (
    <Form onSubmit={handleSubmit} style={{maxWidth: '350px', width: '100%', margin: '0 auto'}}>
      {loading && <Loading />}
      <Form.Input
        type='password' 
        fluid 
        required 
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Password' 
      />
      <Button type='submit' variant="contained" color="secondary">Create Password</Button>
      {error && <Error errMsg={error} />}
    </Form>
  )
}

export default SignUpForm;
