import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import PromoCode from './PromoCode';

const OrderSummary = ({ total, setTotal, promo, setPromo }) => {
  return (
    <div id='order-summary'>
      <h3>Order Summary</h3>
      <Paper elevation={2}>
        <div className='item'>
          <img className='item-img' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1587508420/sell_sheet.png' />
          <p className='item-title'>Infant Massage Course - Video Series + Ebook</p>
          <p className='item-price'>$68</p>
        </div>
        <div className='divider'/>
        {promo === 12 && 
          <div className='item'>
            <p className='item-title'>zoomsesh Promo ($12 off)</p>
            <p className='item-price'>- $12</p>
          </div>
        }
        {promo === 68 && 
          <div className='item'>
            <p className='item-title'>Promo (100% off)</p>
            <p className='item-price'>- $68</p>
          </div>
        }
        <div className='item'>
          <p><b>Total</b></p>
          <p><b>{total - promo}</b></p>
        </div>
        <PromoCode setPromo={setPromo} />
      </Paper>
    </div>
  )
}

export default OrderSummary; 