import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';
import emailError from '../../util/emailError';
import Error from '../Error/Error';
import Loading from '../Lottie/Loading';
import { Button } from '@material-ui/core';

const BabyQuestionForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e) => {
    setLoading(true);
    setError(false);
    const url = `https://us-central1-mama-mobile-238622.cloudfunctions.net/api/email/babyMassageQuestion`;
    const data = {
      name,
      email,
      question,
    };

    const fetchData = { 
      method: 'POST', 
      body: JSON.stringify(data),
    };
  
    fetch(url, fetchData)
    .then(res => {
      setLoading(false);
      alert('Your question has been sent to Sarah!');
      setName('');
      setEmail('');
      setQuestion('');
    })
    .catch(err => {
      emailError(err.message, data);
      setLoading(false);
      setError('There seems to be a problem on our end, but we are trying to send your question to Sarah! You can alway email her at sarah@mamamobile.com');
    })
  };

  return (
    <Form onSubmit={handleSubmit}>
      {loading && <Loading />}
      <Form.Input 
        type='name'
        fluid
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder='Name'
      />
      <Form.Input
        type='email' 
        fluid 
        required 
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email' 
      />
      <Form.TextArea
        required 
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
        placeholder='Your question here...' 
      />
      <Button type='submit' variant="contained" color="secondary">Submit My Question</Button>
      {error && <Error errMsg={error} />}
    </Form>
  )
};

export default BabyQuestionForm;
