import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Quote from '../../components/Landing/Quote';
import Reviews from '../../components/Landing/Reviews';
import Blog from '../../components/Landing/Blog';
import HowItWorks from '../../components/Landing/HowItWorks';
import MassagePricing from '../../components/Landing/MassagePricing';
import Benefits from '../../components/Landing/Benefits';
import WhyUs from '../../components/Landing/WhyUs';
import FAQs from '../../components/Landing/FAQs';
import HeroPolaroid from '../../components/Hero/HeroPolaroid';
import SEO from '../../components/SEO/SEO';

const data = {
  prenatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      title: 'In-home prenatal massage is a must for expecting mamas. Relax and feel safe in the hands of our expert prenatal massage therapists.',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `“Five stars! I’m 37 weeks pregnant and have been dealing with serious chronic pain. It’s so amazing to have a massage in my own home from someone who has specialty training in prenatal massage.”`,
      ],
      sub: `- Kaitlin`
    },
    pricing: {
      firstDescription: `Our first visit includes a 10-minute detailed assessment to create your prenatal treatment plan personalized to your pregnancy. This includes curated stretches and self-care to reap the full benefits of your massage.`,
      subsequentDescription: `Your massage treatment plan will include a mix of techniques safely adapted for your pregnancy. This may include Swedish massage, lymphatic drainage and deep tissue massage. Your prenatal RMT will discuss the best approach for you and your bun!`,
    },
    benefits: {
      title: 'Prenatal Massage Benefits',
      cards: [
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Labour Prep',
          description: 'Our prenatal massage services are often recommended by doctors and midwives to prepare moms for labour, even with high-risk pregnancies and at the hospital.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Swelling',
          description: 'Your massage specialist will apply lymphatic drainage techniques to reduce swelling.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Aches and Pains',
          description: 'Prenatal massage can relieve immediate aching muscles and reduce pregnancy related injuries like sciatica and carpal tunnel syndrome.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Joint Support',
          description: 'Specialized massage techniques will help support the extra joint stress often experienced during pregnancy.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Hormones',
          description: 'Prenatal massage helps stabilize hormones and has been shown to increase endorphins (aka feel-good hormones!).'
        },
      ]
    },
    whyUs: [
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'Prenatal massage experts',
        description: ['Our Registered Massage Therapists (RMT) are all certified through a 2-year+ massage therapy program, and have completed additional training to support prenatal mamas.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'We save you time and hassle',
        description: ['Save hours of time commuting to the massage and back. We’ll come to you at the comfort of your home! No traffic jams, no parking pains. We’re kinda obsessed with making your life easier.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        title: 'Insurance covered',
        line: true,
        description: ['All Mama Mobile massage therapists are Registered in Ontario and Halifax. Our services are covered under extended health insurance and you will receive a receipt after your massage.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        title: 'Safety is serious business',
        description: ['Your safety and that little bun in the oven are our top priorities! Every Mama Mobile Massage Therapist passes a background check, has extensive training, and our services are insured.'],
      },
    ],
    reviews: [
      {
        review: 'If Only I can give 10 Stars! I am in my 3rd trimester and started to experience lower back and some other muscle pains so having a Prenatal Massage ... plus at your own place & comfort is just amazing. I am beyond glad to have known Mama Mobile Massage from the Baby Show.',
        name: 'Charmae Mancego',
        source: 'Google Review',
        link: 'https://goo.gl/maps/yxmdr4xcXtT1ApDz9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597173086/Untitled_design_4_4_l1k5wc.png',
        alt: 'Ottawa in-home prenatal massage',
        type: 'image'
      },
      {
        review: `We’ve had Mama Mobile come to our home a few times already, during pregnancy and post-partum. They came with all the equipment & special pillows for my belly (during pregnancy) and to accommodate my breasts (from nursing). It’s so nice to stay in the comfort of my own home in such a vulnerable time to have a massage.`,
        name: 'Josianne Krucker',
        source: 'Google Review',
        link: 'https://goo.gl/maps/7XZEd4qhfYLycCUv6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Ontario in-home prenatal massage',
        type: 'image'
      },
      {
        review: `Five stars! I’m 37 weeks pregnant and have been dealing with serious chronic pain. It’s so amazing to have a massage in my own home from someone who has speciality training in prenatal massage. Sarah is so lovely and friendly, asked lots of thoughtful questions and made sure I was comfortable and really helped to alleviate pain and discomfort. Highly recommended!!`,
        name: 'Kaitlin Mackenzie',
        source: 'Google Review',
        link: 'https://goo.gl/maps/eG61oycAZ3q8nihz9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_29_v4iopz.png',
        alt: 'mama mobile in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I’ve been feeling a lot of pain and uncomfortable sensations throughout this pregnancy. I feel more relaxed and plan on getting a few more prenatal massages before I moved to postnatal massages.  It's so convenient to have them come to your home especially the fact that I'm still working. It felt as if I were at a spa.`,
        name: 'Krystelle Patag',
        source: 'Google Review',
        link: 'https://goo.gl/maps/xvuc92tuLQvCgGj59',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_25_iafjc9.png',
        alt: 'Toronto in-home prenatal massage',
        type: 'image'
      },
      {
        review: `Amazing if you're pregnant or have a little one.`,
        name: 'Kiruthiga Sinnarajah',
        source: 'Google Review',
        link: 'https://goo.gl/maps/dAtCViE2bqmZybAB7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_20_pvosyc.png',
        alt: 'Brampton in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I had Samantha yesterday evening for my prenatal massage and it was absolute perfection.  She was on time, explained everything and I had such a great feeling afterward. She even took some time at the end for self-care. Loved her attitude and personality! Would definitely recommend Mama Mobile Massage to anyone else.`,
        name: 'Givy Pavananthan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GXaU5MDtbq3TcQQB9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_24_plc1fn.png',
        alt: 'Mississauga in-home prenatal massage',
        type: 'image'
      },
      {
        review: `I was delighted to hear that there was a service which comes to your home to provide a massage for pregnant woman. I have two dogs and in the middle of moving and she worked around the mess and made me feel very comfortable. I recommend this service to anyone especially expecting moms.`,
        name: 'Monika P',
        source: 'Google Review',
        link: 'https://goo.gl/maps/EqPNmD23EiFnrjgN7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_15_nx4mtx.png',
        alt: 'Ottawa prenatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `At 32 weeks, it takes a lot to feel truly relaxed and this did just the trick :o)`,
        name: 'Colleen McGurran',
        source: 'Google Review',
        link: 'https://goo.gl/maps/rA8jYfk91gwwTPDE6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Toronto prenatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `This service is amazing. Not only is it SO convenient - they come right to you - the RMTs are so professional, skilled, patient, kind and overall great at what they do! I truly look forward to this massage every few weeks. It was also very important to me to get a massage from an RMT with experience in prenatal and MMM definitely met my expectations in that regard. I feel lucky to have found them and would highly recommend!`,
        name: 'Miriam Anbar',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GsM2ETN7sc7VdTSKA',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_10_qulce0.png',
        alt: 'Ontario prenatal pelvic floor physiotherapy',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What To Expect During Your First Prenatal Massage',
        type: 'prenatal massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587009574/mama_experience.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
    faqs: [
      {
        question: 'Can I get a deep tissue massage during pregnancy?',
        answer: 'During pregnancy your body produces relaxin, making your joints lax. Your prenatal massage specialist knows how to navigate this. Deep tissue massage techniques will only be able to be applied in certain areas. No need to worry, your massage specialist will guide you through the experience.',
      },
      {
        question: 'How does prenatal massage help with swelling?',
        answer: 'It is common during pregnancy to experience swelling in the wrists, legs, and feet! Your prenatal massage therapist has special training in lymphatic drainage to reduce swelling. Lymphatic drainage techniques will help improve sleep, boost circulation, and reduce overall discomfort.',
      },
      {
        question: 'I’m pregnant and can’t lay on my stomach… How does this work?',
        answer: 'Our Registered Massage Therapists are prenatal massage experts! You will be positioned comfortably on your side with lots of pillows to put you in the safest position possible.',
      },
      {
        question: 'Do you massage papas too?',
        answer: 'Although we focus on mamas we do in fact massage papas too! We actually created a special option so that we can let them experience some TLC too. On our schedule this option is called “mama/papa” We do not offer couple massages simultaneously. Like everything we do, our mama/papa massages are a little bit unconventional. We provide each of you with either a 60 or 90 minute individual massage back-to-back. The idea behind this is that you will be able to tag team the kids to bed!',
      },
    ],
  },
  postnatal: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      title: 'In-home postnatal massage will support your recovery from labour and ease the aches and pains that come along with being a new mama.',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `"The thought of getting a babysitter, organizing milk, getting dressed, driving to get a massage made it so unappetizing to me. Until I had a pretty bad injury that I needed fixed. Then I discovered mama mobile.”`,
      ],
      sub: `- Akira`
    },
    pricing: {
      firstDescription: `Your first visit includes a 10-minute detailed assessment to create your postnatal  treatment plan personalized to you. This includes curated stretches and self-care to reap the full benefits of your massage.`,
      subsequentDescription: `Your massage treatment plan will include a mix of techniques curated to ease your recovery and the physical demands of being a new mama. This may include Swedish massage, myofascial release, lymphatic drainage and deep tissue massage. Your postnatal RMT will discuss the best approach for you!`,
    },
    benefits: {
      title: 'Postnatal Massage Benefits',
      cards: [
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Breast Pain',
          description: 'Many moms experience things like blocked milk ducts, hormonal changes etc. that lead to pain- massage can help!'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'C-section Recovery',
          description: 'Massaging around your scar is important as it will help improve the appearance and effects of the scar on your body!'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Improved Feedings',
          description: 'Postpartum breast massage will not only help increase milk flow but it will also boost milk production.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Aches and Pains',
          description: 'Feeling sore...everywhere?! Help your tight muscles relax and recover from any aches from hunching over feeding, sleepless nights, and the hectic days of being a new mama.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Mental Health',
          description: 'Massage helps increase endorphins which can lead to increased happiness.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Improved Sleep',
          description: 'Hormones are released during massage that lead to a deeper more restful sleep.'
        },
      ]
    },
    whyUs: [
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'Postnatal massage experts',
        description: ['Our Registered Massage Therapists (RMT) are all certified through a 2-year+ massage therapy program and have completed our extensive training specific for postnatal massages.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'We get you',
        description: ['Let’s normalize mamahood! Leaky boobs, new scars, feeding breaks...etc. We’ll roll with it and adjust as we go so that you can relax!'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'Here when you need us',
        description: ['We understand how busy you are. Book your massage at a time that actually works for you. From pre-bedtime bookings to during your baby’s nap-time, we’re obsessed with making your life easier.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        title: 'Insurance covered',
        description: ['All Mama Mobile massage therapists are Registered in Ontario and Halifax. Our services are covered under extended health insurance and you will receive a receipt after your massage.'],
      },
    ],
    reviews: [
      {
        review: 'The thought of getting a babysitter, organizing milk, getting dressed, driving to get a massage made it so unappetizing to me. Until I had a pretty bad injury that I needed fixed. Then I discovered mama mobile. Not only did I appreciate the convenience of not leaving my home, I got an amazing relaxing massage and MOST importantly an extremely competent RMT that resolved my ongoing injury! I am a fan for LIFE! oh and the HUGE win was they finished before the kids finished their nap!!',
        name: 'Akira Seuradge',
        source: 'Google Review',
        link: 'https://goo.gl/maps/hwSneTaofJwf66Xj9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380238/reviews/review_images_1_8_putlvc.png',
        alt: 'Toronto in-home postnatal massage',
        type: 'image'
      },
      {
        review: `She focused on all of the right areas, and payed special attention to those problem some areas that come from holding and nursing a baby all day. I have never felt more relaxed during and after receiving a massage and then being able to melt right onto your bed after is the cherry on top! I am so grateful for Mama Mobile and am so impressed with everything they encompass as a company. Thank you for giving this Mama exactly what she needed!!!`,
        name: 'Mighty Mom Movement',
        source: 'Google Review',
        link: 'https://goo.gl/maps/APzcPZvMScgTuUXZ7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_5_gwbkvo.png',
        alt: 'Ottawa in-home postnatal massage',
        type: 'image'
      },
      {
        review: `My baby is a month old and without a set schedule yet for nap times or feedings, it’s so hard to predict when a good time to go out or book a specific time that I know will work yet. This service made it so easy to schedule a session as we were told that extra time is allotted for feedings or fussiness so I felt comfortable knowing there would be some flexibility there and we’re at home with all the conveniences baby may need and where we’re all most comfortable.`,
        name: 'ModellaMedia',
        source: 'Google Review',
        link: 'https://goo.gl/maps/jbvkbxPnP86bx5X16',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/google_review_1_iloco9.png',
        alt: 'Mississauga in-home postnatal massage',
        type: 'image'
      },
      {
        review: `I’ve been feeling a lot of pain and uncomfortable sensations throughout this pregnancy. I feel more relaxed and plan on getting a few more prenatal massages before I moved to postnatal massages.  It's so convenient to have them come to your home especially the fact that I'm still working. It felt as if I were at a spa.`,
        name: 'Krystelle Patag',
        source: 'Google Review',
        link: 'https://goo.gl/maps/xvuc92tuLQvCgGj59',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1597173086/Untitled_design_4_4_l1k5wc.png',
        alt: 'Ontario in-home postnatal massage',
        type: 'image'
      },
      {
        review: `One word: LIFESAVER! With an old/recurring back injury, a busy lifestyle with a rambunctious kid and a new baby, I was in a lot of pain and yet couldn’t find the time to get it treated.  That was, until I stumbled across mama mobile massage!  The fact that an rmt will come to your house, at a time convenient to you, meaning you don’t have to drive somewhere, figure out what to do with your kids, only operate during business hours... game-changer for moms, seriously!`,
        name: 'Andria Hinton',
        source: 'Google Review',
        link: 'https://goo.gl/maps/Dbfxwwte4FWo9C427',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Ottawa postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I had Samantha yesterday evening for my prenatal massage and it was absolute perfection.  She was on time, explained everything and I had such a great feeling afterward. She even took some time at the end for self-care. Loved her attitude and personality! Would definitely recommend Mama Mobile Massage to anyone else.`,
        name: 'Givy Pavananthan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/GXaU5MDtbq3TcQQB9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_21_u1hejr.png',
        alt: 'Toronto postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I’ve used mama mobile services a few times and am continuously blown away by the company. The therapists are professional and friendly and give great massages! I’m home with an infant and this is such a great way to get some self-care. I highly recommend mama mobile massage.`,
        name: 'Rachel',
        source: 'Google Review',
        link: 'https://goo.gl/maps/C6o6zKKALmd1Gg7r6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_24_plc1fn.png',
        alt: 'Mississauga postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `This company is so amazing that they support mamas who might not be able to make an appointment for a massage because we are at home with our little ones. Katelyn was friendly, checked in with me, and so respectful of me needing to tend to my baby during the massage at any time. I’ve recommended this to all my mama friends and will be continuing this as part of my self-care plan.`,
        name: 'Nicole W',
        source: 'Google Review',
        link: 'https://goo.gl/maps/ibDW4krZ92yQfKUH7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Ontario postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `My husband and I are adjusting to our 10 day old son, and after the thralls this new little man including the labour, nursing, and diaper changes we were both feeling the need for knead. We got to tag team the baby while the other got relief and relaxation.`,
        name: 'Kathryn Douthart',
        source: 'Google Review',
        link: 'https://goo.gl/maps/V5V8EKvf4hzwV3xb8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_27_gliroz.png',
        alt: 'Brampton postnatal pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `this service is amazing! super convenient, easy booking, and helpful staff that make it a great experience. their RMTs are professional and excellent at what they do. both my husband and i had massages a few months into being postpartum and we were impressed. highly recommend and can't wait for our next massage!`,
        name: 'Michelle Halligan',
        source: 'Google Review',
        link: 'https://goo.gl/maps/1QoweMoKiZFLkqn48',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_14_ojvv1o.png',
        alt: 'Toronto in-home postnatal massage',
        type: 'image'
      },
      {
        review: `Great for moms who have a hard time getting out of the house. I had a great massage with Jessi who showed up on time and gave me a great massage after my baby had gone to bed for the night. What a dream! 100% would recommend.`,
        name: 'Marina Kamalova',
        source: 'Google Review',
        link: 'https://goo.gl/maps/uEyTxRNV6QdFFqzZ8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_13_gxclsl.png',
        alt: 'Ottawa in-home postnatal massage',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What To Expect During Your First Postpartum Massage',
        type: 'Postnatal Massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183693/postnatal_massage.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
    faqs: [
      {
        question: 'My boobs are leaky',
        answer: 'Mama, believe us when we say this is totally normal! Don’t worry about leaking. We will always be sure to provide a position that supports tender breasts for your comfort. We also bring along all supplies to clean up our tables post massage so don’t worry about any leaks, just relax!',
      },
      {
        question: 'What do I do with baby during my massage?',
        answer: 'Many of our mamas try to schedule baby’s nap time, in the evening or weekend! All of our RMTs “speak baby” and are happy to offer suggestions on what to do if it’s an especially fussy day!! We have had many mamas nurse during the massage, place baby in her carrier within close view- you would be surprised at how creative, accommodating and worry free the whole experience really is! There is a reason they call our RMTs the mama support squad.',
      },
      {
        question: 'Do you massage papas too?',
        answer: 'Although we focus on mamas we do in fact massage papas too! We actually created a special option so that we can let them experience some TLC too. On our schedule this option is called “mama/papa” We do not offer couple massages simultaneously. Like everything we do, our mama/papa massages are a little bit unconventional. We provide each of you with either a 60 or 90 minute individual massage back-to-back. The idea behind this is that you will be able to tag team the kids to bed!',
      },
    ],
  },
  busymama: {
    hero: {
      background: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598393375/_K3A9584_1_upupel.jpg',
      title: 'In-home Massage to help manage the aches and pains of being the busy mama that you are!',
      buttonText: 'Book Now'
    },
    quote: {
      main: [
        `"One word: LIFESAVER! With an old/recurring back injury, a busy lifestyle with a rambunctious kid and a new baby, I was in a lot of pain and yet couldn’t find the time to get it treated. That was, until I stumbled across mama mobile!”`,
      ],
      sub: `- Andria`
    },
    pricing: {
      firstDescription: `Your first visit includes a 10-minute detailed assessment to create your busy mama  treatment plan personalized to you. This includes curated stretches and self-care to reap the full benefits of your massage.`,
      subsequentDescription: `Your massage treatment plan will include a mix of techniques curated to the physical demands of your busy life. This may include sport massage, Swedish massage, myofascial release, lymphatic drainage and deep tissue massage. Your RMT will discuss the best approach for you!`,
    },
    benefits: {
      title: 'Busy Mama Massage Benefits',
      cards: [
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Improved Posture',
          description: 'Lengthen muscles that are often overused as a mama, and receive personalized stretches that work with your busy lifestyle.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Active Lifestyle Recovery',
          description: 'Decrease post-workout muscle soreness, improve flexibility, and increase range-of-motion.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Aches and Pains',
          description: 'Massage can help reduce pain and finally address THAT nagging spot.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Injury Prevention',
          description: 'Prevent injuries by relaxing tight muscles that could lead to repetitive strain injuries.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Improved Sleep',
          description: 'Hormones are released during massage that lead to a deeper more restful sleep.'
        },
        {
          image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
          title: 'Mental Health',
          description: 'Massage helps increase endorphins aka happy hormones!'
        },
      ]
    },
    whyUs: [
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'Massage experts',
        description: ['Our Registered Massage Therapists (RMT) are all certified through a 2-year+ massage therapy program and have experience in with deep tissue, swedish and various other massage techniques.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        line: true,
        title: 'We save you time and hassle',
        description: ['We understand how busy you are. Save hours of time commuting to the massage and back. We’ll come to you at the comfort of your home! No traffic jams, no parking pains. We’re kinda obsessed with making your life easier.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        title: 'Insurance covered',
        line: true,
        description: ['All Mama Mobile massage therapists are Registered in Ontario and Halifax. Our services are covered under extended health insurance and you will receive a receipt after your massage.'],
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1596925674/how_it_works.png',
        title: 'Safety is serious business',
        description: [`You and your family's safety is our top priorities! Every Mama Mobile massage therapist passes a background check, has extensive training, and our services are insured.`],
      },
    ],
    reviews: [
      {
        review: `I'm so happy I found mama mobile. This is an amazing service for any parent who feels that they cannot take time out of their busy schedules to take care of themselves, and who would appreciate maintaining their health from within the comfort of their home.`,
        name: 'Mélanie Pronovost',
        source: 'Google Review',
        link: 'https://goo.gl/maps/6YZ73K7gCy971RV57',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380240/reviews/review_images_1_28_r0mlsq.png',
        alt: 'Toronto in-home massage for moms',
        type: 'image'
      },
      {
        review: `I enjoyed being in the comfort of my own home with my newborn. Anjulee set up her work station quickly and made it super comfortable with soft blankets and calming aromas. She was very personable and interacted well with myself and adolescent children. I will definitely be doing this again.`,
        name: 'Kelly Koenig',
        source: 'Google Review',
        link: 'https://goo.gl/maps/9C2UgxpKFf515KGM9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Ottawa in-home massage for moms',
        type: 'image'
      },
      {
        review: `Angela was on time and came prepared with everything to make the massage perfect. She had read my intake form and asked intelligent questions and my issues and most importantly gave me a fantastic massage! Love the convenience of not having to find a babysitter to get an amazing massage!`,
        name: 'Melanie Greenspoon',
        source: 'Google Review',
        link: 'https://goo.gl/maps/42HeYd2JzytsUJKp6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_3_ggoo1x.png',
        alt: 'Mississauga in-home massage for moms',
        type: 'image'
      },
      {
        review: `The massage itself was as good as any spa massage. But the really amazing part was to have it in my home. Kids were in bed, I didn't have to leave the comfort of my robe and a glass of wine and hot shower were waiting for me as soon as I was done. Will definitely book again!`,
        name: 'Lauren Binks',
        source: 'Google Review',
        link: 'https://goo.gl/maps/kmcKqTJovvodNUTk7',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380237/reviews/review_images_1_6_ehxy5t.png',
        alt: 'Ontario in-home massage for moms',
        type: 'image'
      },
      {
        review: `I love Mama Mobile. I have had consistently stellar service. Every therapist they have sent has been awesome. Great technical skills, friendly and punctual. Really enjoy their massages!`,
        name: 'Dr. Dina Kulik',
        source: 'Google Review',
        link: 'https://goo.gl/maps/YUmWVMC8c5LRGWWn6',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_19_rsdjd8.png',
        alt: 'Ottawa pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `Love this company! All of the RMTs are wonderful and really take great care in listening to my problem areas and give me great after-care advice. As a busy mom, having them come to me is so convenient. Booking appointments online is so simple, and they have excellent customer service. I’ve recommended them to many family and friends.`,
        name: 'Jennifer Mach',
        source: 'Google Review',
        link: 'https://goo.gl/maps/CmXiBq4Mq3ZTmuGG9',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_18_gpodbq.png',
        alt: 'Toronto pelvic floor physiotherapy',
        type: 'image'
      },
      {
        review: `I am always ranting and raving to friends and family how genius this service is! Getting a massage in the comfort of your own home, even at late hours like 8 or 9pm. The RMTs are very professional and friendly. The entire experience is always great. I plan to continue to use them for all future massages.`,
        name: 'Simply Stunning Decor and Events',
        source: 'Google Review',
        link: 'https://goo.gl/maps/8usE7K77VU9rtgkU8',
        type: 'quote'
      },
      {
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1598380239/reviews/review_images_1_16_owyzzm.png',
        alt: 'Mississauga pelvic floor physiotherapy',
        type: 'image'
      },
    ],
    blogs: [
      {
        title: 'What to expect during your first in-home massage experience: For busy mamas and partners',
        type: 'Busy Mama Massage',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183693/postnatal_massage.jpg',
      },
      {
        title: 'Your Health Is Our Top Priority',
        type: 'Covid Protocol',
        image: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg',
      },
    ],
    faqs: [
      {
        question: 'Will I receive an insurance receipt?',
        answer: 'Heck yes mama! Within 24 hours following your massage you will be emailed an insurance receipt. Please note that your file is updated with the correct name that you wish to appear on it.',
      },
      {
        question: 'Do you offer group massages for mama friends?',
        answer: 'We do! We have worked with mama’s who meet up on a weekly basis. We typically provide 30 minute mini massages with one mama at a time while other mamas visit and have a lil’ playdate with the babes. Please email us for more info and pricing hello@mamamobile.com. If you have over 4 mamas we will send multiple RMT’s.',
      },
      {
        question: 'Do you massage papas too?',
        answer: 'Although we focus on mamas we do in fact massage papas too! We actually created a special option so that we can let them experience some TLC too. On our schedule this option is called “mama/papa” We do not offer couple massages simultaneously. Like everything we do, our mama/papa massages are a little bit unconventional. We provide each of you with either a 60 or 90 minute individual massage back-to-back. The idea behind this is that you will be able to tag team the kids to bed!',
      },
    ],
  },
}

const seo = {
  postnatal: {
    title: 'Postnatal',
    description: 'In-home postnatal massage will support your recovery from labour and ease the aches and pains that come along with being a new mama.',
  },
  prenatal: {
    title: 'Pregnant',
    description: 'In-home prenatal massage is a must for pregnant moms. Relax and feel safe in the hands of our expert prenatal massage therapists.',
  },
  busymama: {
    title: 'Busy',
    description: 'You are busy enough running the family - let us bring the massage to you. Our Registered Massage Therapists (RMTs) are in the GTA and Ottawa.',
  },
}

const MassageLanding = () => {
  const { stage } = useParams();

  return (
    <div>
      <SEO 
        title={`In-Home Massage for ${seo[stage].title} Moms in GTA and Ottawa | Mama Mobile`}
        description={seo[stage].description}
        url={`service/${stage}/massage`}
      />
      <ScrollToTop />
      <HeroPolaroid
        route={`https://www.booking.mamamobile.com/select-service?stage=${stage}`}
        image={data[stage].hero.background}
        title={data[stage].hero.title}
        buttonText={data[stage].hero.buttonText}
        quote='I’ve got 99 problems, massage will fix 86 of them.'
      />
      <Quote main={data[stage].quote.main} sub={data[stage].quote.sub} secondary />
      <HowItWorks />
      <MassagePricing pricing={data[stage].pricing} route={`https://www.booking.mamamobile.com/select-service?stage=${stage}`} secondary />
      <Benefits benefits={data[stage].benefits} />
      <WhyUs whyUs={data[stage].whyUs} title='Why Mama Mobile?' />
      <Reviews reviews={data[stage].reviews} />
      <FAQs faqs={data[stage].faqs} />
      <Blog blogs={data[stage].blogs} />
      {/* <Marquee /> */}
    </div>
  )
};


export default MassageLanding;