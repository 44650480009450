import React from 'react';

const Blob = ({ secondary, bottom }) => {
  return (
    <div className={bottom ? 'blob bottom' : 'blob'}>
      <div className='blob-wrapper'>
        <div className={secondary ? 'blob-inner secondary' : 'blob-inner'}>
        </div>
      </div>
    </div>
  )
}

export default Blob;