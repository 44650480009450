import React from 'react';
import UpcomingWorkshopCard from '../Card/UpcomingWorkshopCard';

const UpcomingWorkshops = ({ workshops }) => {
  return (
    <div className='landing-component upcoming-events'>
      <h4>Upcoming Free Online Workshops</h4>
      <div className='card-wrapper'>
        {Object.values(workshops).map((event, idx) => <UpcomingWorkshopCard key={idx} card={event}/>)}
      </div>
    </div>
  )
};

export default UpcomingWorkshops;