import blog15 from '../../assets/blog15.jpg';
import blog14 from '../../assets/blog14.jpg';
import blog13 from '../../assets/blog13.jpg';
import blog12 from '../../assets/blog12.jpg';
import blog11 from '../../assets/blog11.jpg';
import blog10 from '../../assets/blog10.jpg';
import blog9 from '../../assets/blog9.jpg';
import blog8 from '../../assets/blog8.jpg';
import blog7 from '../../assets/blog3.jpg';
import blog6 from '../../assets/blog6.jpg';
import blog5 from '../../assets/blog5.jpg';
import blog4 from '../../assets/blog4.jpg';
import blog3 from '../../assets/blog3.jpg';
import blog2 from '../../assets/blog2.png';
import blog1 from '../../assets/blog1.jpg';
import blog0 from '../../assets/blog0.png';
const blog17 = `https://res.cloudinary.com/dhze7gimq/image/upload/v1581485923/blog/cori_stern.png`;
const blog18 =`https://res.cloudinary.com/dhze7gimq/image/upload/c_fill,g_east,h_252,w_400,x_0/v1583355364/blog/tracy.jpg`;
const blog20 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580415670/welcome/IMG_2896_2_p3uegb.jpg';
const blog19 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1584684555/blog/blog_19.png';
const blog21 = 'https://res.cloudinary.com/dhze7gimq/image/upload/c_fill,h_252,w_400/v1584685254/blog/blog_21.jpg';
const blog22 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1585155482/blog/PostpartumBlog__Vanessa_lefesc.png';
const blog22H = 'https://res.cloudinary.com/dhze7gimq/image/upload/c_fill,h_252,w_400/v1585155482/blog/PostpartumBlog__Vanessa_lefesc.png';
const blog23 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183154/baby_massage_1.jpg';
const blog24 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587009574/mama_experience.jpg';
const blog25 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587183693/postnatal_massage.jpg';
const blog26 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1584381060/hero_corona.jpg'; 
const blog27 = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1595627520/jessi_setup.jpg';

const blogData = [
  {
    id: 27,
    date_created: new Date('July 24, 2020'),
    img: blog27,
    title: 'Your Health Is Our Top Priority',
    author: 'Sarah Rennick',
    type: 'Covid Protocol',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          `We have put measures in place in order to keep you and your family safe and healthy.`,
          `This page will continue to be updated as we adapt to the changing health climate and government guidance.`,
          `Last Updated: July 24, 2020`,
          `In accordance with Ontario’s Chief Medical Officer of Health regulated health professionals can, when all necessary precautions and protocols are in place to protect patients and themselves, begin providing wellness care. This includes both physiotherapy and massage therapy.`
        ]
      },
      {
        type: 'h3',
        content: `What our team is doing to keep you safe:`
      },
      {
        type: 'h5',
        content: `Top grade cleaning supplies and cleaning`,
      },
      {
        type: 'p',
        content: `Upon arrival and departure, your Massage Therapist will clean all supplies/surfaces with a top grade disinfectant.`,
      },
      {
        type: 'h5',
        content: `RMT PPE`,
      },
      {
        type: 'p',
        content: `Your Massage Therapist will wear a mask during your massage. (Note: Some of our Massage Therapists have chosen to wear gowns/gloves as well)`,
      },
      {
        type: 'h5',
        content: `RMT Screening`,
      },
      {
        type: 'p',
        content: `Your Massage Therapist will have a temperature check before starting each visit. Your Massage Therapist will also be required to complete the COVID-19 screening questions prior to each visit.`,
      },
      {
        type: 'h5',
        content: `Mama Screening`,
      },
      {
        type: 'p_arr',
        content: [
          `Upon booking your appointment you will be required to complete the COVID-19 screening questions.`,
          `You will be contacted again within 24 hours of your appointment to complete the COVID-19 screening questions a second time to ensure no changes have been made. Please ensure that you keep an eye out for this email as it will be a requirement. (Note: You will also be required to send us your temperature.)`,
        ]
      },
      {
        type: 'h5',
        content: `Changes to Your Massage`,
      },
      {
        type: 'p',
        content: `We will not be performing facial massage at this time and you will be required to wear a mask during your massage. You will be required to provide your own sheets for your massage.`,
      },
      {
        type: 'h5',
        content: `Contact us!`,
      },
      {
        type: 'p',
        content: `If you need to cancel an upcoming appointment or have questions don’t hesitate to get in touch with us! You can contact us at hello@mamamobile.com and we will get back to you within 12 hours.`,
      },
      {
        type: 'h5',
        content: `How to stay informed`,
      },
      {
        type: 'p_with_link',
        content: [
          `We are closely following the guidelines put out by the `,
          {
            content: 'CMTO',
            link: 'https://www.cmto.com/assets/Client-Resource-Massage-Therapy-and-COVID-19.pdf',
          },
          ` (College of Massage Therapists of Ontario) `,
          {
            content: 'Public Health Ontario',
            link: 'https://www.publichealthontario.ca/en/diseases-and-conditions/infectious-diseases/respiratory-diseases/novel-coronavirus',
          },
          ` and the `,
          {
            content: 'World Health Organization (WHO)',
            link: 'https://www.who.int/emergencies/diseases/novel-coronavirus-2019/advice-for-public',
          },
        ]
      },
      {
        type: 'p',
        content: `Please keep yourself informed by regularly following these sites.`,
      },
      {
        type: 'h5',
        content: `COVID-19 Self-Assessment Tool`,
      },
      {
        type: 'p_with_link',
        content: [
          `If you are worried you may have been exposed to COVID-19 fill out this assessment tool before calling your primary care provider or Telehealth Ontario at 1-866-797-0000. `,
          {
            content: 'https://covid-19.ontario.ca/self-assessment/',
            link: 'https://covid-19.ontario.ca/self-assessment/ ',
          },
        ]
      },
      {
        type: 'h3',
        content: `COVID-19 FAQ`,
      },
      {
        type: 'h5',
        content: `Can my massage happen outside? `,
      },
      {
        type: 'p',
        content: `Yes! Actually, we highly encourage it. You can leave instructions upon booking and your Massage Therapist will happily be in accordance.`,
      },
      {
        type: 'h5',
        content: `Why can’t I find any appointments available in my area? `,
      },
      {
        type: 'p_arr',
        content: [
          `This has been a difficult time for our team. Unfortunately, many of our Massage Therapists aren’t ready to return to work quite yet. We are currently operating at ¼ of our team capacity.`,
          `Please know, we are trying our best to fill as many requests as possible but in some areas, we are still waiting on our Massage Therapists to return to work.`
        ]
      },
      {
        type: 'h5',
        content: `Will there be a cancellation fee?`,
      },
      {
        type: 'p',
        content: `At this time we are waiving all cancellation fees in order to encourage transparency and to keep everyone safe.`,
      },
      {
        type: 'h5',
        content: `Do I have to wear a mask in my own home?`,
      },
      {
        type: 'p',
        content: `Yes. In compliance with Health Canada guidelines, we kindly ask that you follow the protocol of wearing a mask during your appointment.`,
      },
      {
        type: 'h5',
        content: `Why do I have to provide my own sheets?`,
      },
      {
        type: 'p',
        content: `This is a new procedure for us at Mama Mobile. We have decided to put this in place in order to keep you and your Massage Therapist safe by containing supplies as much as possible. Thank you for your understanding.`,
      },
      {
        type: 'h5',
        content: `Why can I book a gua sha or facial massage?`,
      },
      {
        type: 'p',
        content: `At this time we do not provide facial massages. We feel that prolonged exposure by both parties without masks working in close proximity is not safe at this time. We will continue to update you as things change.`,
      },
    ]
  },
  {
    id: 26,
    date_created: new Date('September 17, 2020'),
    img: blog26,
    title: 'What to expect during your first in-home massage experience: For busy mamas and partners',
    author: 'Sarah Rennick', 
    type: 'Busy Mama Massage',
    blogPost: [
      {
        type: 'p',
        content: `Getting a massage at home for the first time can feel a bit like going on a blind date. Whether you are a seasoned massage-getter or trying massage for the first time ever getting it at home will make your already hectic life just a little bit less overwhelming and a little more relaxing. Here are some things that you should know about your first experience.`,
      },
      {
        type: 'h5',
        content: 'Booking your first in-home massage'
      },
      {
        type: 'p_with_link',
        content: [
          `You can start relaxing from the moment you visit our website to `,
          {
            content: 'book your massage',
            link: 'https://booking.mamamobile.com/',
          },
          ` - we’ve worked our magic to make sure the booking experience is as stress-free as possible!`
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `You can book your massage by `,
          {
            content: 'clicking here',
            link: 'https://booking.mamamobile.com/',
          },
          `. You will be prompted to enter your address, create or sign in to your existing account. From there you will be met with a schedule of available dates and times in your area. We do have evening slots as late as 8 pm available for you but they do book quickly. (aim to pre-plan 2-3 weeks in advance for your ideal time!)`
        ]
      },
      {
        type: 'h5',
        content: 'What happens immediately after I book my first in-home massage?'
      },
      {
        type: 'p',
        content: `Right after you book your massage you will be emailed a confirmation. You will also be emailed detailed instructions on what to expect and how to prepare for your first in-home massage.`,
      },
      {
        type: 'h5',
        content: 'So what should I prepare in my home?'
      },
      {
        type: 'p_arr',
        content: [
          `Your massage therapist will arrive at your door with all of the mandatory supplies to perform your massage - this includes… you guessed it a massage table. The massage table is about the size of a standard rectangular coffee table.`,
          `Start thinking of a few places in your home where this table could fit (along with a little clearance of course for a human to walk around the perimeter of it) If you’re not sure don’t worry! When your massage therapist arrives they can help you decide where the best place may be. We typically massage our mamas in living rooms, home offices and even balconies/decks (depending on the season)`,
          `We do suggest your massage happens on the main floor of your home due to the weight of the massage table (it’s about +40lbs!)`,
          `Many of our mamas love to make the space they choose as relaxing as possible, this could include lighting a few candles, diffusing some essential oils, and putting on your favorite playlist to chill out to.`,
          `You will also need to pull out two flat sheets, a pillowcase, and two pillows for your massage. Although ordinarily, we love to bring all the supplies we are taking extra precautions during the pandemic.`
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `(BTW check-out these blogs if you have booked an `,
          {
            content: 'in-home prenatal massage',
            link: 'https://www.mamamobile.com/blog/What-To-Expect-During-Your-First-Prenatal-Massage',
          },
          ` or `,
          {
            content: 'in-home postnatal massage',
            link: 'https://www.mamamobile.com/blog/What-To-Expect-During-Your-First-Postpartum-Massage',
          },
          ` for further instructions about your experience)`,
        ]
      },
      {
        type: 'h5',
        content: 'What should I wear?'
      },
      {
        type: 'p',
        content: `Many of our mamas love to put on something comfy! A robe, pj’s, or even the clothes you wore to work are just fine. Your Registered Massage Therapist will give you a chance to crawl into the sheets while she has exited the room but it is always nice to have something comfortable to slip on afterward especially if your massage is happening before bed.`,
      },
      {
        type: 'h5',
        content: 'What’s the difference between my first massage and subsequent? I noticed the first is longer'
      },
      {
        type: 'p_with_link',
        content: [
          `We really care about you. We chose to make the length of your first experience slightly longer in order to create a fully curated massage plan. During your first session, your massage therapist will spend a little extra time getting to know you and your goals (even if that goal is just to relax!) We keep detailed notes so that no matter who you work with moving forwards everyone was on the same page to give you the best massage experience possible. `,
          {
            content: 'Click here to book your first in-home massage.',
            link: 'https://booking.mamamobile.com/',
          }
        ]
      },
      {
        type: 'h5',
        content: 'How do in-home couples massages work at Mama Mobile?'
      },
      {
        type: 'p',
        content: `We understand how busy parenting can be, so have chosen to do our couples massages a little differently! You and your partner can book as late as 8 pm. Your massages will happen back-to-back, most of our couples tag-team the littles to bed and then chill together after in their zen state.`,
      },
      {
        type: 'h5',
        content: 'What sort of training do Registered Massage Therapists have in Ontario?'
      },
      {
        type: 'p',
        content: `All of the Massage Therapists on the Mama Mobile team have completed a 2+ year college program through an accredited Massage Therapy school in Ontario. From there they have passed all licensing examinations to uphold the title of Registered Massage Therapist.`,
      },
      {
        type: 'h5',
        content: 'How do I claim my massage for insurance?'
      },
      {
        type: 'p',
        content: `After your massage, you will be emailed a receipt that you can easily submit to your insurance company for reimbursement!`,
      },
      {
        type: 'h3',
        content: 'What are the top 5 benefits to getting massage at home vs in a clinic or spa?'
      },
      {
        type: 'p',
        content: 'Massage has a primary intention of lengthening out muscles that become shortened resulting in the soreness you feel. This shortening typically happens as a result of repeated daily tasks, twisting yourself into strained postural positions and everyday stressors!'
      },
      {
        type: 'h5',
        content: `1. Avoid Traffic and Parking Fees`,
      },
      {
        type: 'p_arr',
        content: [
          `The biggest benefit of having your massage at home is that you are able to stay in a relaxed environment following your treatment allowing your body to reap the effects for longer than you would if you were traveling to and from a physical location.`,
          `Having a massage at home saves you from having to drive, look for parking, pay for parking or transit, and sitting in traffic. You can finally remove the stress of being late for an appointment and the need to meticulously plan out your day.`,
        ]
      },
      {
        type: 'h5',
        content: `2. Put a value on your time`,
      },
      {
        type: 'p',
        content: `Your time as a mama is more precious than ever before. Many mamas end up putting themselves last and as a result, missing vital time for their own self-care. Having a massage at home makes it easy- use the pockets of time you already have.`,
      },
      {
        type: 'h5',
        content: `3. Avoid the hassle of a sitter`,
      },
      {
        type: 'p',
        content: `Hiring sitters, parking tickets, gas- it all adds up!`,
      },
      {
        type: 'h5',
        content: `4. Enjoy late Night Massage`,
      },
      {
        type: 'p',
        content: `Many clinics/spas close at 8 pm and this happens to be our most popular time for busy mamas and partners. Enjoy your massage while the kids are in bed. These options also allow you to crawl into bed afterward feeling more relaxed than ever before.`,
      },
      {
        type: 'h5',
        content: `5. Better Results`,
      },
      {
        type: 'p',
        content: `A massage isn’t just about what happens on the massage table- it is also about what happens afterward. The longer you can stay in a relaxed state the better magic the massage will have on your body.`,
      },
    ]
  },
  // {
  //   id: 26,
  //   date_created: new Date('June 4, 2020'),
  //   img: blog26,
  //   title: 'Can I get a massage yet',
  //   author: 'Sarah Rennick',
  //   type: 'Booking',
  //   blogPost: [
  //     {
  //       type: 'p_arr',
  //       content: [
  //         `Hey mamas,`,
  //         `We miss you and hope you, your family, and your loved ones are safe and healthy.`,
  //       ]
  //     },
  //     {
  //       type: 'p_with_link',
  //       content: [
  //         `As you may have heard, the Chief Medical Officer of Health has amended `,
  //         {
  //           content: 'Directive 2',
  //           link: 'https://www.ctcmpao.on.ca/resources/Directive-2-May-26-2020.pdf',
  //         },
  //         ` to state that Health Care Providers, including Registered Massage Therapists, are now permitted to gradually restart non-essential and elective services as soon as it is safe for ourselves and our clients to do so.`
  //       ]
  //     },
  //     {
  //       type: 'h3',
  //       content: `Is Mama Mobile Open?`
  //     },
  //     {
  //       type: 'p_arr',
  //       content: [
  //         `We would love nothing more than to be part of your self-care routine once again, but your safety and that of our Massage Therapists come first.`,
  //         `Nothing has been confirmed yet, but I predict re-opening around July 15th. This date will, of course, be influenced by the directives coming from the Ministry of Health.`,
  //         `Although we are not open we may be able to book some mamas on a case by case basis with extra precautions (depending on your area.) This will be for emergency or urgent care only. You can request an appointment old school by emailing us: hello@mamamobile.com or simply replying to this email.`,
  //       ]
  //     },
  //     {
  //       type: 'h3',
  //       content: 'You’re Still Our Priority - Steps To Reopening',
  //     },
  //     {
  //       type: 'h5',
  //       content: 'Rescheduled appointments'
  //     },
  //     {
  //       type: 'p',
  //       content: `We’re so sorry if your appointment was canceled before- I know it’s been so long now since that “almost” massage. Please know we are keeping track of this and that you will get first access to our booking system when it is opened again.`,
  //     },
  //     {
  //       type: 'h5',
  //       content: `VIP access AKA waitlist`,
  //     },
  //     {
  //       type: 'p',
  //       content: `I know even if you’re appointment wasn’t canceled you’ve been patiently waiting for your massage for months now. Although we can’t see you yet we would love to prioritize you when we can! Hop on the waitlist and you will get access to our booking system before it goes public. This means prime times!`,
  //     },
  //     {
  //       type: 'p_with_link',
  //       content: [
  //         {
  //           link: 'https://mailchi.mp/308973af5712/waitlist?utm_source=MMobile&utm_campaign=5dd308d58d-EMAIL_CAMPAIGN_2020_05_29_08_19&utm_medium=email&utm_term=0_26675a593b-5dd308d58d-110865049',
  //           content: 'GET ON THE VIP LIST',
  //         },
  //         {
  //           type: 'br',
  //         }
  //       ]
  //     },
  //     {
  //       type: 'p_arr',
  //       content: [
  //         `Lastly, I want to thank you from the bottom of my heart for all the support you’ve given us during this very difficult time. Our team is obsessed with caring for you and stepping out of that role was really challenging. Please know that every day you’ve been in our thoughts and we can’t wait to see you again.`,
  //         `We have a few really exciting announcements coming to you over the next month - stay tuned! We have listened to all of your requests and are creating something beyond awesome just for you.`,
  //         `If you have any questions please don’t hesitate to send them- we’re still here and always ready to chat. I promise to keep you updated as things change.`,
  //       ]
  //     },
  //     {
  //       type: 'p_noMargin',
  //       content: 'Warmest Regards,',
  //     }, 
  //     {
  //       type: 'p_noMargin',
  //       content: 'Sarah',
  //     }, 
  //     {
  //       type: 'p_noMargin',
  //       content: 'Mama Mobile Co-Founder and CEO',
  //     },
  //   ]
  // },
  {
    id: 25,
    date_created: new Date('April 18, 2020'),
    img: blog25,
    title: 'What To Expect During Your First Postpartum Massage',
    author: 'Sarah Rennick',
    type: 'Postnatal Massage',
    blogPost: [
      {
        type: 'img-fullSize',
        content: blog25,
        alt: 'postnatal massage'
      },
      {
        type: 'p_with_link',
        content: [
          `Postpartum massage is just as important as `,
          {
            link: 'https://www.mamamobile.com/blog/What-To-Expect-During-Your-First-Prenatal-Massage',
            content: 'prenatal massage'
          },
          `. Growing a baby for 9 months and the marathon of childbirth certainly take a toll on your body.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_arr',
        content: [
          `Our body recovers fastest with proper sleep, rest,  light movement, nutrition and hydration. Most new moms are so busy taking care of their new baby that little time is left for themselves.`,
          `Not to mention, caring for a new baby adds a new host of aches and pains and it is important to address them to avoid long term injuries. Postpartum massage can also have a positive impact on your mental health and help with postpartum depression.`
        ]
      },
      {
        type: 'h3',
        content: 'What is a postnatal Massage?',
      },
      {
        type: 'p',
        content: 'Postpartum massage is a massage received anytime after giving birth up to a year postpartum.',
      },
      {
        type: 'h3',
        content: 'When can a new mom start a postpartum massage?',
      },
      {
        type: 'p_arr',
        content: [
          `A new mom can begin to receive a postpartum massage as soon as the day after giving birth! A post-delivery massage within the first 4 days is highly recommended, at Mama Mobile we call this a “labour recovery massage”.`,
          `The purpose of this postpartum massage is to help you recover from the marathon of labour.`,
          `This labour recovery massage is slightly different than a typical massage. Instead of using deep targeted pressure points your postpartum massage therapist will use very light pressure and broad strokes. Rather than focusing on a few sore muscles your postpartum massage therapist will perform a full body massage. The aim of this massage is to help you relax and to boost circulation in your body.`,
          `Since your body just went through so much with labour it is important not to overstimulate it in the first few days postpartum. If your postpartum massage is too deep it can lead to increased tension as your body will not be able to recover.`,
          `It is important to work with a massage therapist who has special training and focuses their massage therapy practice on postpartum massage. All of the massage therapists at Mama Mobile have postpartum massage training and are up to date with the latest research.`,
        ],
      },
      {
        type: 'h3',
        content: 'Boost Lymphatic Drainage',
      },
      {
        type: 'p',
        content: `During pregnancy body fluids increase by 50%, as soon as you are cleared for a massage it will be helpful to your recovery to begin to reduce these fluids as quickly as possible. A postpartum massage will decrease swelling and help relieve your body of toxins.`,
      },
      {
        type: 'h3',
        content: `Increase Blood Flow`,
      },
      {
        type: 'p_arr',
        content: [
          `During labour (even c-sections) new moms are in particular positions for extended hours at a time. Without movement circulation is decreased and this affects recovery. A light touch broad pressure, full-body massage postpartum will help circulate oxygenated blood allowing all of your muscles to receive the nutrients they need. A boost in circulation will also increase your immunity and may help prevent you from getting sick.`,
          `*Consult with your medical professional before beginning any massage therapy treatments- post c-section you may require a longer recovery period before starting massage*`,
        ]
      },
      {
        type: 'h3',
        content: 'What are the benefits of postnatal massage?',
      },
      {
        type: 'h5',
        content: 'Better Sleep',
      },
      {
        type: 'p_arr',
        content: [
          `Sleep is the most important recovery tool you have. Postpartum your sleep will be interrupted so it is very important to make sure that in the hours you do have you are achieving the highest quality sleep possible.`,
          `Massage encourages the body to release endorphins. Endorphins are feel-good and natural pain relief hormones that are produced by the brain. This will help to improve your sleep postpartum.`,
          `Have you ever noticed that you have fallen asleep during a massage? Studies have proven that massage can help the brain to release delta waves - these help you achieve a deeper more restful sleep.`,
        ]
      },
      {
        type: 'h5',
        content: 'Enhances Breastfeeding'
      },
      {
        type: 'p_arr',
        content: [
          `During postpartum muscles in the chest and shoulders often become tight as a result of carrying around the new baby and hunching forward while breastfeeding. As a result milk ducts can become slightly blocked and cause milk flow to slow down. Studies show that massage increases prolactin levels - this is a lactation hormone. Postpartum breast massage will not only help increase milk flow but it will also boost milk production.`,
          `Mastitis is a common problem postpartum moms face. This is the inflammation of the breast tissue which can involve an infection. Massage can help with this too!`,
        ]
      },
      {
        type: 'h5',
        content: `Reduces Neck Pain and Tension Headaches`,
      },
      {
        type: 'p_arr',
        content: [
          `When you are breastfeeding or bottle feeding a baby your chin is more than likely angled down to check on your little one. This positioning will lead to tension in your shoulders and the back of your neck over time. For some new moms the tension can get so bad that it leads to headaches. These are often caused by hypertonic bands of muscles that cause a pain referral pattern, they are called trigger points. All  trigger points in your body have different pain patterns- there are some that originate in your shoulders and refer pain into your temples. The best way to relieve these trigger points is through postpartum massage.`,
          `Through sustained pressure or movement your massage therapist will be able to release them for you. Postpartum massage is one of the most beneficial ways to reduce tension in the neck and shoulders. Your massage therapist will recommend stretches to do following your postpartum massage to ensure that the effects last until your next massage.`,
        ]
      },
      {
        type: 'h5',
        content: 'Reduces Back Pain',
      },
      {
        type: 'p_arr',
        content: [
          `Most women continue to experience lower back pain in the weeks following labour. During the last trimester prenatal massage therapists lighten the pressure in your lower back for your safety and many pregnant moms find it hard to get full relief from their pain.`,
          `The good news is that after the first few months postpartum your massage therapist will be able to target your lower back again safely! This will bring relief to the aching muscles and help your lower back recover.`,
          `You may also be feeling pain in your mid/upper back as a result of carrying the new baby and sporadic feeding in a hunched position. Your new baby is giving you a functional workout and you are most likely not used to having these muscles worked so frequently!`,
          `Postpartum massage is a great way to help tired back muscles relax and speed up the recovery from your new functional “baby minding workouts” aka being a new mom.`,
        ]
      },
      {
        type: 'h5',
        content: 'Reduces Wrist Pain'
      }, 
      {
        type: 'p_arr',
        content: [
          `It is important to note that postpartum your body is producing the hormone relaxin for up to 6-months, this is the hormone that relaxes the joints in your body in preparation for labour!`,
          `With the loosening of the joints in your body your muscles will work extra hard shortening in order to protect the joints. This is most commonly what you are feeling when you notice “tightness” postpartum.`,
          `The biggest difference in having loose joints during pregnancy and postpartum is that during your pregnancy you most likely were able to rest more (unless you had other little ones you were running after!)`,
          `Most new moms find increased pain in their wrists/hands, leading to problems such as carpal tunnel syndrome and DeQuervain's tendonitis. This is because the joints in these areas are still lax and yet you are having to constantly activate them- think… picking up your new baby from the crib, putting the new baby down, breastfeeding or bottle feeding baby etc. Your wrists and hands never get a break so it is important to focus on these areas during your postpartum massage.`,
        ]
      },
      {
        type: 'h5',
        content: 'Relieves Achy Legs/Feet',
      },
      {
        type: 'p_arr',
        content: [
          `During pregnancy the arches in your feet drop causing your feet to flatten out. This leads to pain and tension. Postnatal massage will help your feet recover`,
          `Many postpartum moms experience residual swelling in the legs from pregnancy. Postpartum massage can include lymphatic drainage which will help reduce any swelling you may have. As a result this should help with any cramping too.`,
        ]
      },
      {
        type: 'h5',
        content: 'Helps with postpartum blues'
      },
      {
        type: 'p_arr',
        content: [
          `Unfortunately many women experience some sort of postpartum blues and  10% of postnatal women will go through postpartum depression. Massage is a tool that can help manage symptoms.`,
          `Booking a postpartum massage during daytime hours if you are alone is a great way to receive some human interaction and to ensure you are making time for yourself despite the demands of being a new mom.`,
        ]
      },
      {
        type: 'h3',
        content: 'What positions are safe?',
      },
      {
        type: 'p_arr',
        content: [
          `Your massage therapist will assess the best position for you to be in during your postpartum massage based on your current sleeping position. If you have been cleared to lay on your stomach  by your medical professional and have not had a c-section within the last two months this position should once again be safe for you.`,
          `You may be feeling additional breast tenderness and swelling. The massage therapists at Mama Mobile are specially trained and will provide you with additional breast support. A towel roll will be placed underneath your collar bones and a pillow under your stomach. This will elevate your breasts from being pressed uncomfortably against the massage table.`,
          `Some new moms find it most comfortable during the first few months after giving birth  to continue postpartum massage in a side-lying position similar to prenatal massage. Even with additional breast support it can be uncomfortable to have even slight pressure on tender breasts. If you book a postpartum recovery massage within the first week postpartum this will most likely be in a side-lying position too.`,
        ]
      },
      {
        type: 'h3',
        content: 'What will I do with my baby during my postpartum massage?',
      },
      {
        type: 'p_arr',
        content: [
          `A lot of new moms that we visit choose to have their postpartum massage during daytime hours. Oftentimes they don’t have an extra set of hands around and that’s okay!`,
          `If your baby is on a nap schedule booking your postpartum massage during naptime is generally the best option.`,
          `You will be able to put your baby down and  leave your baby monitor on during your massage.  Your massage therapist can help make suggestions if your baby needs attention and even tend to your baby if she cries.`,
          `If you are not yet on a nap schedule you can always place your baby in a carrier, playmat, bassinet etc. close by. This option is a little less conventional but ensures that you can still get the postpartum massage you deserve. We find that if the baby can see mom they are generally happy- this usually means placing the baby under the headrest so that they can see your face.`,
          `Sometimes the best position for both mom and baby during a postpartum massage is on the table together. The postpartum massage therapists at Mama Mobile are trained on safe positioning - usually this looks like you laying on your side with the baby tucked in. This position offers easy access to the boob and a special bonding experience.`,
        ]
      },
      {
        type: 'h3',
        content: 'How do I make the effects of my postpartum massage last?'
      },
      {
        type: 'p',
        content: `In the week following your postnatal massage your body will most likely be feeling some relief! In order to ride out this feeling there are a few steps you can take at home:`,
      },
      {
        type: 'h5',
        content: '1) Stay Hydrated',
      },
      {
        type: 'p',
        content: 'Drinking lots of water helps your muscles/joints function better and often leads to less pain!',
      },
      {
        type: 'h5',
        content: '2) Practice Self-Massage'
      },
      {
        type: 'p',
        content: 'Try some of our self massage techniques for postpartum check out the Mama Mobile YouTube channel.'
      },
      {
        type: 'h5',
        content: '3) Move your body',
      },
      {
        type: 'p_arr',
        content: [
          `Consult with your medical professional to find out the best time for you to begin a postpartum exercise program. Light walks are a great way to get started.`,
          `* If you are recovering from a c-section this will vary`,
        ]
      },
      {
        type: 'h5',
        content: '4) Perform Light Stretching',
      },
      {
        type: 'p',
        content: `After your postnatal massage your Mama Mobile massage therapist will provide you with a few stretches you can do at home based on your unique treatment plan. This will help you maintain the effects of the massage and manage daily aches/pains!`,
      },
      {
        type: 'p_with_link',
        content: [
          `For relaxing at home postnatal yoga check out the `,
          {
            content: 'Mama Mobile YouTube Channel',
            link: 'https://www.youtube.com/channel/UC3Y8ybylNb_DE6_7CVpw3DQ',
          },
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `At Mama Mobile, we have an experienced team of massage therapists who specialize in postnatal massage. If you are a new mother who has just given birth in Toronto, Ottawa or Vancouver and need help with massage, `,
          {
            content: 'you can book an appointment online here.',
            link: 'https://booking.mamamobile.com/',
          },
          {
            type: 'br'
          }
        ]
      },
    ]
  },
  {
    id: 24,
    date_created: new Date('April 18, 2020'),
    img: blog24,
    title: 'What To Expect During Your First Prenatal Massage',
    author: 'Sarah Rennick',
    type: 'Prenatal Massage',
    blogPost: [
      {
        type: 'img-fullSize',
        content: blog24,
        alt: 'prenatal massage'
      },
      {
        type: 'p_arr',
        content: [
          `If this is your first pregnancy it can be a confusing time. You may be researching and wondering which of your beloved pre-pregnancy activities are still safe?`,
          `Fortunately, pregnancy massage is not only safe to enjoy but it is also encouraged! Studies show that massage during pregnancy can help manage symptoms and make for an easier labour. Here are all the things you need to know before you book your first prenatal massage appointment!`,
        ]
      },
      {
        type: 'h3',
        content: 'Is it safe to get a massage during pregnancy?'
      },
      {
        type: 'p_arr',
        content: [
          `Yes, massage is safe during pregnancy. Some spas discourage against massage altogether during the first trimester. There is a myth that massage may somehow eject the baby during pregnancy, we don’t have magic superpowers and can assure you that your baby will stay intact during your massage. No matter your trimester.`,
          `Our Registered Massage Therapists at Mama Mobile have special training in massaging prenatal women in all stages of pregnancy, first trimester included! Of course as with any massage, this is given that you have been granted the green light from your health practitioner to get started.`,
          `There are a few accupressure points that most massage therapists will avoid in order to keep you safe. These points are said to induce labour although there is no firm medical research proving this. A few of these include the area between your ankle bone and heel and the fleshy area on your hand between your thumb and index finger.`
        ]
      },
      {
        type: 'h3',
        content: 'How does prenatal massage differ from regular massage?'
      },
      {
        type: 'p_arr',
        content: [
          `Prenatal massages are adapted to the anatomical changes of your body. Traditionally when you think of massage you are most likely picturing yourself face down on a massage table. Things will look a little different during your prenatal massage as the safest and best position for you to be in is on your side.`,
          `Luckily our massage therapists are specially trained to massage women during their pregnancies and believe this position to not only be the safest but also gives the best access to areas of the body that are most affected by pregnancy.`,
          `Your Mama Mobile massage therapist will bring many pillows to your home. During your massage she will place a pillow in between your legs, under head and in between your arms. This will prop your body up to effectively access the areas that need TLC.`,
          `At some point in the massage you may be asked to turn onto your back in what we call a “semi-side lying position.”`,
          `We do so very methodically as laying on your back during pregnancy is cautioned. The weight of the baby and uterus can compress blood vessels. This reduces circulation to your placenta making it an unsafe position and one that can result in dizziness. We achieve our semi-side lying position safely by placing a wedge underneath your right hip. All of our massage therapists are trained to do this properly, it is our duty to keep you and your baby safe!`,
        ]
      },
      {
        type: 'h3',
        content: 'Why it is important to seek a prenatal massage specialist?'
      },
      {
        type: 'p_arr',
        content: [
          `As you continue to grow your baby your body makes major changes in order to accomodate. A hormone called relaxin is pumped through your body during pregnancy. Relaxin causes all of the joints in your body to loosen which causes temporary structural changes such as the slight broadening of your hip bones to prepare for labour.`,
          `Your muscles begin to move from their regular lengthened position into a shortened position to help out the lax joints and reduce injury. As a result of the lax joints and shortening muscles many women experience pain and discomfort. You are most likely feeling pain in your back, neck and hips from these changes.`
        ]
      },
      {
        type: 'h3',
        content: 'How do I find a prenatal massage expert?'
      },
      {
        type: 'p_arr',
        content: [
          `All massage therapists learn how to perform prenatal massage during their massage therapy training. However, in order to become an expert in prenatal massage they must complete additional courses and regularly work with pregnant women to maintain their skills. Upon graduation many massage therapists don’t have enough access to this population and quickly lose confidence in the skills they learned.`,
          `This is one of the reasons that as a company we choose to focus exclusively on moms. We provide in house training to ensure all of our massage therapists have the same skill-set and have quarterly education sessions to sharpen their skills. We also operate as a community allowing all of our massage therapists to share knowledge between one another.`
        ]
      },
      {
        type: 'h3',
        content: 'What are the benefits of prenatal massage?'
      },
      {
        type: 'h5',
        content: 'Reduces Swelling',
      },
      {
        type: 'p',
        content: `The massage therapists at Mama Mobile are trained to incorporate lymphatic drainage into your prenatal massage. This technique will help to reduce swelling that you may be experiencing during your pregnancy in places like your legs, hands and feet (as long as that swelling isn't a result of preeclampsia).`
      },
      {
        type: 'h5',
        content: 'Connection With New Baby'
      },
      {
        type: 'p',
        content: 'Housing a new baby can make your body feel foreign to you at times. Massage is a great way to connect your body and mind. It may also help you build connection to your baby.',
      },
      {
        type: 'h5',
        content: 'Stabilize Hormones'
      },
      {
        type: 'p',
        content: 'Regular massage during pregnancy can help reduce anxiety, elevate your mood.'
      },
      {
        type: 'h5',
        content: 'Improves Nerve Pain',
      },
      {
        type: 'p',
        content: 'Sciatica and piriformis syndrome are very common symptoms during pregnancy. This might feel like numbness or tingling in the lower back, bum area and potentially all the way down the leg. Prenatal massage can also eliminate carpal tunnel pain.',
      },
      {
        type: 'h5',
        content: 'Makes Delivery Easier',
      },
      {
        type: 'p_with_link',
        content: [
          `Studies show women who have regular massages during pregnancy have shorter labors (three hours shorter on average!) and less demand for pain medication. This is most likely due to a decrease in stress hormones `,
          {
            content: '(source)',
            link: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2870995/',
          },
          ` Published in Expert Review of Obstetrics and Gynecology.`
        ]
      },
      {
        type: 'h5',
        content: 'Improves Circulation'
      },
      {
        type: 'p',
        content: 'Massage improves blood flow and circulation. Improved circulation is particularly important during pregnancy because it allows your body to deliver more nutrients and oxygen to the baby. The more nutrients and oxygen the baby receives the more it can grow!'
      },
      {
        type: 'h3',
        content: 'Can prenatal massage prepare your body for labour?',
      }, 
      {
        type: 'p',
        content: `Prenatal massage is a great modality to prepare your body for the marathon of birth that is to come. When it comes time to push it is much easier to do so when your muscles are in a relaxed state. Every prenatal massage should include a bit of massage around your hips.`,
      },
      {
        type: 'h3',
        content: 'How often should I get prenatal massages?'
      },
      {
        type: 'p_arr',
        content: [
          `Your massage therapist will create a specialized treatment plan for you based on your unique needs. Generally during pregnancy massage every 3-4 weeks will best help to accommodate the quick changes your body is going through.`,
          `Many of our mamas choose to do a few bookings a few days apart during their last few weeks!`,
        ]
      },
      {
        type: 'h3',
        content: 'I’m late in my third trimester- can I start getting massages?',
      },
      {
        type: 'p_arr',
        content: [
          `As long as you have been cleared by your medical professional for massage it is safe for you to begin treatment as far into your pregnancy as on your predicted due date! Most mamas-to-be find the last 3 weeks of their pregnancy to take the biggest toll on their bodies and it is not too late to benefit from the effects of a prenatal massage.`,
          `If you have reached a point during your pregnancy where you don’t feel agile enough to get onto a massage table or you are on bed rest don’t worry! All of the Mama Mobile massage therapists are trained to offer alternative positioning options for your prenatal massage. Your massage can be performed on a chair or even laying on the couch or in bed.`,
        ],
      },
      {
        type: 'h3',
        content: 'How much does prenatal massage cost?',
      },
      {
        type: 'p',
        content: `The good news is that most insurance plans cover prenatal massage! A standard 1-hour session is 128, many of our mamas opt for a 90-minute session to ensure all areas can be covered.`
      },
      {
        type: 'h3',
        content: 'How do I make the effects of my prenatal massage last?',
      },
      {
        type: 'p',
        content: 'In the week following your prenatal massage your body will most likely be feeling some relief! In order to ride out this feeling there are a few steps you can take at home:'
      },
      {
        type: 'h5',
        content: '1. Stay Hydrated'
      },
      {
        type: 'p',
        content: 'Drinking lots of water helps your muscles/joints function better and often leads to less pain!'
      },
      {
        type: 'h5',
        content: '2. Practice Self-Massage',
      },
      {
        type: 'p',
        content: 'Try some of our self massage techniques for pregnancy check out the Mama Mobile YouTube channel.'
      },
      {
        type: 'h5',
        content: '3. Move your body'
      },
      {
        type: 'p',
        content: 'With a growing bump it can be tempted to avoid moving more than needed. Challenging your muscles from their day to day positions can help to reduce tension - this can be as easy as a light walk. (make sure to consult your medical professional before beginning a new exercise program).'
      },
      {
        type: 'h5',
        content: '4. Perform Light Stretching'
      },
      {
        type: 'p',
        content: `After your prenatal massage your Mama Mobile massage therapist will provide you with a few stretches you can do at home based on your unique treatment plan. This will help you maintain the effects of the massage and manage daily aches/pains!`,
      },
      {
        type: 'p_with_link',
        content: [
          `For relaxing at home prenatal yoga check out the `,
          {
            content: 'Mama Mobile YouTube Channel',
            link: `https://www.youtube.com/channel/UC3Y8ybylNb_DE6_7CVpw3DQ`
          },
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `At Mama Mobile, we have an experienced team of massage therapists who specialize in postnatal massage. If you are a new mother who has just given birth in Toronto, Ottawa or Vancouver and need help with massage, `,
          {
            content: 'you can book an appointment online here.',
            link: `https://booking.mamamobile.com/`,
          },
          {
            type: 'br'
          }
        ]
      },
    ]
  },
  {
    id: 23,
    date_created: new Date('April 16, 2020'),
    img: blog23,
    title: `Why You Should Become Your Baby's Massage Therapist!`,
    author: 'Sarah Rennick', 
    type: 'Baby Massage',
    blogPost: [
      {
        type: 'img-fullSize',
        content: blog23,
        alt: 'prenata massage'
      },
      {
        type: 'p_arr',
        content: [
          `Out of babies five senses touch is the one that is most developed from birth, this is one of the many reasons babies crave a soothing massage just as much as you do!`,
          `Find out about the possible benefits of infant massage and learn how to get started.`
        ]
      }, 
      {
        type: 'h3',
        content: 'What are the benefits of baby massage?',
      },
      {
        type: 'h5',
        content: `Increased Bonding`
      },
      {
        type: 'p_arr',
        content: [
          `Infant massage provides a great opportunity for you to get to know your baby better. You will begin to connect through intentional touch. Massaging your baby provides an opportunity for you both to slow down and relax together. You will learn to read your baby’s cues better as you focus on her response to the massage. By making this part of your daily routine you will both be able to look forward to this special time together.`,
          `The best part is that this is an activity you can carry into the toddler stage. Once your baby becomes familiar with massage it can become a calming technique in the future.`
        ]
      },
      {
        type: 'h5',
        content: 'Relief from Constipation',
      },
      {
        type: 'p_with_link',
        content: [
          `It can be challenging as a new mom to not have a solution for your baby’s constipation. By having specific tactile massage techniques to apply that are proven to help will give you a sense of empowerment. If constipation is something your baby is struggling `,
          {
            content: 'checkout our course to learn more about baby massage for gas and constipation.',
            link: '/baby-massage-course',
          }
        ]
      },
      {
        type: 'h5',
        content: 'Improves Immunity'
      },
      {
        type: 'p_with_link',
        content: [
          `There are specific massage techniques that you can perform around your baby's sinuses to help relieve congestion. `,
          {
            content: 'You can learn more about how to boost your baby’s immune system in our course',
            link: '/baby-massage-course',
          },
          `. Regular infant massage will also help to improve circulation for your baby which in turn will help the immune system become stronger!`,
        ]
      },
      {
        type: 'h5',
        content: 'Improves Sleep Quality'
      },
      {
        type: 'p',
        content: `Studies show that regular massage can result in a more restful sleep. Performing infant massage on your baby before bedtime or naptime can help her associate it with sleep and may make the process of winding down a little easier!`
      },
      {
        type: 'h3', 
        content: 'When should I massage my baby?'
      },
      {
        type: 'p_arr',
        content: [
          `Although it is tempting to want to use infant massage as a tool to help relieve a temper tantrum this is not the ideal time to perform it. It is best to massage your baby when they are in a ‘quiet but alert stage’ this is generally before or after nap time or bedtime. It is best to perform the massage at the same time each day so that the baby can learn to expect and enjoy it.`,
          `If the infant massage is done when your baby is fussy it is likely they will never be able to associate massage with relaxation- we don’t want this to happen!`,
          `Make sure to wait 20 minutes + after a feeding to avoid tummy upset and spitting up.`,
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Tip:',
          },
          ` Many moms love to incorporate massage post bath time as this is a time that you would ordinarily be applying oil or lotion to your babies skin.`,
        ]
      },
      {
        type: 'p',
        content: '',
      },
      {
        type: 'h3',
        content: 'What oil should I use for baby massage?'
      },
      {
        type: 'p_arr',
        content: [
          `There are many baby massage oils on the market which appear to be safe but ensure that you check the ingredient label for anything non-edible. During infant massage the baby massage oil will be spread on the face and fingers so it is best to be a little more cautious than normal!`,
          `We suggest using an edible cold-pressed fruit or vegetable oil traditionally used for cooking: coconut oil, grapeseed oil and apricot oil are all great choices! Not only will these oils have a great glide on your baby's skin (this is important for comfort!) but you will not have to worry if your baby ingests some- we know the baby will end up with a finger or toe in her mouth at some point!`,
          `Make sure to test the oil on a small area of your baby's skin and wait a few days to ensure there is no reaction.`,
          `Check out your local dollar store for a small lotion pump travel container and keep the oil in there ready for your next baby massage session.`,
        ]
      },
      {
        type: 'h3',
        content: 'How frequently should I massage my baby?'
      },
      {
        type: 'p',
        content: 'Infant massage can easily become part of your babies daily routine and we highly suggest it! Because there are so many benefits even if you only have time for a few minutes each day it is still worth doing. Build infant massage into your babies schedule by performing it at the same time and in the same place each day.'
      },
      {
        type: 'h3',
        content: 'What do I need before I start a baby massage?'
      },
      {
        type: 'p',
        content: 'It doesn’t take many supplies to begin your babies infant massage routine. Here are a few things we suggest:'
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Warm Room:'
          },
          ` Since you will be undressing the baby for the massage it is important for the room to be warm so that she is comfortable.`,
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Soft Place for the Massage:'
          },
          ` Many mamas like performing the massage routine on baby’s change table, a soft carpet, couch or bed- make sure wherever you choose is safe.`,
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Soft Blanket:'
          },
          ` It is important for your baby to be warm and comfortable during their massage. Try using the same blanket each time to help your baby associate it with massage time.`,
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Baby Massage Oil:'
          },
          ` Edible cold-pressed fruit or vegetable oil.`,
        ]
      },
      {
        type: 'p_noMargin',
        content: '*if your baby has eczema you may want to use his prescribed cream for the massage.'
      },
      {
        type: 'p',
        content: '',
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Warm Hands + Calm Mom:'
          },
          ` Try rubbing your hands together several times before you begin and take a deep breath. It is important for you to be grounded in order to transfer relaxation onto your little one. Nervous touch can make a baby feel anxious- be confident mama!`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Training Guide:'
          },
          ` It is best to learn how to do all techniques properly. Check out our video course here or book a private infant massage lesson to learn curated techniques for your babies needs in a one on one setting!`,
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'h3',
        content: 'Can my partner massage the baby too?',
      },
      {
        type: 'p_arr',
        content: [
          `Performing infant massage on your baby is a great way to bond.`,
          `If you are breastfeeding, baby massage time can be a great opportunity for your partner to get some one on one skin to skin bonding with the baby too. (When you stop breastfeeding it can help ease the transition for mom and baby by giving you another way to connect.)`
        ]
      },
      {
        type: 'h3',
        content: `Are there any areas on my baby's body I should avoid?`,
      },
      {
        type: 'p',
        content: 'It is important to be cautious around baby’s joints as they are still developing. Be cautious when massaging around the elbows, knees, ankles, wrists and spine.'
      },
      {
        type: 'h3',
        content: 'What is the ideal age for infant massage?'
      },
      {
        type: 'p',
        content: 'Once your baby is old enough to crawl it can be challenging to begin a new routine- we suggest starting at 2 months old. This is generally around the time you will begin to receive cues from your baby such as eye contact, cooeing and distinguished cries that you will be able to recognize as a time to stop. These cues will give you important insight into which techniques your baby is enjoying.',
      },
      {
        type: 'h3',
        content: 'How to Massage Your Baby'
      },
      {
        type: 'p_with_link',
        content: [
          `Make sure to checkout our `,
          {
            link: '/baby-massage-course',
            content: `full course to learn all of the techniques on how to massage your baby.`,
          },
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_arr',
        content: [
          `Here are a few techniques to get you started: `,
          `Begin by rubbing some lotion in your hands above your baby. Ask him if he is ready for his Massage. Your baby will be laying on her back for this routine. Start at the feet and work your way up, repeat on the other side of the body. Perform each technique 3-5 times before moving on to the next body part. If your baby begins to fuss make sure to stop- you always want your baby to associate massage with relaxation.`
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Feet:'
          },
          ` Gently draw little circles with your thumb from the heel to the toes.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Legs:'
          },
          ` Start on the outer ankle and with a flat hand stroke all the way up to the bum.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Bum:'
          },
          ` With your palm draw larger circles along the side of the bum.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          {
            type: 'b',
            content: 'Belly:'
          },
          ` With the pinky edge of your hand starting at the lower ribs gently swipe hands one after the other towards the hips.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'h3',
        content: `Happy Massaging - Drop me your email and I'll send you a teething massage routine!`
      },
      {
        type: 'baby_massage_form',
      }
    ]
  },
  {
    id: 22,
    date_created: new Date('March 25, 2020'),
    img: blog22H,
    title: `Surviving Postpartum Depression: How I Found My Worth As a New Mom`,
    author: 'Vanessa',
    type: 'Mama Story',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          `I still remember the day I found out I was pregnant with my first. I was excited, I was scared and I was VERY uncertain of my future.`,
          `You see my first pregnancy was NOT planned. I was NOT married. I was one year out of University and thankful to have been employed by a great company, with great pay (I mean any pay after graduating is great) and benefits.`,
          `OK so pregnant me… my partner and I at the time had only been dating for a little over a year and decided to live separately until the new year (2015) which was super close to when the baby was born (early February).`,
          `SIDE NOTE: looking back now and after having our now SECOND baby together I had missed being 8 months pregnant and ALONE in bed with it all to myself! I digress…`,
          `Anyway, fast track to the birth of my first born and there I was SHOOK at how amazing, crazy scary and wonderful everything felt that day.`,
        ]
      },
      {
        type: 'h3',
        content: 'I WAS A FREAKING MOM!',
      },
      {
        type: 'p_arr',
        content: [
          `NOW, let's couple the above experience with the horrible feelings and thoughts associated with Postpartum Depression (PPD). Not only did I start living with my partner (now fiance), and take on a BUNCH of new roles that I thought were EXPECTED of me (dishes, laundry, cooking, cleaning, groceries and MORE) because there wasn’t much time to determine roles and expectations and nuances of the day to day or week to week prior to the birth of our first born and being 8-9 months pregnant (like what days I should do laundry or groceries etc.) BUT, I also suffered from Postpartum Depression. I can admit this now but I struggled for months, maybe even a full year or so by way of horrible thoughts, constant crying, and uncontrollable or explainable RAGE. It took a toll on me, my partner and definitely didn’t make me feel like a great mother. Did I get help, NOPE I was in such denial, I was so upset for feeling these ways that I really tried my best to just BE NORMAL but my nervousness when someone else held my baby would get to me, when someone would tell me how to be would get to me, when others only handed her to me when she was hungry it would get to me, when I washed dishes I would cry, when basically anything that sparked any sense of feeling it would be intensified times 783874874 billion and come out or stay in in very unhealthy ways.
          So what happened, how did I “get better”.`,
          `Well, I think my partner helped push me to try things like yoga, exercise, starting a small business (which initially was selling children’s clothes) and I started to meet people. I started to get more comfortable talking about my experience, hearing from others and their experiences.`,
          `I returned to work following my mat leave. It felt good being around adults BUT part of me didn’t feel good enough to be there or perhaps it was that I felt destined to be home with my little one because it was so much more fun. Whatever it was I chose to find a new job to see if that would fill the void I was feeling and so I did for a year or so and then BOOM.`,
        ]
      },
      {
        type: 'quote',
        content: 'Positive pregnancy test. This one was planned.'
      }, 
      {
        type: 'h3',
        content: 'Why I ate my placenta!'
      },
      {
        type: 'p_with_link',
        content: [
          `It’s been 20 months since that happened and my little one is now 11 months old. I have to say this time around is so different. Yes there is the second child syndrome at play here so I am less anxious. One thing I firmly believe helped me was `,
          {
            content: 'eating my placenta for postpartum depression.',
            link: 'https://www.mommonality.com/post/i-ate-what-placenta-encapsulation-postpartum-depression',
          },
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p',
        content: 'It isn’t for everyone and some don’t believe in it but I truly believe that if I had done this with my first I would have never experienced the horror of Postpartum Depression.'
      }, 
      {
        type: 'h3',
        content: 'How I became a mom boss'
      },
      {
        type: 'p',
        content: 'So here I am today, on maternity leave (this time 18 months) with another baby girl and I feel so blessed to have two! Two girls who have each other as besties forever.',
      },
      {
        type: 'p_with_link',
        content: [
          `This time around I got that itch again, to do something for me, something creative and I went for it. I decided to start my own podcast called The `,
          {
            content: 'Mommonality Podcast',
            link: 'https://www.mommonality.com/podcast',
          },
          `, taking my `,
          {
            content: 'Mommonality BLOG to a new platform',
            link: 'https://www.mommonality.com/blog',
          },
          ` and speaking to people about motherhood entrepreneurship and so much more. `,
          {
            content: 'I also started a monthly mom based (family really) market in the peel region',
            link: 'https://www.mommonality.com/themrkt',
          },
          ` which I am taking a hit these days given the current situation of COVID-19. Prior to this pandemic we had done 2 markets that were VERY successful so I am super excited to get back to having them once everything going on subsides. Also, I recently took on the Moms Mississauga chapter powered by `,
          {
            content: 'Moms Toronto',
            link: 'http://momsto.com/',
          },
          ` and I am super excited to grow the community there with awesome events and experiences for parents.`
        ]
      },
      {
        type: 'p_arr',
        content: [
          '',
          `Anyway, all of this is to say that I changed, I grew and that there is always light at the end of the dark tunnel. I could have gotten here sooner if I had got professional help BUT I cannot beat myself up about that. The journey I went on that landed me where I am right now. These days I find myself transitioning all the (what would have been) FEAR into EXCITEMENT whenever it comes to doing things that once would have been a huge NO WAY or that would scare me given my insecurity and sense of self. Today I am thankful for my fiancé, for my children, my family, my friends but most of all I am thankful for the gift of me. The person I love the most… the person able to love others and be in love with all the things I choose to do from this point on in my life.`,
        ]
      },
      {
        type: 'p_bold_noMargin',
        content: `- Vanessa`
      },
      {
        type: 'a',
        content: 'www.mommonality.com',
        text: `www.mommonality.com`,
      },
      {
        type: 'p_noMargin',
        content: '@mommonality',
      }, 
      {
        type: 'p_noMargin',
        content: '@momsmississauga',
      },
      {
        type: 'p_noMargin',
        content: '@themommonalitymrkt',
      },
      {
        type: 'p_noMargin',
        content: '@themommonalitypodcast',
      },
      {
        type: 'img',
        content: blog22,
        alt: 'Vanessa'
      }
    ]
  },
  {
    id: 21,
    date_created: new Date('March 19, 2020'),
    img: blog21,
    title: 'Keeping My Toddler Busy During Social Distancing',
    author: 'Alexis Coronado',
    type: 'Mama Story',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'Being on maternity leave, it wasn’t much of a change to our daily routine when we were urged to stay home and practice social distancing. My husband was thankfully also given the green light to work from home.',
          'But living in a 2-bedroom condo, it became a challenge to give my husband the space to work. My toddler knows he’s home, but she doesn’t understand why he can’t play with her.',
          'So in the first day of self-isolation, I searched for ideas to keep my toddler busy, instead of resorting to screen time (I’m not against screen time, but I do try to limit it).',
        ]
      },
      {
        type: 'h3',
        content: 'Sensory Bins',
      },
      {
        type: 'p',
        content: 'Grab 3 bowls in small, medium and large sizes, measuring cups and spoons, a funnel, and roughly three cups of uncooked rice (pasta or lentils could work too). Place them all within a large tray as a catchall. Celine loves to scoop and transfer the rice from bowl to bowl and her independent play buys me at least half an hour!'
      },
      {
        type: 'h3',
        content: 'Memory Lane'
      },
      {
        type: 'p',
        content: 'Celine recently discovered our album of photo booth strips from our wedding and she likes to name the people she recognizes in the photos. It’s such a nice way to go down memory lane and also an opportunity for me to tell her stories from before she was born. Looking through her baby photos/videos is another fun pastime, she loves to watch videos we’ve taken of her when she was a baby.',
      },
      {
        type: 'h3',
        content: 'Box Road',
      },
      {
        type: 'p',
        content: 'I love this idea from @busytoddler on Instagram. If you have building blocks at home, all you need is to lay a box flat, draw a road with a marker, and the kids can build a city on top of it!'
      },
      {
        type: 'h3',
        content: 'Build a Fort/Hiding Spot',
      },
      {
        type: 'p',
        content: 'Celine’s at that age (2.5 years old) where any crevice she finds at home becomes a hiding spot. So I like to let her hang out there (after it passes a safety test) and offer her some books or colouring materials (and snacks) to occupy herself with.'
      },
      {
        type: 'h3',
        content: 'Dance Party',
      },
      {
        type: 'p',
        content: 'I like to execute this one when Celine starts asking me if she can watch TV. I’ll ask Google Home to play some music and she usually starts dancing with me. It counts as staying active, right? Obviously this would only work if my husband is not on a call (lol).'
      },
      {
        type: 'h3',
        content: 'Good Ol’ Screen Time'
      },
      {
        type: 'p_arr',
        content: [
          'When all else fails or if it’s been a particularly taxing day, I let her choose her favourite movie (currently rotating between Trolls and Cloudy with a Chance of Meatballs) and just join her on the sofa. At the least it will be some bonding time.',
          'I’m ending this post with a note to remind ourselves to give ourselves grace, especially at this time. Let’s be kind to ourselves and take it one day at a time (I know I have to remind myself of this daily). Stay safe, everyone.',
          '- Alexis Coronado'
        ]
      },
      {
        type: 'img',
        content: blog21,
        alt: 'Alexis Coronado'
      }
    ]
  },
  {
    id: 20,
    date_created: new Date('March 16, 2020'),
    img: blog20,
    title: 'Bookings Temporarily Closed Until Further Notice',
    author: 'Mama Mobile Team',
    type: 'Booking',
    blogPost: [
      {
        type: 'h3',
        content: 'A message from the Mama Mobile family.'
      },
      {
        type: 'p_arr',
        content: [
          `⁣⁣We have made the extremely difficult decision to close our home visits until further notice in order to protect the health of the community. To date, there are NO confirmed cases of a Massage Therapist or mama in our roster with COVID-19, but in these challenging times we want to support the collective whole.`,
          `While this commitment will affect our business on a surface level, we are more worried about the livelihood of our Massage Therapists and staff which absolutely breaks our hearts. Our thoughts and well wishes are with all of the other small businesses who are having to make this strenuous decision.`,
          `All bookings will be held until the date we reopen. We will take care of this for you, there is nothing you need to do. 💛`,
          `If you require an emergency booking please email us.`,
          `Please refer to Instagram and check your email for updates.⁣`,
          `Please take care of yourself and those around you. We look forward to seeing you when this passes.`,
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `If you wish to support us during this time please consider purchasing a gift card to use for your next visit or to gift to someone you love `,
          {
            content: 'click here.',
            link: '/gift-card',
          },
          ` We strongly urge you to consider doing this for other small businesses in your community too.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_noMargin',
        content: `Sending you all so much love,⁣`,
      },
      {
        type: 'p_bold_noMargin',
        content: `Sarah, Cherry and the Mama Mobile Team`
      },
      {
        type: 'img',
        content: blog20,
      },
    ]
  },
  {
    id: 19,
    date_created: new Date('March 10, 2020'),
    img: blog19,
    title: 'Getting Over Grief',
    author: 'Tracey Fraser',
    type: 'Mama Story',
    blogPost: [
      {
        type: 'img',
        content: blog19
      },
      {
        type: 'p_arr',
        content: [
          'I have to admit, the title of this blog is something I have searched a million times on google when trying to figure out how to get over the loss of my son Roo last year. How do you go back to normal? How do you tell people this is what you are going through without having to say it out loud? Is there a normal grieving timeline I should be following? How do I get over it? As it fast approaches his 1 year anniversary, I felt compelled to write down my experience and my lessons learned in a bid to help others on their own journey through grief.',
          `Sadly, as we get older, grief seems to play a bigger and bigger role as the years pass. It’s more profound now because we understand the consequences of death and how valuable life really is. My best friend recently described death as ‘final’ and she was right. It is final. However, I can’t help but feel that it also lingers and can overwhelm us if we don’t come to terms and deal with it in the best way we individually can.`,
          `My first point I want to emphasize is that grief is experienced differently by all. Therefore, there is no right or wrong way to deal with it. You might cry all the time, you might not want to speak about it, you might only want to speak about it, or you might be angry. These are just a few ways I’ve seen people deal with grief. The important thing is that you need to find the most appropriate way for you to get by. Initially, however, just coping and getting through each day as it arrives was the challenge. Longer term though, I found that using a repertoire of coping skills was the key to not letting grief overcome all.`,
          `When chatting about grief, it is important to remember that although you are experiencing it, who you are talking to has their own take on how to deal with it. Let’s be honest, most people often avoid the subject completely but there could be a number of reasons why; it brings up too many sad memories for them, it makes them uncomfortable, they are worried that they will say the wrong thing so they hardly say anything to avoid upsetting you, etc. This really struck me as true after Roo died. Some people were afraid to ask me about him while others opened up. I learned very quickly not to take it personally and, out of respect, didn’t judge others when they avoided conversations about him or death in general. I don’t know what they have been through, so I can’t judge. All I can do is not get caught up in someone else’s reactions.`,
          `By not taking other people's attitude to heart, this helped me deal with medical staff after our loss and the language they used. I will leave this for another post but, again, choosing not to take it personally was my first insight on how to cope in these situations.`,
          `Learning to be accepting of myself and my feelings was another major lightbulb for me. Society expects you to react in certain ways. This can put pressure on you to hurry up and get over it all - you’ve heard the phrase, “life goes on”. The only issue is that this mindset hinders your progress when you are experiencing a bad day (and I can guarantee you, they are going to happen). So instead, I actively acknowledge to be gentle with myself on the days I have felt down and upset. I gave myself permission to have those feelings and to really live them while they were happening. I didn’t - and still don’t - judge myself for my bad days, I simply recall that they are real and what I am experiencing is natural. Beating myself up for feeling bad helps no one. Often, after a bad day where I cry and reminisce, I feel so much better and happy I could express my feelings that way. So be kind while you cry and reflect.`,
          `I am a huge advocate of seeking professional help. Your mind is dealing with trauma and needs guidance. Finding someone you can open up to, to help unpack all your fears and emotions surrounding death is vital in helping you regain control of your situation. There is nothing to be ashamed or embarrassed about. If you have a counsellor and it isn’t working, try someone else - it makes a huge difference if you can connect and trust this person, so do not settle for anyone you do not trust.`,
          `Find your support group. I don’t mean this literally, although support groups are great for some. I mean, surround yourself with people who you know you can communicate with and let them know when things are tough. Partners, family, friends, professional help, or online support are just some ways in which to build up your coping community. I personally found speaking to other mums who had lost their baby rather comforting. I felt they understood my situation immediately and vice versa. It felt beneficial when another mum could reach out to me and feel brave enough to share their experience with me. I wasn’t alone and neither were they.`,
          `Time is an obvious but slow healer. When death hits you, time seems to take longer to pass. But looking back over my last year, I am amazed that despite some really low points, I have come so far in my healing journey. I am much more comfortable talking about what happened. I can even now support other people going through a similar situation without it taking me down the rabbit hole. The only problem with time, is that it takes time. You just need to trust me that in 6 months, your perspective will have changed and the rawness of it all will have faded.`,
          `When Roo died, he was cremated. Since we don’t own a house in Toronto, nor do we find any particular place ‘special’, we decided to have his ashes stored inside a teddy bear that we could take with us, no matter where we went. It is such a lovely reminder as we can give it a cuddle and instantly feel closer to our son. Memories and mementos are important in remembering what we were lucky to have. I also find that by celebrating his little life, I am honouring his time here with us. Some people might not like having reminders which I completely understand but for me, they are so vital as it reminds me of how quickly life can be over - so go live it.`,
          `Sadly, getting over grief isn’t something anyone I have ever met has been able to do. Instead, by learning to cope, people like myself have been able to move beyond its debilitating grasp that many get caught in. For whatever reason you are reading this post, just know that this is an experience we all share, to different degrees. You are not alone and I wish you all the best in your journey.`,
          `- Tracey Fraser`
        ]
      },
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1584684890/blog/tracey_mundie.jpg'
      },
    ]
  },
  {
    id: 18,
    date_created: new Date('March 3, 2020'),
    img: blog18,
    title: 'Improve Your Intimacy Postpartum',
    author: 'Dr. Tracy Dalgleish',
    type: 'Educate',
    blogPost: [
      {
        type: 'p',
        content: `Our relationship with our partner changes after welcoming a baby to your family. Notably, the intimacy between two partners often becomes the last thing on the “to-do” list. For some, they may feel they want to be intimate with their partner, but they don’t know where to start.`,
      },
      {
        type: 'h3',
        content: 'Here are 10 common challenges that impact intimacy postpartum.',
      },
      {
        type: 'h5',
        content: '1. The demands placed on you as a couple has increased.'
      },
      {
        type: 'p_noMargin',
        content: `This can have a positive or negative impact on your intimacy. For example, one study found that this new focus on your baby can result in a form of intimacy that is more sensual than sexual1. However, for others, it may feel like you are not being cared for, nurtured, or you only talk about baby. It can feel like a to-do list, and many couples report feeling decreased satisfaction and connection.`,
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '2. Lack of sleep and exhaustion.'
      },
      {
        type: 'p_noMargin',
        content: `Many mothers also report feeling “touched out” and physically exhausted. When you have nothing left to give, it can be hard to then turn to your partner and give them emotional, physical or sexual energy.`,
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '3. Hormonal changes and breastfeeding.',
      },
      {
        type: 'p_noMargin',
        content: `Some women will report experiencing lower desire and arousal, which can be part of the hormonal changes postpartum. Women who are breastfeeding may also experience a decrease in their desire levels and their ability to have adequate lubrication for intercourse.`,
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '4. Narrowly defining intimacy with a pressure to “get back to your old sex life.”',
      },
      {
        type: 'p_noMargin',
        content: `Sex is often defined as penis-in-vagina. This is a narrow definition of sex and creates pressure for sex to be a certain way. It creates the belief that if you are not having intercourse then you are not being intimate with your partner. Many women also feel pressure to return to their previous ways of having sex; however, this may not be what they want.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '5. Pain during intercourse. ',
      },
      {
        type: 'p_noMargin',
        content: `Experiencing pain and discomfort leads to decreased arousal and desire.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '6. Difficult and toxic emotions. ',
      },
      {
        type: 'p_noMargin',
        content: `The experience of “mom-guilt” or resentment towards your partner may contribute to difficulties with intimacy. `
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '7. Changes how you feel about yourself. ',
      },
      {
        type: 'p_noMargin',
        content: `Your body has undergone a huge change. Many women put unrealistic demands on their bodies to return to their previous shape, a pressure that often comes from media and societal messages. How you feel about your body impacts intimacy.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '8. Mental health difficulties',
      },
      {
        type: 'p_noMargin',
        content: `including postpartum depression or postpartum anxiety. `
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '9. Birth trauma or difficult birth experience.',
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '10. Nothing really “wrong,”',
      },
      {
        type: 'p_noMargin',
        content: `but for some, they report feeling unable to have sex and feel blocked.`
      },
      {
        type: 'br',
      },
      {
        type: 'quote',
        content: `It’s important to highlight that most couples are not having a lot of sex in this season of your relationship. Unfortunately, this isn’t something that is often talked about. So just how do you begin to improve your intimacy?`,
      },
      {
        type: 'h3',
        content: 'Here are 6 tips for addressing intimacy in the postpartum period:',
      },
      {
        type: 'h5',
        content: '1. Work on building trust and security in your relationship.',
      },
      {
        type: 'p_noMargin',
        content: `Do you spend time listening to each other and letting the other person know that they are important? Have conversations that allow each other to talk about hard emotions and experiences while providing a supportive and open space. Provide emotional and physical support during difficult moments.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '2. Redefine sex and start communicating about it with your partner.',
      },
      {
        type: 'p_noMargin',
        content: `What are other ways that you and your partner can define intimacy? Could it be through emotional, physical, spiritual, intellectual or experiential ways? What do you get out of sex? Make a list for your partner to understand what feels good for you right now in this stage.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '3. Heal toxic emotions.',
      },
      {
        type: 'p_noMargin',
        content: `If you are struggling with anxiety or depression, or guilt as a mother, these are emotions that you need to deal with. Healthy coping strategies could be through journaling, talking to your support system, or through seeking therapy.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '4. Learn how to touch just for the sake of touching.',
      },
      {
        type: 'p_noMargin',
        content: `Physical intimacy is key for our relationship. It provides positive physiological effects, like releasing oxytocin (the cuddle hormone that bonds us together, the same hormone released while breastfeeding) and helps to build closeness and security between partners. Try finding ten minutes each week to sit on the couch and gently stroke the other’s arm or give a 30 second hug each day.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '5. Learn how it feels to have an orgasm for yourself2. You are responsible for your sexuality.',
      },
      {
        type: 'p_noMargin',
        content: `Return to masturbation, or if you have never self-stimulated, this is a great time to do that. Learn to feel comfortable with being touched, with your own control.`
      },
      {
        type: 'br',
      },
      {
        type: 'h5',
        content: '6. Seek professional help.',
      },
      {
        type: 'p_with_link',
        content: [
          `If you are experiencing pain on intercourse, I highly recommend finding a pelvic floor physiotherapist to help address this. You may also listen to `,
          {
            content: 'this podcast episode',
            link: 'https://drtracyd.com/podcast/episode7/',
          },
          ` addressing your pelvic floor. If you are having difficulties addressing intimacy in your relationship, it is never too early (or too late) to see a trained mental health professional that specializes in sex therapy.`,
          {
            type: 'br'
          }
        ]
      },
      {
        type: 'p_with_link',
        content: [
          `Looking for more help with improving your intimacy? I created a guide for you and your partner to work through, called `,
          {
            content: 'Five Ways to Improve Your Intimacy.',
            link: 'https://sendfox.com/lp/m8elqm',
          },
          {
            type: 'br',
          }
        ]
      },
      {
        type: 'p',
        content: `Please note that all information is provided for educational purposes and does not substitute for the care of a healthcare professional.`,
      },
      {
        type: 'h5',
        content: `References`,
      },
      {
        type: 'p_noMargin',
        content: `1. Stavdal, M. N., Skjaevestad, M. L. L., & Dahl, B. (2019). First-time parents’ experiences of proximity and intimacy after childbirth: A qualitative study. Sex Reprod Healthc, 20: 66-71.`,
      },
      {
        type: 'p_noMargin',
        content: `2. See Appendix in Nagoski, E. (2015). Come As You Are.`
      },
      {
        type: 'br',
      },
      {
        type: 'h3',
        content: `About Tracy Dalgleish`,
      },
      {
        type: 'p_arr',
        content: [
          `Dr. Tracy Dalgleish helps individuals and couples navigate the challenges we all face in our relationships and within ourselves to create a more meaningful life through therapy, wellness seminars, and her work outside of the therapy room.`,
          `For over 15 years, Dr. Dalgleish has provided direct clinical services as well as researching, writing, and speaking about relationships. She provides psychological assessments, diagnosis, and individual and couple therapy for a variety of difficulties, including depression, anxiety, postpartum difficulties, stress and burnout, and relationship difficulties.`,
          `A mother of two young children and owner of Integrated Wellness, a mental health clinic in Ottawa Ontario, she understands what it means to juggle the full load of being a mother and professional.`,
        ],
      },
      {
        type: 'p_with_link',
        content: [
          `To learn more about what she does every day with clients in her therapy office, join her online community. Visit her website, https://drtracyd.com, or check out her Instagram page. If you prefer to learn on the go, Dr. Dalgleish offers a variety of ways to learn, including an e-course for busy women, a `,
          {
            content: 'membership site for women',
            link: 'https://beconnected.drtracyd.com/',
          },
          ` who want to master their relationship, or tune into her podcast, `,
          {
            content: `‘I’m Not Your Shrink’`,
            link: 'https://drtracyd.com/podcast/',
          },
          ` where she dives deeper into clinical knowledge and research, in a relatable and informal way.`,
          {
            type: 'br'
          }
        ]
      }, 
      {
        type: 'img',
        content: blog18,
      },
      {
        type: 'p_noMargin',
        content: 'Dr. Tracy Dalgleish'
      },
      {
        type: 'p_noMargin',
        content: 'Clinical Psychologist, Couples Therapist'
      },
      {
        type: 'p_noMargin',
        content: 'IG: @Dr.TracyD'
      },
      {
        type: 'p_noMargin',
        content: 'Facebook: @Dr.TracyDalgleish'
      },
      {
        type: 'p_noMargin',
        content: 'Website: https://www.drtracyd.com'
      },

    ]
  },
  {
    id: 17,
    date_created: new Date('February 12, 2020'), 
    img: blog17,
    title: 'STOP the whining',
    author: 'Cori Stern',
    type: 'Educate',
    blogPost: [
      {
        type: 'p',
        content:`Today's your day. I'm throwing you a bone. I'm giving you a few of my strategies on how to curb whining. In my opinion, there's nothing more painful than listening to your child whine.`
      },
      {
        type: 'li', 
        content: [
          'The first time it happens.... ok fine.',
          `The second time it happens..... I'm a bit annoyed but can deal`,
        ]
      },
      {
        type: 'p_arr',
        content: [
          `The third, fourth, & fifth time..... I'm about to lose my shit (and that's not good for no one) 🙅`,
          `Whining is one thing in my house that I don't tolerate. My threshold for it is extremely low. After a few times of hearing it, my body starts to tense, my jaw clenches and automatically my hair starts to frizz 😤That's my fight or flight response to whining, ha!`,
          `Children use whining as a form of expression. It's an emotional outlet for them. It's a way for them to indirectly unload their feelings in a very annoying way (for lack of a better term).`,
          `There is some good news to this. Whining is a cue for us! It's a sign letting us know that our child needs our help to work through whatever feelings and emotions they are experiencing.`,
          `Some children whine when they're tired, some when they're hungry, and some when they're overstimulated. Some children whine when they feel challenged, defeated, and/or annoyed.`,
          `Now if you think your toddler or preschooler is going to very calmly and politely come up to you and say...`
        ]
      },
      {
        type: 'quote',
        content: `"mom I"m feeling completely overstimulated because the tv is on, you and dad are talking loud, and my sister is running back and forth through the hallway with her toys, and I'm tired because I was at daycare all day today"`
      },
      {
        type: 'p',
        content: `...then we need to chat.`
      },
      {
        type: 'p_arr',
        content: [
          `It's more like... (read in whiny voice) "mummy, I can't hear the tv. mummyyyy, mummyyyy, ahhhhh, waaaa, muuuummy, I want to hear the tv, I can't hear the tv."`,
          `What you MUST know about whining, is the second you respond to your child by turning the tv up and lowering your voice at the same time, you have just reinforced the whining so you better believe it's going to happen again and again and again! Your child just struck gold! He got exactly what he wanted! 💥`,
          `We have to support our children in expressing their emotions. We have to support their emotional needs, and we have to teach them that whining is not an effective way of getting shit done.`,
          `Here are 3 ways to you can do that... It's time to say buh-bye to that whining noise!`,
        ]
      },
      {
        type: 'p_bold',
        content: `1. Make your child aware`,
      },
      {
        type: 'p',
        content: `Children need to be made aware of their behavior. Whenever my son or daughter whines I will immediately say "that's whining, mummy doesn't like whining". You have to give it a name, label it for them. Building awareness is the first part of curbing this behavior. Labeling it once, won't do anything. You want to keep labeling the whining behavior until you start to see some change happen.`,
      },
      {
        type: 'p_bold',
        content: `2. Give your child an alternative action`,
      },
      {
        type: 'p',
        content: `Once you've started labeling the whining behavior you need to give your child an alternative response to the whining. So if my child starts to whine, I will say "that's whining, mummy doesn't like whining, you can use your big kid voice instead and ask nicely". Since this is an emotional response- we wouldn't want to send our child the message that they shouldn't express themselves so you can also say something like "that's whining, mummy doesn't like whining, if you're hungry please tell mummy your tummy feels hungry and we'll get you a yummy snack". Modeling the appropriate response is really important here. Show them exactly what it’s supposed to look like. They need that model.`,
      },
      {
        type: 'p_bold',
        content: `3. Give another chance`
      },
      {
        type: 'p',
        content: `Again, since whining behavior is a form of communication, we don't want to hinder it. We don't want our children to feel blamed or shamed for trying to express themselves. We want them to feel empowered to express their emotional needs. So when you're child starts to whine, you always want to give them another chance to practice the alternative behavior you provided them with. Don't just shut them out and tell them to stop whining. That will just escalate things further and you'll end up in power struggle or meltdown territory. I love the phrase "try again or do you want to try again". For example, I'll say "that's whining, mummy doesn't like whining, you can use your big kid voice instead and ask nicely, do you want to try again".`,
      },
      {
        type: 'p',
        content: `Try these out and stick to the script. After practicing these strategies consistently, you will see the whining behavior start to decrease. We've used these strategies so much in my house that now all I have to say is "that's whining" and my kids redirect their response to a way more functional one! No more frizzy hair for me! (at least from the whining!)`,
      },
      {
        type: 'p',
        content: `I've got more strategies around this and any other behavior that comes your way. If your feeling exhausted, stressed or defeated in any way then I want you to book in a FREE 30-minute strategy call with me. I've got solutions for you.`,
      },
      {
        type: 'li',
        content: [
          `You've got this!`,
        ]
      },
      {
        type: 'p_arr',
        content: [
          `Cori`,
          `By trade, Cori has merged her backgrounds in Pedagogy, Child Study & Education, and Applied Behaviour Analysis to bring to mamas a very unique perspective on child growth & development.`,
          `She offers behavioral support and academic support services.`
        ]
      },
      {
        type: 'a',
        content: 'https://www.instagram.com/cori_stern/',
        text: `Follow Cori: @cori_stern`,
      },
      {
        type: 'p',
        content: ` `,
      },
      {
        type: 'a',
        content: 'http://coristern.com/',
        text: `Visit Cori: coristern.com`,
      },
      {
        type: 'img',
        content: blog17,
      },
      
    ],
  },
  {
    id: 16,
    date_created: new Date('AUGUST 30, 2019'), 
    img: `https://res.cloudinary.com/dhze7gimq/image/upload/c_fill,h_254,w_400/v1567181573/blog/Screen_Shot_2019-08-30_at_9.07.07_AM_hnqmli.png`,
    title: 'Why I CHOSE a C-Section',
    author: 'Amanda Thomson',
    type: 'Mama Story',
    blogPost: [
      {
        type: 'p',
        content:`“I had a c-section.” Every time you say that sentence you are immediately met with the same kind of questions or statements. “Emergency? Oh I\someone I know, had to have an emergency C-section” “oh, I was worried about that. Luckily I was able to have a natural birth.”  Now I don’t even pause I just immediately follow it up with…  “by choice.” The truth is, vaginal birth never even crossed my mind as an option.`
      },
      {
        type: 'img',
        content: `https://res.cloudinary.com/dhze7gimq/image/upload/q_40/v1567180122/blog/blog_16.png`
      },
      {
        type: 'p_bold',
        content: `Have you ever asked if you can have an elective C-section?`
      },
      {
        type: 'p_bold',
        content: `Have you ever talked about it in a group setting?`
      },
      {
        type: 'p_arr',
        content: [
          `Even discussing it is met with hushed tones and disapproval. Mom shamed is an understatement. I was questioned at every single turn, most people I talked to didn’t even know you could ask.`,
          `I was told about risks every single time I brought it up, which I had thoroughly researched and understood. One doctor I saw wouldn’t even discuss it with me and became almost standoffish every time I brought it up, I learned that advocating for yourself is the most important thing to do.`,
        ]
      },
      {
        type: 'quote',
        content: `My birth wasn’t any less natural because it’s what came naturally to me. I’m not any less of a mother because they used a scalpel, I’m a mother because I love my child with my entire existence.`
      },
      {
        type: 'p',
        content: `Lyric was welcomed to the world on November 5, 2018. My husband was at my side and was able to snap this epic photo.`,
      },
      {
        type: 'p_bold',
        content: `I have no shame in my birth story. It's mine.`,
      },
      {
        type: 'img',
        content: `https://res.cloudinary.com/dhze7gimq/image/upload/q_40/v1567181573/blog/Screen_Shot_2019-08-30_at_9.07.07_AM_hnqmli.png`,
      },
      {
        type: 'p',
        content: `About the author: Amanda is a bad-ass mama residing in Leslieville Toronto, Ontario.`,
      },
      {
        type: 'p',
        content: `Connect with her over on IG @amanda.c.thomson`,
      },
    ],
  },
  {
    id: 15,
    date_created: new Date('FEBRUARY 26, 2019'), 
    img: blog15,
    title: 'Is prenatal chiropractic safe',
    author: 'Dr. Salerno',
    type: 'Educate',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'Chiropractic is a safe and effective treatment that can be very beneficial during pregnancy. Chiropractors that provide care for pregnant women have additional training in The Webster Technique.',
          'Not only do we provide maintenance care throughout pregnancy to maintain pelvic balance but we also deal with the aches and pains that come with carrying a baby.',
        ] 
      },
      {
        type: 'img',
        content: blog15
      },
      {
        type: 'quote',
        content: '“The Webster technique focuses on creating a state of balance in the pelvic bony structures, muscles and ligaments. This allows baby greater room to develop without restrictions and to move into the best possible position for birth.”',
      },
      {
        type: 'p',
        content: 'The added frontal weight a woman experiences during pregnancy can put stress on the neck, back, hips, knees and feet. Changes occur on a daily basis! The added weight can also lead to altered posture and biomechanics as we change the way we sleep, stand and move during pregnancy. As the baby grows and the uterus expands, the abdominal muscles also stretch and lose their ability to support as they once did. We provide safe pre-natal exercises to prepare the body for delivery and safe movement techniques to reduce stress on the muscles and joints. Treatments may include soft tissue therapy, instrument or gentle manual adjustments and kinesiology tape for any aches and pains. We have special pillows that allow you to lay on your stomach during treatment! (Just like us mama!)'
      },
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555877668/blog/blog_1_2.jpg'
      },
      {
        type: 'p_arr',
        content: [
          'Some common conditions we treat during pregnancy include: mid back and rib pain, neck pain, headaches, low back pain, abdominal pain, pelvic and pubic pain and sciatica.',
          'Chiropractic care is also very effective for the post natal population as it will aid in getting the body back to its optimal function. We help to ease any residual pain from labour and help you feel refreshed and strong to care for your little one. We can help with all the new aches that come from caring for your baby and constantly being hunched over! This is done through specific exercises and making sure the joints and muscles heal as they should. We love to work as a team with Mama Mobile Massage RMT’s!',
        ]
      },
    ],
  },
  {
    id: 14,
    date_created: new Date('DECEMBER 7, 2018'),
    img: blog14,
    title: 'The Mom Blog',
    type: 'Mama Story',
    author: 'Daniella',
    blogPost: [
      {
        type: 'img-fullSize',
        content: 'https://www.themomblog.ca/wp-content/uploads/2018/11/Mama-Mobile-Massage-Blog-Header.jpg',
      },
      {
        type: 'p_arr',
        content: [
          'I’m a big proponent of self-care; mani-pedi’s, facials, long bubble baths and hair masks. But above all else, I LOVE getting a massage. While I was pregnant I would go for a bi-weekly massage and it was always the highlight of my week. However, once Lucas burst onto the scene I had a lot less time for self-care and making space in my schedule for a massage was no longer a priority. But the truth is I need massages now more than ever. Living in a townhouse and carting Lucas up and down multiple flights of stairs multiple times per day has taken its toll on my back, and now that I’m working out again my muscles are in a constant state of soreness. I was desperate to find a way to work massages back into my regular routine when I came across Mama Mobile Massage on Instagram.',
          'As the name suggests, Mama Mobile Massage offers pre and post-natal massage therapy services in the comfort of your own home. I booked my service during Lucas’ afternoon nap hour. My RMT Sam showed up a little early to give me time to get Lucas down. As she set up in my living room, he settled into his crib, and by 3pm I was face down in mama massage heaven. Sam assured me that if Lucas woke up that we had more than enough time for me to go upstairs and get him back into nap mode. That’s the beauty about this company. They account for the unexpected (read: likely) by book ending the session with extra time.',
          'Sam communicated with me throughout the session, ensuring that the pressure was perfect. Deep enough to work out any issues, but gentle enough to relax me. Her spa-like music and soft as clouds sheets and pillows lulled me into a light sleep, and by the end of the session both Lucas and I were feeling well rested and relaxed AF.',
          'If you are looking for the perfect gift to give to that mama to be or new mom on your list (or yourself), this is it. As kids come into the picture it becomes so much harder to take time out of our busy schedules for ourselves, but an in-home massage service that respects and accounts for the element of surprise gave me a sense of relief and comfort, making for a much more relaxing experience. I’m not one for New Year Resolutions, but if I had to put just one on my list it would be to make more time for self-care in 2019, starting with more regular massages. And it doesn’t hurt that my benefits are about to renew 😉',
        ]
      },
    ]
  },
  {
    id: 12,
    date_created: new Date('NOVEMBER 26, 2018'),
    img: blog12,
    title: 'Leslievillemom.com',
    type: 'Mama Story',
    author: 'Angela',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'I recently had a great prenatal massage from mama mobile massage. Their business model is such a great concept and it’s something I want to do again when baby two comes along. I got a few massages in the year after Jacob was born, but it was often very stressful to schedule for the first 9 months or so since he was hard to predict and could be very fussy.',
          'I didn’t have Jacob running around during my massage this time but I made sure to ask about how that would work. The therapist let me know they schedule extra time around each massage so if you have to stop to nurse/soothe the baby you can still get your full massage length even if you have to take some little breaks. I am crossing my fingers my next little boy will be better at napping but it’s good to know they are very flexible like that.',
          'The prenatal massage itself was excellent. They set up a different prenatal massage pillow I haven’t used before. In the past when I’ve had prenatal massages almost every place has you do side lying and there is a support pillow under your bump. The pillow they used lets you lie face down. It’s on an upwards angle with a cutout and sling where your belly is. You stomach stays supported by the sling so there is no back strain. It was nice to lie on my stomach – something I haven’t done in a while! The therapist was great and obviously experienced with prenatal massage.',
        ]
      }
    ]
  },
  {
    id: 11,
    date_created: new Date('NOVEMBER 16, 2018'),
    img: blog11,
    title: 'Is it possible to save money during maternity leave',
    type: 'Educate',
    author: 'Melanie',
    blogPost: [
      {
        type: 'p',
        content: 'As a mother of two, and being self employed, I have found it sometimes quite challenging to make ends meet. On the bright side, the following tips have helped me come out of tough times on top!',
      }, 
      {
        type: 'h3',
        content: '#1: Baby Shower',
      },
      {
        type: 'p',
        content: 'Ask and you shall receive! So… ask for necessities like diapers, clothes, and especially GIFT cards… You don’t know what kind of baby you will have so you can buy stuff as you go and not spend money on things you won’t use. You can also do a 50/50 draw by asking people to pick the baby’s birthday, fun way to make a little extra money which people are happy to do as they may have 50% of the money!!…everyone wins',
      }, 
      {
        type: 'h3',
        content: '#2 Ontario Child Benefit',
      },
      {
        type: 'p',
        content: 'Apply for the Ontario child benefit right away. Most people end up putting it aside for RESP or The Headstart Plan, but you might need to use some for the additional baby expenses.',
      }, 
      {
        type: 'a',
        content: 'http://www.children.gov.on.ca/htdocs/english/financialhelp/ocb/index.aspx',
        text: 'Click here to learn more!',
      },
      {
        type: 'h3',
        content: '#3 Accept Help',
      }, 
      {
        type: 'p',
        content: 'Accept grandma’s help for babysitting so you go out and you don’t have to pay for babysitting!',
      }, 
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555731239/blog/blog_11_2.jpg',
      }, 
      {
        type: 'h3',
        content: '#4 Moms Support Moms'
      },
      {
        type: 'p_arr',
        content: [
          'Trade with other moms…parents are so generous and they are mostly always happy to get rid of toys or other baby gear, you just need to ask!',
          'Social media is amazing place to start asking!! Go try it – it’s fun!'
        ]
      },
      {
        type: 'p_bold',
        content: 'Pro Tip: Try typing in a buy and sell for moms in your area on Facebook'
      },
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555731437/blog/blog_11_3.jpg',
      },
      {
        type: 'h3',
        content: '#5- Side Hustle!'
      },
      {
        type: 'p',
        content: 'Start a little side business if things are really tight – might be a good timing to do what you are really passionate about – go for it! You may never return to work!'
      },
    ]
  },
  {
    id: 9,
    date_created: new Date('AUGUST 5, 2018'),
    img: blog8,
    title: 'Making the most of nap time - how does nap time massage work',
    type: 'Booking',
    author: 'Sarah',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          "Mama Mobile created a secret weapon... Nap-Time Massage! If you are a busy mama on maternity leave with little to no help during the daytime this one's for you. We are SO happy you found us.",
          "Let us first establish and take a moment for our non-mama readers to understand how difficult it can be to leave the house with a baby under 12 months.",
          "It is so wonderful that some clinics & spas allow you to actually bring your babe to your Massage appointment. Maybe this has been working for you just fine (Keep going then mama!!) But maybe it has seemed like more of a problem in your mind than a luxury...",
          "Let me explain. There may be a mama stress battle going on inside your head before you leave the house to go to your scheduled appointment that goes a little something like this..",
        ]
      },
      {
        type: 'li', 
        content: [
          '"Okay if I feed him now we will be okay until..."',
          '"What might I need today.. "',
          '"How many diapers do I need... oh crap I forgot the baby wipes. Hopefully they have a place to change him"',
          '"Have I showered today?"',
          `"I hope she sleeps.. oh my gosh what if she FREAKS OUT. I'll have to leave"`,
          `"I wonder if I can find parking"`,
        ]
      },
      {
        type: 'p_arr',
        content: [
          `"This doesn't feel like a relaxing experience"`,
          'And Then... ',
        ]
      },
      {
        type: 'p_bold',
        content: '"Maybe I should cancel the appointent"',
      },
      {
        type: 'p_arr',
        content: [
          `Do you want to know how we know this story oh so well? We have heard it time and time again from our new mamas'. In fact, we created the Nap-Time Massage based soley on the experiences our mamas shared with us and things we had seen when working in these clinics/spas.`,
          `We created this business model to make your life just a little bit easier and to give you an option to enjoy something just for you. Our goal is to help erase every single one of those worries from your beautiful mind so you can focus on relaxing. (We realize that right now everyone/everything in your life is centred around your baby but, we want to focus on you. We are here to give you the Massage you deserve and we are not leaving until you get it!)`,
        ]
      },
      {
        type: 'h3',
        content: 'HOW WE MAKE EVERYTHING AMAZING',
      },
      {
        type: 'p_arr',
        content: [
          `Guess what? We actually schedule an extra 30 minutes as a grace period after your appointment at absolutely no extra cost. This means that you can take breaks during your Massage and still get your full scheduled hands on time on the table no matter what your sneaky little babe throws your way. (And we know just how sneaky he can be)`,
          `We typically try to schedule the appointment on a nap time. We always hope that your baby will nap for most of the Massage. Sometimes baby awakes 30 minutes in, in  need of a quick feed or diaper change. Other times when the baby is extra fussy he may need a position change.`,
        ]
      },
      {
        type: 'h3',
        content: 'Here are some things we have tried in the past with great results:',
      },
      {
        type: 'li',
        content: [
          'Exersaucer',
          'Baby swing or rocker',
          'Playmat',
          'Carrier',
          'Crib',
        ]
      },
      {
        type: 'p_arr',
        content: [
          'Etc. Any place your baby can chill out- this can take a few tries to find her happy place!',
          `Please note we wouldn't suggest these things if they didn't work. By not being in a rush we are able to pivot our approach to fit your needs on that specific day and to make this experience as relaxing as it can be.`,
        ]
      },
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555801386/blog/blog_9_1.jpg',
      },
      {
        type: 'h3',
        content: 'CO-BABY MASSAGE',
      },
      {
        type: 'p_arr',
        content: [
          `Okay. The exersaucer is a nice idea but what if my baby just really won’t calm the F down?`,
          `It is rare but it happens, mama. We have tried everything and sometimes the temper tantrum is still a 10 on the baby rage scale. We have a solution because it has happened once or twice... Co-Baby Massage.`,
          `Yes, you guessed it Massage with your baby! (This isn't a baby Massage this is still YOU time)`,
        ]
      },
      {
        type: 'h3', 
        content: 'WHAT DOES THIS LOOK LIKE?',
      }, 
      {
        type: 'p_arr',
        content: [
          `We typically will have you lay on your side facing your baby with both of you tucked under our super soft sheets.`,
          `Usually, this is the best position for a sassy babe who just wants his mama. In this position, you can easily breastfeed and have a little snuggle while still experiencing your amazing Massage.`,
          `The best part? We don't care or judge! You aren't disturbing another person getting a Massage in a room nearby and we have seen it/heard it all. Our main focus is on you mama and making sure this can be as seamless as possible for you.`,
        ]
      },
      {
        type: 'h3',
        content: 'DROP YOUR EXCUSES MAMA',
      },
      {
        type: 'p_arr',
        content: [
          'Can we remember for just a minute that your body recently housed a little human? Ever since that day you have been carrying around this little bundle of weighted joy all day in some pretty awkward carrying positions... all while being slightly sleep deprived. (There is a reason your back is so darn sore!)',
          'Your body has been through labour and back. You now rarely have time for yourself if any at all.',
          'We want to give you just a piece of that time back. We want you to feel just a little bit better. We think you are doing an amazing job and please know that taking time for yourself is not selfish. By refilling your own cup, you will have more water to pour into the cups of those you love. So take 60 or 90 minutes and book your Massage, this is you permission slip.'
        ]
      }

    ]
  },
  {
    id: 7,
    date_created: new Date('JULY 11, 2018'),
    img: blog7,
    title: 'Mama Mobile Massage featured on Rogers TV Ottawa',
    type: 'Media',
    author: 'Sarah',
    blogPost: [
      {
        type: 'video',
        content: 'https://www.youtube.com/embed/4nHOpmOjibI',
      }
    ]
  },
  {
    id: 6,
    date_created: new Date('JUNE 11, 2018'),
    img: blog6,
    title: 'Juniorgrownup.com',
    type: 'Mama Story',
    author: 'Juniorgrownup.com',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'I tend to think that when something seems too good to be true, it probably is.',
          'That ends now, because Mama Mobile Massage exists and it seemed too good to be true, and was, in fact, even better than I’d expected.',
          'MMM was founded by Sarah Rennick, another local boss babe, as a way to reach out to the pre and post-natal clients she came to know and love through her work at massage clinics. A Registered Massage Therapist, Sarah sought to create a service to more easily meet the massage therapy needs of busy moms (and dads).',
          'Here’s the deal: she comes to your house and gives you a massage. She has the gizmos to make massage therapy work for clients who are pregnant (even the blessed preggo-pillow with the big hole in it that lets you lie on your stomach after months of not being able to do so), and cushions to caress and support engorged post-natal breasts.',
          'She lugs all of this stuff into your home. You don’t have to leave the house.',
          'Among the many things I love about not leaving the house for a massage are:',
        ]
      },
      {
        type: 'li',
        content: [
          '- Just not leaving the house. That’s it. That is wonderful in and of itself',
          '- Not having to carve out a special time. Few RMT’s (that I know of) offer late evening appointments. My little guy nurses before bed, so I am not able to leave the house until 7pm, and that puts me out of the running for even the later appointments at clinics.',
          '- Not having to use any weekend time, because apparently now I am a full-time weekend birthday party attendee (along with my son)',
          '- Not having to find/pay for parking',
          '- Wearing my pyjamas, in my own living room, before and after the massage',
          '- Being massaged in front of a fireplace',
        ]
      },
      {
        type: 'p_arr',
        content: [
          '- Walking away from the massage table and directly into my bed (holler, new sheets!)',
          'As far as the massage itself, I was not disappointed.',
          'Sarah came to our house at 7:15pm. She greeted my dogs, set up her table quickly and effortlessly, put on some lovely music (instrumental covers of pop songs), and was ready to rock.',
          'My husband and I had booked “Mama and Papa massages” (cutest). We fought in front of Sarah about who would go first (awkward, sorry, Sarah). I ended up going first.',
          'I was, for maybe the first time in my life(?) relatively low-maintenance as far as Sarah’s clients go (my words, not hers). I didn’t need special pillows. But I desperately needed a massage. I’ve suffered from shoulder pain since I was a teenager, and Sarah helped relieve a lot of the pain that had been building up lately.',
          'She also recommended some stretches (that I probably won’t do) to help decrease my shoulder pain, and suggested I use a heat pack once a day. This, I am definitely doing because it involves warmth and watching tv.',
          'As an RMT, her service will be covered in part by my health insurance, which is a huge bonus for me. In addition to the huge bonus of, say it with me now, “NOT — LEAVING — THE — HOUSE!”',
        ]
      }
    ]
  },
  {
    id: 5,
    date_created: new Date('JUNE 11, 2018'),
    img: blog5,
    title: 'TalesofMommyhood.com',
    type: 'Mama Story',
    author: 'Ashley',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'As a mom it can be hard to find time for yourself. I am always trying to take care of things for others (the kids, hubby, the house, etc) and I put off taking care of me. I discovered massage therapy in high school after a car accident. When my treatment period was over, I continued going for relaxation massages once a month for a couple of years.  As time passed, we bought our house and money for that type of thing was rare, so I eventually gave it up.',
          'It has taken me a long time to realize that I need some time for me. Making sure I am taken care of helps me have the energy to take care of my family.  Having that time to relax and unwind and let the stress melt away from my body is something that I need to prioritize.',
          `When I learned about Mama Mobile Massage Therapy, I knew that I needed to give it a try.  Sarah Rennick, RMT, focuses on pregnant women and busy moms.  An RMT with doula certification, she has the knowledge for pre-natal and post-natal care.  She also realizes that it's not always easy to fit in a visit to a clinic for a treatment, so she comes to you. Arranging babysitters isn't always possible - how about a massage while the kids are napping?  Sounds good to me. I wish Mama Mobile had been around when my kids were small - I may have gone back to massages sooner.`,
          'I made my appointment last week, and Mr. J ended up being home from school with a sore tummy the day of. No problem since Mama Mobile comes to the house. If I had an appointment in a clinic, I would have had to cancel.  With being done in home, Mr. J was able to lay cuddled up in front of the tv upstairs while I had my massage in the living room.',
        ]
      }, 
      {
        type: 'h3',
        content: 'What To Expect',
      }, 
      {
        type: 'p_arr',
        content: [
          'The day before treatment you will receive an intake form by email. You will provide all the necessary information to help Sarah provide the best treatment for you.  You will fill in your name, address and phone number. There are areas on the form to specify any injuries you may have suffered; health issues you have; family history and specific areas of concern.',
          'The day of treatment, Sarah will arrive with her folding table and set up is very quick. She will ask any questions she has about your intake form and injuries. Once the table is set up and covered with sheets, she will excuse herself to wash her hands while you have the chance to lie on the table and cover up.',
        ]
      }, 
      {
        type: 'h3',
        content: 'Let the relaxation begin'
      },
      {
        type: 'p',
        content: `You may think you are missing out by not going to a massage clinic, but you aren't. Soft music, scented oils, and Sarah's gentle but firm touch will have you relaxing in minutes. It has been almost 10 years since my last massage and I had forgotten how wonderful they are. It didn't take long for my mind to clear.  When the massage was over, I was shocked that 60 minutes had gone by. I honestly didn't realize how tense my shoulders were until AFTER the massage was over and they felt so loose. My whole body felt great.`,
      }, 
      {
        type: 'h3',
        content: 'Services offered',
      }, 
      {
        type: 'p_arr',
        content: [
          `Pre-natal, post-natal and therapeutic massage appointments are available for either 60 or 90 minutes. Fee may be paid by cash, cheque, debit or credit at the time of appointment. Insurance receipts are available and will be emailed within 24 hours of your appointment. Fee's are listed on the website. HST is extra.`,
          `At this time, Mama Mobile Massage offers services to The Glebe, Lansdowne, Centertown and Old Ottawa East areas of the city. Appointments are also available in Brockville twice monthly.`,
          `Now that you know all about this great service, what are you waiting for? Check out the Mama Mobile facebook page and website to book an appointment -  make sure to tell her I sent you :)`,
        ]
      }
    ]
  },
  {
    id: 4,
    date_created: new Date('MAY 22, 2018'),
    img: blog4,
    title: 'Is Prenatal Massage Safe',
    type: 'Educate',
    author: 'Sarah',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          `It is no secret that pregnancy is a delicate time for most women. With a laundry list of things to avoid/caution women often wonder if Massage during pregnancy is safe.`,
          `The short answer is YES... given that you are seeing a Registered Massage Therapist. (RMT)`,
          `In Ontario Massage Therapy is considered a regulated profession. This means that all Massage Therapists have taken and passed a college program along with a provincial licensing exam to obtain their title.`,
          `Part of the course curriculum when becoming an RMT is prenatal Massage therefore all with this title are competent in prental Massage. Those with special interest will take additional courses to further their expertise in the area.`,
          `At Mama Mobile we only hire Massage Therapists who have expressed a passion for prenatal Massage and we provide them with constant education to ensure they are feeling confident in their skills. Our advice is to visit a clinic that specializes in prenatal Massage or an RMT that lists this as a special interest in his/her biography on the website you visit.`,
          `Here are some things to note when you are having your first prenatal Massage that may be different than your usual session.`,
        ]
      },
      {
        type: 'h3',
        content: 'POSTIONING',
      }, 
      {
        type: 'p_arr',
        content: [
          'With a growing belly and advice from ones doctor not to lie on your baby bump, many women have the misconception that they will not be able to receive a Massage during their pregnancy.',
          'With the help of research and innovation, there are a few solutions to ensure you receive a safe and effective Massage during every stage of your pregnancy.',
        ]
      },
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555821537/blog/blog_4_1.png',
      },
      {
        type: 'h3',
        content: 'WHAT ARE PREGNANCY MASSAGE PILLOWS?'
      },
      {
        type: 'p_arr',
        content: [
          `There are in fact a series of special pillows that exist which allow you to safely lay facedown on the Massage table with your bump! As long as you are working with a Registered Massage Therapist and have a pillow which completely elevates/supports your belly in most cases you will be safe. Our two-part pillowing system is contoured to fit around your entire belly.`,
          `The bottom pillow contours around your hips/pelvis and lower belly, this completely supports delicate ligaments and sensitive areas. The top pillow is carved out to support tender breasts and contoured around the top of the belly completely elevating it from the table. Both of these pillows work together to support the mama to be on the Massage table. The beauty of having two separate pillows is that they can be moved closer together/apart to accommodate the size of the belly. The main thing to note is that the belly never touches the table, it is cradled in between the pillows creating a safe nest to support your baby.`,
        ]
      }, 
      {
        type: 'h3',
        content: '3 Important Things To Note',
      },
      {
        type: 'p_arr',
        content: [
          `1- Your belly should never touch the table. Gently reach your hand under your belly once in the pillowing system, if you feel no contact with the table you are most likely in a safe position.`,
          `2- You feel supported. You do not feel uncomfortable or unsafe in your position. You are able to fully breathe/relax.`,
          `3- You do not stay on your belly for the whole Massage. It is not recommended to stay in this position for a full hour. Part of the Massage should be performed in another position.`,
        ]
      }, 
      {
        type: 'img',
        content: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1555821810/blog/blog_4_2.png',
      },
      {
        type: 'h3',
        content: 'WILL ANYTHING ELSE BE DIFFERENT?'
      },
      {
        type: 'p_bold',
        content: 'PRESSURE',
      },
      {
        type: 'p',
        content: 'During your prenatal Massage, the amount of pressure applied will be slightly less than what you could ask for during a regular Massage. I always like to compare a deep tissue Massage to a workout. Your body needs adequate rest and time to recover, it is not recommended during pregnancy to do any vigorous activities as the body is already working overtime creating your little human. Less pressure does not mean that the Massage will be less effective! Your body just needs a more gentle approach during this time. ',
      },
      {
        type: 'p_bold',
        content: 'FREQUENCY',
      }, 
      {
        type: 'p_arr',
        content: [
          `We recommend Massage every 3-4 weeks throughout your pregnancy. When a normal client without injury works with us we usually suggest every 4-6 weeks.`,
          `We increase the frequency during pregnancy to accommodate the constant changes that your body is undergoing. We are not able to be as aggressive in our approach with deep pressure, stretching recommendations etc. This means that having lighter Massages more frequently are more effective in this case. Studies also show that frequent Massages during pregnancy makes for an easier labor, delivery and alleviates some of the common physical/emotional symptoms of pregnancy. (More on this later)`,
        ]
      }, 
      {
        type: 'p_bold',
        content: 'BLOOD PRESSURE',
      }, 
      {
        type: 'p',
        content: 'During any Massage it is very normal for your blood pressure to drop, this is actually a major plus and a great sign that your body is feeling relaxed! In a Prenatal Massage the shift in blood pressure can happen more suddenly and it is reccomended to practice caution when getting up after your Massage is finished. (If you feel an unusual amount of dizziness contact your doctor)',
      },
      {
        type: 'p_bold',
        content: 'TECHNIQUE',
      }, 
      {
        type: 'p_arr',
        content: [
          'Unlike your last Massage when you were not yet pregnant part of your treatment may be focused on reducing swelling of the hands/feet. Massage for swelling involves very light pressure to move the edema and increase circulation. Trust your Massage Therapist to perform what is best for your body during this time.',
          'We hope this debunked some of the myths and motivated you to get the Massage you deserve!',
        ]
      }
    ]
  },
  {
    id: 3,
    date_created: new Date('MAY 14, 2018'),
    img: blog3,
    title: "Mama's Mindful Minutes",
    type: 'Educate',
    author: 'Sarah',
    blogPost: [
      {
        type: 'p_arr',
        content: [
          'With to do lists, a constant influx of emails/text messages and managing small humans it is hard to imagine life without stress. We are all waiting for the weekend or a vacation to have a little more time to give back to ourselves again. For most people it is only after a break from their day to day lives that they feel rejuvenated again.',
          'Moms are in a unique situation where they not only have the crushing pressure of their personal lives but they also have the responsibility of keeping their children alive. (You got this!) So much time and energy is spent in a selfless flow of making sure those around them are sound that suddenly personal time is no longer a priority but now a mere indulgence.',
        ]
      },
      {
        type: 'h3', 
        content: 'Why Meditate?'
      },
      {
        type: 'p_arr',
        content: [
          'When we begin to move from one task to the next without a chance to gather our thoughts we often begin to feel a little big foggy. This can make us forgetful, irritated and frustrated at how difficult it is to find extra time in the day to focus on ourselves. What you may feel now as overwhelm has a funny way of leading to a more general feeling of stress if left unattended.',
          'The easiest way to reverse this is through mediation. Is your mind already racing? I get it...  you don’t the time to head over to lululmemon to grab the latest meditation outfit and the perfect pillow to sit on. SLOW DOWN. All you need is 5 minutes and your beautiful self.',
          'Meditation is like a shower clearing away all negative thoughts and emotions and giving you a refocused perspective. There is a big shift you will notice in your mental state over time. It feels as thought you move from a state of existance (going through motions) to observation (feeling moments as they are happening) This concept is called mindfulness and is one of the best outcomes of mediataion.',
          `We already have established that time isn’t on your side but luckily meditation has the ability to clear the fog you have been existing under. Through this daily practice in you will begin to notice life losing it's sense of urgency. You will begin to approach situations from a calmer state allowing you to feel more in control of outcomes. You will even see little loopholes of time for YOU that you couldn't when you were trapt under the crushing thoughts of your mind.`,
          `Are you excited yet? Let’s start!`
        ]
      }, 
      {
        type: 'h3',
        content: 'The How To:',
      }, 
      {
        type: 'p_arr',
        content: [
          `Set a timer for 5 minutes (You can increase this over time)`,
          `Pull up a pillow (whatever damn pillow you can find)`,
          `Close your eyes and begin to breath`,
          `Let all the thoughts go from your mind and focus on the feeling of the breath going in expanding your chest and out, freeing your body.`,
          `Try counting your breaths (In- 1, out-2 etc up to 10)`,
          `Every time your mind wanders bring it back to the breath`,
          `.... That’s it! You just freaking meditated.`,
          `Let us know how becoming more mindful improves your life. Test out other methods of meditation and find what works best for you!`,
        ]
      }
    ]
  },
  // {
  //   id: 1,
  //   date_created: new Date('MARCH 30, 2018'),
  //   img: blog1,
  //   title: "Why You Should Become Your Baby's RMT!",
  //   type: 'Educate',
  //   author: 'Sarah',
  //   blogPost: [
  //     {
  //       type: 'p_arr',
  //       content: [
  //         `Raise your hand if you have ever fallen asleep during a massage! Most people correlate massage with deep relaxation, and an A+ nap is usually the collateral from this. Have you ever noticed that the night after having a massage you experience a more restful sleep? This isn’t a coincidence!`,
  //       ]
  //     }, 
  //     {
  //       type: 'img',
  //       content: blog1,
  //     },
  //     {
  //       type: 'h3',
  //       content: 'Some Fast Facts on Massage for Relaxation',
  //     }, 
  //     {
  //       type: 'li',
  //       content: [
  //         '1. Lowers cortisol levels (stress hormones!)',
  //         '2. Provides relief of muscle tension (we often hold stress in our bodies)',
  //       ]
  //     },
  //     {
  //       type: 'p_arr',
  //       content: [
  //         '3. Studies show Massage decreases symptoms of depression/anxiety.',
  //         'A better night’s sleep is bound to happen when all of theses benefits are occurring, but did you know your little one may benefit too?',
  //       ]
  //     },
  //     {
  //       type: 'h3',
  //       content: 'Benefits of Infant Massage',
  //     },
  //     {
  //       type: 'li',
  //       content: [
  //         '1. Promotes a more restful sleep',
  //         '2. Decreases colic/constipation (a less fussy baby is always more relaxed!)',
  //         '3. Decreases muscle pain (baby is growing fast)'
  //       ]
  //     },
  //     {
  //       type: 'p_arr',
  //       content: [
  //         '4. Increases bonding between parents and baby',
  //         'I suggest adding massage into your baby’s nighttime routine. Try setting this up after a bath when you are applying lotion to the little one. Make sure you are in a warm environment and that you are not performing directly after a feeding.',
  //         'Any essential oil will work wonderfully, in this sleep specific Massage we suggest adding a drop of high quality lavender essential oil into coconut oil. Lavender claims to help promote further relaxation and the smell can now be associated with Massage for your baby. We have now heightened the senses of touch and smell!',
  //         'Look for cues from your baby (cooing, smiling etc.) to see what she likes:',
  //         'Begin by rubbing some lotion in your hands above baby as though you are in the old days trying to start a fire. Ask him if he is ready for his Massage and look for the visual cues mentioned above. Baby will be laying on his back for this routine. Start at the feet and work your way up, repeat on the other side of the body. Perform each technique 3-5 times before moving on to the next body part.',
  //         'Feet: Gently draw little circles with your thumb from the heel to the toes',
  //         'Legs: Start on the outer ankle and with a flat hand stroke all the way up to the bum',
  //         'Bum: With your palm draw larger circles along the side of the bum',
  //         'Belly: With the pinky edge of your hand starting at the lower ribs gently swipe hands one after the other towards the hips.',
  //         'The above are a few techniques to get your started. Remember to be patient… it may take a few weeks before baby starts enjoying this time; be consistent and stop when baby starts to fuss. As your little one begins to recognize the routine and enjoy her massage, you will have found yet another way to bond with your little one at bedtime!',
  //       ]
  //     }
  //   ]
  // },
  {
    id: 0,
    date_created: new Date('MARCH 9, 2018'),
    img: blog0,
    title: "4 Easy Ways To Self Care",
    type: 'Educate',
    author: 'Sarah',
    blogPost: [
      {
        type: 'img',
        content: blog0,
      }, 
      {
        type: 'p_arr',
        content: [
          `I get it; your days of sleeping in and lazy Sundays without pants are behind you. Sometimes it may feel that your “you” time came out of your body during labour. Yes, in some ways it did. You now have another human requiring a large amount of attention thrown into your everyday mix of life. The problem is that you were not given the allotted number of extra hours in the day to accommodate the change. The time you used to spend doing things for yourself was probably the first thing you dropped out of your routine when things began to get hectic. Congratulations, putting others in front of yourself is the unspoken hazing of mommy hood… you did it!`,
          `The problem with constantly spending our energy on others is that we put ourselves at risk to burn out. Some days you may feel invincible and that you’re an exception to this rule, but your energy will begin to drain when you least expect it. The best way to protect yourself is to practice “self care”. This doesn’t need to be an elaborate day of yoga and brunch like it once was… heck it could be only 5 minutes. Here are some 4 easy ways to self care so you can recharge. You can sneak them in during a nap time, after bed time, or when you are offered some help:`,
        ]
      },
      {
        type: 'h3',
        content: 'MAMA BATH TIME',
      }, 
      {
        type: 'p_arr',
        content: [
          'Time: 20 minutes',
          'Take the rubber ducky off the side of the tub and replace it with a few candles. Fill up the tub with 2 cups of Epsom salts, these can be purchased at your local drugstore. Epsom salts help relax your muscles and detox your body a serious must have in any bath routine!',
          'Try adding a few drops of lavender essential oil to up the luxe factor. Enjoy this soak for 20 minutes.',
        ]
      },
      {
        type: 'h3',
        content: 'MAMA MEDITATION',
      },
      {
        type: 'p_arr',
        content: [
          'Time: 5 minutes',
          'Meditation can often seem like a daunting task with a rotating to-do list replaying in your mind. If you can hone this skill it can help with memory, overall mood and surviving even the most stressful of situations.',
          'I love the app “Headspace” this is completely free and times your sessions into 5-minute blocks. The more often you do this the greater it will benefit you.',
        ]
      },
      {
        type: 'h3',
        content: 'MAMA MOVES',
      },
      {
        type: 'p_arr',
        content: [
          'Time: 15 minutes',
          'I get it; you don’t want to spend the precious amount of energy you DO have working out. Heck, how are you even going to make it to the gym? Although you are physically expending energy during a workout, studies have proven that it can help manage both physical and emotional stress, giving you more energy in the long run.',
          'We are lucky to have endless access to free workout routines online, taking out the need to leave your house. Set your stopwatch for 15 minutes and move it! By shortening the time of your workouts, the task will seem less overwhelming and before you know it part of your daily routine. You might even have fun!',
        ]
      },
      {
        type: 'h3',
        content: 'MAMA MOBILE MASSAGE',
      },
      {
        type: 'p_arr',
        content: [
          'Time: 60-90 minutes',
          'Massage can loosen up tight muscles, lower stress levels, increase circulation and ease the aches/pains of mommy hood. Finding time to consistently make it to a clinic or spa for a Massage can be a challenge, especially in those moments where you really needed. Mama Mobile Massage was created for busy mamas just like yourself; we come to you! Expect everything you would experience in a registered massage therapy treatment, right in your living room. Some of our clients book during their baby’s nap time or after bed time, and others enjoy their treatment laying on their side with baby cuddling right in. Every treatment includes extra “fussy baby time” so that you always receive your full hour of care.',
          'I hope you take advantage of these tips and incorporate one (or more!) into your everyday routine. Remember, self care is not selfish! It is necessary so that you can continue to be the kickass mama that we all love.',
        ]
      }
    ]
  },
];

export default blogData;