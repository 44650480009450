import React from 'react';
import './BabyMassageVideo.css';
const ebookImg = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588813405/teething_pdf.png';


const BabyMassageFreeVideo = () => (
  <div className='page-component baby-massage-video'>
    <h1>Massage For Your Teething Baby</h1>
    <iframe src="https://player.vimeo.com/video/409027804" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    <div id='learning-overview'>
      <div className='learning-section'>
        <h4>Teething Relief Massage</h4>
        <p>
          Teething is something every baby will struggle with at some point in time. The techniques in this routine will help to ease the associated pain empowering you with the tools to help your baby feel his best.
        </p>
      </div>
      <div className='learning-section'>
        <h4>Instructor</h4>
        <div className='instructor-bio-body'>
          <img src='https://lh5.googleusercontent.com/ZaeIeeKLspzHHvFM5p2O0K6RU2KjoMa8m4IcFcJ8DZzNOfbgPH5ft4BrO0F8efCRyYyjO3EvRdwN5cLEf4OPsWA2-yoNfC1AkJhyT6S1AwkfFY-Ei9CJuu_xZy8sSniTBDJzr1iy' alt='Sarah Rennick - RMT'/>
          <div>
            <p className='instructor-bio-name'><b>Sarah Rennick</b></p>
            <p className='instructor-bio-description'>Master Infant Massage Instructor</p>
            <p className='instructor-bio-description'>Registered Massage Therapist</p>
            <p className='instructor-bio-description'>Co-founder Mama Mobile</p>
          </div>
        </div>
      </div>
      <div className='learning-section ebook'>
        <a target="_blank" href="https://firebasestorage.googleapis.com/v0/b/mama-mobile-massage.appspot.com/o/Teething%20Relief%20Massage%20PDF.pdf?alt=media&token=3f37dd5a-fcad-46b8-9b81-338f9966226d" download='Baby Massage - Ebook'>
          <h4>Teething Relief Massage Routine PDF</h4>
          <img src={ebookImg} alt='teething massage routine ebook'/>
          <p><b>Download the Teething Relief Massage Routine PDF</b></p>
        </a>
      </div>
    </div>
  </div>
);

export default BabyMassageFreeVideo;