import React from 'react';

const HowItWorksCard = ({ card }) => {
  const { image, alt, number, title, opposite } = card;
  return (
    <div className={opposite ? 'how-it-works-card opposite' : 'how-it-works-card'}>
      <img src={image} alt={alt} />
      <div className='how-it-works-card-content'>
        <div className='how-it-works-step'>{number}</div>
        <h3>{title}</h3>
      </div>
    </div>
  )
}

export default HowItWorksCard;