import React from 'react';
import BlogCard from '../../pages/Blog/BlogCard';

const Blog = ({ blogs }) => {
  return (
    <div className='landing-component blogs'>
      <h2 className='landing-title'>Blogs</h2>
      <div className='blogs-wrapper'>
        {
          blogs.map((blog, idx) => (
            <BlogCard
              key={idx}
              title={blog.title}
              type={blog.type}
              image={blog.image}
            />
          ))
        }
      </div>
    </div>
  )
}

export default Blog;