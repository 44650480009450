import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CourseOverview from './CourseOverview';
import Overview from './Overview';
import QandA from './QandA';

const LearningTabs = ({ video, course }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        variant="fullWidth"
        aria-label="disabled tabs example"
      >
        <Tab label="Overview" />
        <Tab label="Q&A" />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <div
          role="tabpanel"
          hidden={value !== 0}
          id='full-width-tabpanel-0'
          aria-labelledby='full-width-tab-0'
        >
          {value === 0 && (
            course ? <CourseOverview video={video} /> : <Overview />
          )}
        </div>
        <div
          role="tabpanel"
          hidden={value !== 1}
          id='full-width-tabpanel-1'
          aria-labelledby='full-width-tab-1'
        >
          {value === 1 && (
            <QandA course={video} />
          )}
        </div>
      </SwipeableViews>
    </div>
  )
};

export default LearningTabs;