const allWorkshops = {
  'banish-mom-guilt': {
    id: 'banish-mom-guilt',
    title: `Online Therapist-led Workshop: 3 Ways To Stop Mom Guilt`,
    instructor: `Michelle Winterburn, MSW, RSW, Alli therapist`,
    date: '2021-06-05',
    startTime: '2021-06-05T11:00:00-04:00',
    endTime: '2021-06-05T12:00:00-04:00',
    time: '11:00am to 12:00pm',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1617402657/alli/landing/PHOTO_FORF_COURSE_dqin6v.png',
    alt: `Alli Therapy Workshop: 3 Ways To Stop Mom Guilt`,
    shortDescription: [
      `In this 45-minute licensed therapist-led workshop you will uncover why you feel mom guilt and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
    ],
    longDescription: [
      `In this licensed therapist-led workshop you will uncover why you feel it and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
      `- Understand why you feel mom guilt`,
      `- Learn 3 tangible tips to stop mom guilt in it's tracks and prevent it from happening`,
      `- Meet other moms in a similar stage of motherhood for a chat!`
    ],
  },
  // 'baby_massage101_2020-10-28': {
  //   id: 'baby_massage101_2020-10-28',
  //   title: 'Baby Massage 101 | A free online lesson ',
  //   instructor: 'Sarah Rennick, baby massage instructor & founder of Mama Mobile',
  //   date: '2020-10-28',
  //   startTime: '2020-10-28T10:00:00-04:00',
  //   endTime: '2020-10-28T10:30:00-04:00',
  //   time: '10:00am to 10:30am',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600714501/Screen_Shot_2020-09-21_at_11.54.36_AM_lk0iwq.png',
  //   alt: 'Baby massage workshop',
  //   shortDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  //   longDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  // },
  // 'postnatal_pelvic101_2020-10-01': {
  //   id: 'postnatal_pelvic101_2020-10-01',
  //   title: 'What should I know about my pelvic floor postpartum? | A free online workshop ',
  //   instructor: 'Ellie Hong, pelvic floor and orthopedic physiotherapist',
  //   date: '2020-10-01',
  //   startTime: '2020-10-01T20:00:00-04:00',
  //   endTime: '2020-10-01T20:30:00-04:00',
  //   time: '8:00pm to 8:30pm',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600721448/Screen_Shot_2020-09-21_at_1.50.30_PM_u8lwbr.png',
  //   alt: 'postnatal pelvic floor physiotherapy workshop',
  //   shortDescription: [
  //     'Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.',
  //   ],
  //   longDescription: [
  //     `Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.`,
  //     `What is my pelvic floor?`,
  //     `How can I assess things... down there?`,
  //     `How can pelvic floor physio prevent and issues related to sexual function, my bladder, bowel issues, and more?`,
  //   ]
  // },
  // 'prenatal_pelvic101_2020-10-05': {
  //   id: 'prenatal_pelvic101_2020-10-05',
  //   title: 'What should I know about my pelvic floor during pregnancy? | A free online workshop',
  //   instructor: 'Sabrina Ait-Ouali, pelvic floor and orthopedic physiotherapist',
  //   date: '2020-10-05',
  //   time: '7:00pm to 7:30pm',
  //   startTime: '2020-10-05T19:00:00-04:00',
  //   endTime: '2020-10-05T19:30:00-04:00',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600717229/Screen_Shot_2020-09-21_at_12.39.04_PM_g2dgtk.png',
  //   alt: 'prenatal pelvic floor physiotherapy workshop',
  //   shortDescription: [
  //     'We will be chatting through all the changes your body is experiencing during pregnancy. Learn how a pelvic floor physiotherapist can make make your upcoming labor easier on your body and set you up for a smooth recovery.',
  //   ],
  //   longDescription: [
  //     `We will be chatting through all the changes your body is experiencing during pregnancy. Learn how a pelvic floor physiotherapist can make make your upcoming labor easier on your body and set you up for a smooth recovery.`,
  //     `How can I prevent vaginal tearing during labor?`,
  //     `How do I assess things down there?`,
  //     `How do I prepare for c-section recovery?`,
  //     `How can I prevent future sexual, bladder, and bowel issues?`,
  //   ]
  // },
};

const prenatalWorkshops = {
  // 'baby_massage101_2020-10-28': {
  //   id: 'baby_massage101_2020-10-28',
  //   title: 'Baby Massage 101 | A free online lesson ',
  //   instructor: 'Sarah Rennick, baby massage instructor & founder of Mama Mobile',
  //   date: '2020-10-28',
  //   startTime: '2020-10-28T10:00:00-04:00',
  //   endTime: '2020-10-28T10:30:00-04:00',
  //   time: '10:00am to 10:30am',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600714501/Screen_Shot_2020-09-21_at_11.54.36_AM_lk0iwq.png',
  //   alt: 'Baby massage workshop',
  //   shortDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  //   longDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  // },
  'banish-mom-guilt': {
    id: 'banish-mom-guilt',
    title: `Online Therapist-led Workshop: 3 Ways To Stop Mom Guilt`,
    instructor: `Michelle Winterburn, MSW, RSW, Alli therapist`,
    date: '2021-04-21',
    startTime: '2021-04-21T20:00:00-04:00',
    endTime: '2021-04-21T21:00:00-04:00',
    time: '8:00pm to 9:00pm',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1617402657/alli/landing/PHOTO_FORF_COURSE_dqin6v.png',
    alt: `Alli Therapy Workshop: 3 Ways To Stop Mom Guilt`,
    shortDescription: [
      `In this 45-minute licensed therapist-led workshop you will uncover why you feel mom guilt and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
    ],
    longDescription: [
      `In this licensed therapist-led workshop you will uncover why you feel it and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
      `- Understand why you feel mom guilt`,
      `- Learn 3 tangible tips to stop mom guilt in it's tracks and prevent it from happening`,
      `- Meet other moms in a similar stage of motherhood for a chat!`
    ],
  },
}

const postnatalWorkshops = {
  // 'baby_massage101_2020-10-28': {
  //   id: 'baby_massage101_2020-10-28',
  //   title: 'Baby Massage 101 | A free online lesson ',
  //   instructor: 'Sarah Rennick, baby massage instructor & founder of Mama Mobile',
  //   date: '2020-10-28',
  //   startTime: '2020-10-28T10:00:00-04:00',
  //   endTime: '2020-10-28T10:30:00-04:00',
  //   time: '10:00am to 10:30am',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600714501/Screen_Shot_2020-09-21_at_11.54.36_AM_lk0iwq.png',
  //   alt: 'Baby massage workshop',
  //   shortDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  //   longDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
// }
    'banish-mom-guilt': {
      id: 'banish-mom-guilt',
      title: `Online Therapist-led Workshop: 3 Ways To Stop Mom Guilt`,
      instructor: `Michelle Winterburn, MSW, RSW, Alli therapist`,
      date: '2021-04-21',
      startTime: '2021-04-21T20:00:00-04:00',
      endTime: '2021-04-21T21:00:00-04:00',
      time: '8:00pm to 9:00pm',
      img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1617402657/alli/landing/PHOTO_FORF_COURSE_dqin6v.png',
      alt: `Alli Therapy Workshop: 3 Ways To Stop Mom Guilt`,
      shortDescription: [
        `In this 45-minute licensed therapist-led workshop you will uncover why you feel mom guilt and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
      ],
      longDescription: [
        `In this licensed therapist-led workshop you will uncover why you feel it and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
        `- Understand why you feel mom guilt`,
        `- Learn 3 tangible tips to stop mom guilt in it's tracks and prevent it from happening`,
        `- Meet other moms in a similar stage of motherhood for a chat!`
      ],
    },
  // 'postnatal_pelvic101_2020-10-01': {
  //   id: 'postnatal_pelvic101_2020-10-01',
  //   title: 'What should I know about my pelvic floor postpartum? | A free online workshop ',
  //   instructor: 'Ellie Hong, pelvic floor and orthopedic physiotherapist',
  //   date: '2020-10-01',
  //   startTime: '2020-10-01T20:00:00-04:00',
  //   endTime: '2020-10-01T20:30:00-04:00',
  //   time: '8:00pm to 8:30pm',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600721448/Screen_Shot_2020-09-21_at_1.50.30_PM_u8lwbr.png',
  //   alt: 'postnatal pelvic floor physiotherapy workshop',
  //   shortDescription: [
  //     'Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.',
  //   ],
  //   longDescription: [
  //     `Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.`,
  //     `What is my pelvic floor?`,
  //     `How can I assess things... down there?`,
  //     `How can pelvic floor physio prevent and issues related to sexual function, my bladder, bowel issues, and more?`,
  //   ]
  // },
};

const busymamaWorkshops = {
  // 'baby_massage101_2020-10-28': {
  //   id: 'baby_massage101_2020-10-28',
  //   title: 'Baby Massage 101 | A free online lesson ',
  //   instructor: 'Sarah Rennick, baby massage instructor & founder of Mama Mobile',
  //   date: '2020-10-28',
  //   startTime: '2020-10-28T10:00:00-04:00',
  //   endTime: '2020-10-28T10:30:00-04:00',
  //   time: '10:00am to 10:30am',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600714501/Screen_Shot_2020-09-21_at_11.54.36_AM_lk0iwq.png',
  //   alt: 'Baby massage workshop',
  //   shortDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  //   longDescription: [
  //     `BYOB (Bring your own baby) - In this group class you will learn massage techniques that will empower you to help baby through some of the things babies struggle with most:`,
  //     `Baby massage for constipation`,
  //     `Baby massage techniques to help improve my baby’s sleep`,
  //     `Baby massage to help with teething pain`,
  //   ],
  'banish-mom-guilt': {
    id: 'banish-mom-guilt',
    title: `Online Therapist-led Workshop: 3 Ways To Stop Mom Guilt`,
    instructor: `Michelle Winterburn, MSW, RSW, Alli therapist`,
    date: '2021-04-21',
    startTime: '2021-04-21T20:00:00-04:00',
    endTime: '2021-04-21T21:00:00-04:00',
    time: '8:00pm to 9:00pm',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1617402657/alli/landing/PHOTO_FORF_COURSE_dqin6v.png',
    alt: `Alli Therapy Workshop: 3 Ways To Stop Mom Guilt`,
    shortDescription: [
      `In this 45-minute licensed therapist-led workshop you will uncover why you feel mom guilt and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
    ],
    longDescription: [
      `In this licensed therapist-led workshop you will uncover why you feel it and walk away with 3 tips to release it once and for all while connecting with other moms who are feeling the same way.`,
      `- Understand why you feel mom guilt`,
      `- Learn 3 tangible tips to stop mom guilt in it's tracks and prevent it from happening`,
      `- Meet other moms in a similar stage of motherhood for a chat!`
    ],
  },
  // 'postnatal_pelvic101_2020-10-01': {
  //   id: 'postnatal_pelvic101_2020-10-01',
  //   title: 'What should I know about my pelvic floor postpartum? | A free online workshop ',
  //   instructor: 'Ellie Hong, pelvic floor and orthopedic physiotherapist',
  //   date: '2020-10-01',
  //   startTime: '2020-10-01T20:00:00-04:00',
  //   endTime: '2020-10-01T20:30:00-04:00',
  //   time: '8:00pm to 8:30pm',
  //   img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1600721448/Screen_Shot_2020-09-21_at_1.50.30_PM_u8lwbr.png',
  //   alt: 'postnatal pelvic floor physiotherapy workshop',
  //   shortDescription: [
  //     'Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.',
  //   ],
  //   longDescription: [
  //     `Did you know that in France every woman is assessed by a pelvic floor physiotherapist after giving birth? We will be chatting through all the changes your body is experiencing postpartum… what’s normal, what’s not and how you can use pelvic floor physiotherapy as a tool to fully recover from your pregnancy. It’s so much more than just Kegels.`,
  //     `What is my pelvic floor?`,
  //     `How can I assess things... down there?`,
  //     `How can pelvic floor physio prevent and issues related to sexual function, my bladder, bowel issues, and more?`,
  //   ]
  // },
}

export {
  allWorkshops,
  prenatalWorkshops,
  postnatalWorkshops,
  busymamaWorkshops,
}