import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import './components/LandingComponent/LandingComponent.css';
import Firebase from './Firebase';
import { FirebaseContext, AuthUserContext } from './context';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { InMemoryCache } from 'apollo-cache-inmemory';

// pages
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import theme from './theme.js';

import './components/Landing/Landing.css';
import Routes from './Routes';

const testKey = "pk_test_J2f0eCxkLH21jQEPy2ATpvry00lbzyYxh1";
const liveKey = 'pk_live_7sEpuRtG8DSaZMKuKJpyJVXt00ea8K8vXR';

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject: object => object.id || null,
  }),
  uri: 'https://api.mamamobile.com/graphql',
  // uri: 'http://localhost:4000/graphql'
});

const themeCustomize = createMuiTheme(theme);

const stripePromise = loadStripe(liveKey);
const name = localStorage.getItem('name');
const user = localStorage.getItem('user');
const firstName = localStorage.getItem('firstName');
const id = localStorage.getItem('id');
const stage = localStorage.getItem('stage');

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      name,
      user,
      purchaser: null,
      id,
      firstName,
      stage,
      setState: (key, value) => {
        this.setState({ [key]: value })
      }
    };
    this.firebase = new Firebase();
  }

  render () {
    return (
      <AuthUserContext.Provider value={this.state}>
        <ApolloProvider client={client}>
          <Router>
            <Elements stripe={stripePromise}>
              <FirebaseContext.Provider value={this.firebase}>
                <ThemeProvider theme={themeCustomize}>
                  <div>
                    <Header />
                    <Routes />
                    <Footer />
                  </div>
                </ThemeProvider>
              </FirebaseContext.Provider>
            </Elements>
          </Router>
        </ApolloProvider>
      </AuthUserContext.Provider>
    )
  }
}

export default App;
