import React, { useState, useContext } from 'react';
import { Modal, Button, Icon, Header } from 'semantic-ui-react'
import { AuthUserContext } from '../../context';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  avatar: {
    backgroundColor: 'var(--color-primary-main)',
    cursor: 'pointer',
  },
  modal: {
    backgroundColor: 'none',
  }
};

const AvatarLogout = () => {
  const [open, setOpen] = useState(false);
  const Auth = useContext(AuthUserContext);
  const initial = Auth.name ? Auth.name[0] : '';
  const handleLogout = () => {
    localStorage.clear();
    Auth.setState('user', null);
    Auth.setState('name', null);
  }

  return (
    <Modal 
      trigger={<Avatar onClick={() => setOpen(true)} style={styles.avatar}>{initial}</Avatar>} 
      basic
      size='small'
      open={open}
      onClose={() => setOpen(false)}
    >
      <Header icon='user' content='Would you like to log out of your account?' />
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} basic color='red' inverted>
          <Icon name='remove' /> No
        </Button>
        <Button onClick={handleLogout} color='green' inverted>
          <Icon name='checkmark' /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  )
};

export default AvatarLogout;