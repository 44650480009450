import React, { useState } from 'react';
import './Checkout.css';
import OrderSummary from './OrderSummary';
import Payment from './Payment';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Feedback from '../BabyMassageCourse/Feedback';

const Checkout = () => {
  const [total, setTotal] = useState(68);
  const [promo, setPromo] = useState(0);

  return (
    <div id='checkout'>
      <ScrollToTop />
      <OrderSummary setTotal={setTotal} total={total} promo={promo} setPromo={setPromo} />
      <Payment total={total} promo={promo} />
      <Feedback />
    </div>
  )
};

export default Checkout;