import React from 'react';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import './ThankYou.css';
import { withRouter, Link } from 'react-router-dom';
import { allWorkshops } from '../../util/upcomingWorkshops';
import AddToCal from '../../components/AddToCal/AddToCal';

const ThankYouWorkshop = ({ match }) => {
  let id = match.params.id;
  const { title, date, time, img, alt, instructor, longDescription, shortDescription, startTime, endTime } = allWorkshops[id];
  const event = {
    title,
    description: shortDescription[0],
    startTime,
    endTime,
  };

  return (
    <div className='landing-component-col thankyou'>
      <ScrollToTop />
      <h1>YOU'RE OFFICIALLY IN!</h1>
      <h4>Make sure to check your e-mail (junk mail) for the details of the workshop!</h4>
      <h4>In the mean time, feel free to check out our <Link to='/'>services</Link> or our other <Link to='/upcoming-workshops'>free workshops</Link>!</h4>
      <AddToCal event={event} buttonText='Click me to add workshop to your calendar!' />
    </div>
  )
};

export default withRouter(ThankYouWorkshop);
