import React, { useState, useEffect } from 'react';
import './countdown.css';
import Display from './Display';

const Countdown = () => {
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');

  // Set the date we're counting down to
  const countDownDate = new Date("Apr 28, 2020 00:00:00").getTime();

  useEffect(() => {
    // Update the count down every 1 second
    const timer = setInterval(() => {
  
      // Get today's date and time
      const now = new Date().getTime();
  
      // Find the distance between now and the count down date
      const distance = countDownDate - now;
  
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
  
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  return (
    <div id='countdown'>
      <Display time={days} title='days'/>
      <Display time={hours} title='hours'/>
      <Display time={minutes} title='minutes'/>
      <Display time={seconds} title='seconds'/>
    </div>
  )
}

export default Countdown;