import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ image, title, type }) => {
  return (
    <Link to={`/blog/${title.split(' ').join('-')}`}>
      <div className='blog-card'>
        <img src={image} alt={title} />
        <div className='blog-card-content'>
          <p>{type}</p>
          <h5>{title}</h5>
        </div>
      </div>
    </Link>
  );
}

export default BlogCard;