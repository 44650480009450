import React from 'react';
import FAQ from '../FAQ/FAQ';

const FAQs = ({ faqs }) => {
  return (
    <div className='landing-component faqs'>
      <h2 className='landing-title'>FAQ</h2>
      <div className='faqs-wrapper'>
        {faqs.map((faq, idx) => (
          <FAQ key={idx} question={faq.question} answer={faq.answer} />
        ))}
      </div>
    </div>
  )
}

export default FAQs;