import React from 'react';

const BenefitsCard = ({ card }) => {
  const { title, description, image } = card;
  return (
    <div className='benefits-card'>
      <svg width="40" height="40" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.0627 8.375C41.2052 8.375 36.5431 10.6363 33.5002 14.2096C30.4572 10.6363 25.7952 8.375 20.9377 8.375C12.3393 8.375 5.5835 15.1308 5.5835 23.7292C5.5835 34.2817 15.0752 42.88 29.4522 55.945L33.5002 59.6021L37.5481 55.9171C51.9252 42.88 61.4168 34.2817 61.4168 23.7292C61.4168 15.1308 54.661 8.375 46.0627 8.375ZM33.7793 51.7854L33.5002 52.0646L33.221 51.7854C19.9327 39.7533 11.1668 31.7971 11.1668 23.7292C11.1668 18.1458 15.3543 13.9583 20.9377 13.9583C25.2368 13.9583 29.4243 16.7221 30.9039 20.5467H36.1243C37.576 16.7221 41.7635 13.9583 46.0627 13.9583C51.646 13.9583 55.8335 18.1458 55.8335 23.7292C55.8335 31.7971 47.0677 39.7533 33.7793 51.7854Z" fill="black"/>
      </svg>
      {/* <img src={image} alt={title} /> */}
      <h4>{title}</h4>
      <p>{description}</p>
    </div>
  )
};

export default BenefitsCard;