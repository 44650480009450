import React from 'react';
import PricingCard from '../Card/PricingCard';

const firstVisitPricing = [
  {
    duration: '60 minutes',
    price: '$150',
  }
];

const subsequentPricing = [
  {
    duration: '30 minutes',
    price: '$100',
  },
  {
    duration: '45 minutes',
    price: '$125',
  },
];

const PelvicPricing = ({ pricing, route }) => {
  return (
    <div className='landing-component pricing-wrapper'>
      <h2 className='landing-title'>Pricing</h2>
      <div className='pricing'>
        <div className='pricing-heading'>
          <h4>Initial Assessment & Treatment</h4>
          {pricing.firstDescription.map(item => <p>{item}</p>)}
        </div>
        <div className='pricing-cards-wrapper'> 
          {firstVisitPricing.map((item, idx) => <PricingCard route={route} card={item} key={idx} />)}
        </div>
        <div className='pricing-heading'>
          <h4>Subsequent Treatments</h4>
          {pricing.subsequentDescription.map(item => <p>{item}</p>)}
        </div>
        <div className='pricing-cards-wrapper'> 
          {subsequentPricing.map((item, idx) => <PricingCard route={route} card={item} key={idx} />)}
        </div>
      </div>
    </div>
  )
};

export default PelvicPricing;