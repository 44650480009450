import React from 'react';
import Instructor from './Instructor';
import CourseDetails from './CourseDetails';
import CourseBenefits from './CourseBenefits';
import CourseEbook from './CourseEbook';
import instructorData from './instructorData';

const CourseOverview = ({ video }) => {
  const { instructor, description, benefits, ebook, suggestions, supplies } = video;

  return (
    <div id='learning-overview'>
      <Instructor instructor={instructorData[instructor]} />
      {
        ebook && 
        <CourseEbook ebook={ebook} />
      }
      <CourseDetails 
        title='Course Details'
        description={description}
      />
      {
        supplies && 
        <CourseBenefits 
        title="Things You Will Need"
        description={supplies}
        />
      }
      {
        benefits && 
        <CourseBenefits 
        title='Course Benefits'
        description={benefits}
        />
      }
      {
        suggestions && 
        <CourseBenefits 
        title={`${instructor.split(' ')[0]}'s Recommendations`}
        description={suggestions}
        />
      }
    </div>
  )
};

export default CourseOverview;