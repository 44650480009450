const instructorData = {
  'Lindsay Finnie': {
    name: 'Lindsay Finnie',
    shortTitle: 'RMT',
    title: 'Registered Massage Therapist (RMT)',
    bio: "Hi! I'm Lindsay (she/her). I found massage therapy when I was lost in an eating disorder. It was an unexpected experience but massage allowed me to slow down and learn to connect with my body and it quickly became integral to my recovery. Through my journey to becoming an RMT, I discovered a deep love working with the perinatal population and I've dedicated most of my professional career to learning all I can about pregnancy and childbirth. It brings me great happiness to work with people during such a transformational time and to offer them support through therapeutic touch is a fulfillment like no other. This love has also led me to becoming a birth doula and soon-to-be childbirth educator. It's my goal to help create safer and braver spaces for my clients to have the best pregnancy, birth and postpartum experiences possible. When I am not massaging or doula-ing, I can be found paddling out on a lake, snuggling at home with my cat Seamus, knitting, or cooking in my far-too-small kitchen.",
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587257789/lindsay_portrait.jpg',
  },
  'Christina Gouveia': {
    name: 'Christina Gouveia',
    shortTitle: 'RMT',
    title: 'Registered Massage Therapist (RMT)',
    bio: 'I graduated from Sutherland Chan in 2011, and have had a special interest area in Therapeutic Breast Massage & Pregnancy massage! I have successfully treated those with abdominal wall scars, Caesarian scars, chest wall & breast tissue surgical procedures and post-surgical conditions such as lymphedema.',  
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588904035/christina_portrait.jpg',
  },
  'Jessica Noskiewicz': {
    name: 'Jessica Noskiewicz',
    shortTitle: 'RMT',
    title: 'Registered Massage Therapist (RMT)',
    bio: 'I started my massage therapy education in 2015 but took a hiatus when I became pregnant with my daughter. Following my maternity leave, I returned to the Canadian College of Massage and Hydrotherapy to complete my education and graduated in May 2019. As a new mom, I became particularly interested in learning more about perinatal massage as it helped me so much during my own pregnancy. I took a course through Trimesters: Massage Therapy Education to study under leading experts in perinatal massage and learn specific assessment skills and treatment techniques that can be utilized in pre- and post-natal massage.',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588904663/jessi_portrait.jpg'
  },
  'Sarah Rennick': {
    name: 'Sarah Rennick',
    shortTitle: 'RMT',
    img: 'https://lh5.googleusercontent.com/ZaeIeeKLspzHHvFM5p2O0K6RU2KjoMa8m4IcFcJ8DZzNOfbgPH5ft4BrO0F8efCRyYyjO3EvRdwN5cLEf4OPsWA2-yoNfC1AkJhyT6S1AwkfFY-Ei9CJuu_xZy8sSniTBDJzr1iy',
    title: 'Registered Massage Therapist (RMT), Co-Founder of Mama Mobile',
    bio: 'Hi, I’m Sarah one of the founders of Mama Mobile! I became a Registered Massage Therapist 6 years ago and it has always been my life work to support moms. I’m a certified doula, master infant massage instructor, personal trainer and my current role is to train/lead our incredible team. The idea for Mama Mobile came to me when I saw a mama trying to breastfeed in her car before she saw me in the clinic for an appointment. I wondered why there wasn’t a mobile clinic of specialists that could support her…. So I created it. We’ve since helped over 3000 mamas.',
  },
  'Patricia MacNeil': {
    name: 'Patricia MacNeil',
    bio: 'I’ve been an RMT since 1986! I designed and supervised pre and perinatal and newborn massage therapy training programs for 5 massage therapy colleges in the Ottawa area. These internships were held at the Civic and General campuses of the Ottawa Hospital system for 15 years.',
    shortTitle: 'RMT',
    title: 'Registered Massage Therapist (RMT)',
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1573280770/Faces_OM-_27_szjgku.jpg',
  },
  'Rebecca Eves': {
    name: 'Rebecca Eves',
    shortTitle: 'RMT',
    title: 'Registered Massage Therapist (RMT)',
    bio: "Hello new parents and families of Guelph, my name is Rebecca. I am inspired to support healthy families within our community as a Registered Massage Therapist and Certified Doula. Holding space for clinical relaxation is a gift I am grateful to be able to facilitate. I believe massage therapy can help increase a parent's capacity to deal with the stresses of every day life. Looking to foster peace in the world, I can think of no better place than by supporting those who are raising the next generation.",
    img: 'https://res.cloudinary.com/dhze7gimq/image/upload/v1588984262/rebecca_portrait.jpg',
  },
};

export default instructorData;