import React from 'react';
import Icon from '@material-ui/core/Icon';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import './BabyMassageCourse.css';
import { useHistory } from 'react-router-dom';
import FAQ from '../../components/FAQ/FAQ';
import BabyQuestionForm from '../../components/Form/BabyQuestionForm';
import Stars from './Stars';
import Feedback from './Feedback';
import { Button } from '@material-ui/core';
import Polaroid from '../../components/Polaroid/Polaroid';
import SEO from '../../components/SEO/SEO';

const faqs = [
  {
    question: 'What age does my baby need to be?',
    answer: `The ideal age for baby massage is +2 months. It is best to wait to begin when your baby is able to give you cues: cooing, eye contact etc. Generally at this time baby's attention span will be slightly longer as well.`,
  },
  {
    question: `What if I've never done baby massage before?`,
    answer: `We expect that most mamas taking this course have never done baby massage before! This course is taught by a Registered Massage Therapist and will teach you everything you need to know to safely and effectively massage your baby! No previous experience required.`,
  },
  {
    question: `What supplies do I need to do baby massage?`,
    answer: `During the introduction video we will go through the supplies you need. You will need a natural oil- we suggest using a cold-pressed cooking oil such as coconut oil, grape seed oil or apricot oil. You will also need a warm space and a soft comforting blanket for your baby to lay on during the massage.`,
  },
  {
    question: `What are the benefits of baby massage?`,
    answer: `Regular baby massage can soothe baby improving sleep quality and can be an important tool to cue sleep in a nap time/bedtime routine. Baby massage may also help improve weight gain, relieve constipation, improve circulation, ease teething pain, relieve blocked sinuses, improve immunity, and can be a great way to bond with your baby.`,
  },
  {
    question: `Will baby massage help with gas and constipation?`,
    answer: `Yes! Actually I made an entire routine dedicated to this. It's such a common issue, but it honestly doesn't need to be. This routine will break down how to improve digestion and techniques to use when your baby is really backed up- you know what I'm sayin'`,
  },
  {
    question: `Can my partner do baby massage too?`,
    answer: `YES! In fact, we highly recommend it. If mom is breastfeeding or spending more time with the baby this can be a great way to get your partner involved and give baby/partner a special bonding time.`,
  },
  {
    question: `When does the course start and finish?`,
    answer: `The course starts now and never ends! It is a completely self-paced online course - you decide when you start and when you finish.`
  },
  {
    question: `How long do I have access to the course?`,
    answer: `How does lifetime access sound? After enrolling, you have unlimited access to this course for as long as you like - across any and all devices you own.`
  },
  {
    question: `What if I am unhappy with the course?`,
    answer: `We would never want you to be unhappy! If you are unsatisfied with your purchase, contact us in the first 30 days and we will give you a full refund.`
  },
  {
    question: `I don’t have a lot of time… how much of a commitment is baby massage?`,
    answer: `That’s okay! All the videos are broken down by body part: You could spend 2 minutes on the feet today and 4 minutes on the belly tomorrow! Actually, studies show that introducing massage slowly is best as your baby’s attention span builds overtime/familiar cues.`,
  }
];

const BabyMassageCourse = () => {
  const history = useHistory();
  return (
    <div className='page-component' id='baby-massage'>
      <SEO 
        title='Online Baby Massage Course | Mama Mobile'
        description="Learn how to massage your baby for teething pain, constipation, blocked sinus, boost immune system and improve sleep from a Registered Massage Therapist!"
        url='baby-massage-course'
      />
      <ScrollToTop />
      <div className='baby-massage-hero'>
        <div className='baby-massage-hero-body center'>
          <div className='center'>
            <Icon>arrow_right</Icon>
            <h3>Baby Massage 101:</h3>
          </div>
          <h1>Learn How-To Massage Your Baby</h1>
          <Stars />
          <Button variant="contained" color="secondary" onClick={() => history.push('/checkout')}>Buy Now $68 (Value $349)</Button>
        </div>
        <Polaroid image='https://res.cloudinary.com/dhze7gimq/image/upload/v1590462577/baby_massage_2.png' alt='baby massage course' quote="I'm ready for my massage, mom!"/>
      </div>
      <img className='baby-101-problem' src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588282709/baby_101_problem_2.gif' alt='baby problems' />
      <div id='course-secrets'>
        <h2>Infant Massage to the Rescue</h2>
        <div>
          <p>
            The practice of mothers massaging their babies has been seen in many cultures worldwide for decades! It has been traditionally used to <b className='highlight'>calm, sooth and stimulate babies.</b> 
          </p>
          <p>
            Sarah, the founder of Mama Mobile has created a baby massage series based off of her research and working with common issues mothers just like you face.
          </p>
          <p>
            The practice of baby massage represents a simple but effective way to <b className='highlight'>enhance your baby's health</b> and <b className='highlight'>strengthen the bond that you share</b>.
            Research has also shown that baby massage is effective at helping postpartum depression recovery!
          </p>
        </div>
      </div>
      <div id='course-conversion'>
        <div>
          <h2>What are you getting?</h2>
          <div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>8-Part Step-By-Step Baby Massage Video Guide</p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>20-page Illustrated E-book</p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>Flexible access, refer back whenever and wherever</p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>Email Access to Instructor for Help</p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>30-Days Money Back Guarantee</p>
            </div>
          </div>
          <Button variant="contained" color="secondary" onClick={() => history.push('/checkout')}>Start Baby Massage</Button>
        </div>
        <div id='baby-demo'>
          <p><b>Watch the Demo</b></p>
          <iframe src="https://player.vimeo.com/video/413341804" style={{backgroundColor: 'black'}} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        </div>
      </div>
      <div id='course-detail'>
        <div id='course-detail-title'>
          <h4>inside</h4>
          <h3>The Baby Massage 101 (How-To and Support System)</h3>
          <h4 className='flex-end'>you will...</h4>
        </div>
        <div className='flex center'>
          <div className='course-detail-body'>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>
                Learn how to perform a relaxation massage on your baby to improve sleep
              </p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>
                Learn techniques that you can apply to baby's tummy to alleviate colic + constipation 
              </p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>
                Learn techniques that can help relive blocked sinuses and boost baby's immune system.
              </p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>
                Learn techniques to help relieve baby of pain due to teething 
              </p>
            </div>
            <div className='list-with-icon'>
              <Icon>done</Icon>
              <p>
                Learn how to make baby massage part of your everyday routine 
              </p>
            </div>
            <Button variant="contained" color="secondary" onClick={() => history.push('/checkout')} >Get the course now!</Button>
          </div>
          <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588273816/baby_101_price1.png' alt='mom massaging baby' />
        </div>
      </div>
      <Feedback />
      <div id='course-faq'>
        <h2>You Might Be Wondering... (FAQ)</h2>
        <div className='divider-thin yellow width-60' />
        {
          faqs.map((faq, idx) => <FAQ key={idx} question={faq.question} answer={faq.answer} />)
        }
      </div>
      <div id='course-question'>
        <img src='https://res.cloudinary.com/dhze7gimq/image/upload/v1588371218/question_illustration.jpg' alt='more questions'/>
        <div id='course-question-form'>
          <h2>Got more questions?</h2>
          <div className='divider-thin yellow width-60' />
          <BabyQuestionForm />
        </div>
      </div>
    </div>
  )
};

export default BabyMassageCourse;
