import React, { useState } from 'react';
import FAQ from '../FAQ/FAQ';

const locations = [
  {
    area: 'Toronto',
    subArea: [
      'Annex',
      'Avenue and Lawerance',
      'Bayview and Leaside',
      'Beaches',
      'Bloordale Village',
      'Bloorwest Village',
      'Brocktown Village',
      'Brampton',
      'Cabbagetown',
      'Cityplace',
      'Corktown',
      'Corso Italia',
      'Danforth',
      'Don Mills',
      'Downtown Toronto',
      'East York',
      'Eglington West',
      'Etobicoke',
      'Forest hill', 
      'Harbord Village',
      'High Park',
      'Junction',
      'Leslieville',
      'Liberty Village',
      'Mississauga',
      'North York',
      'Roncesvalles',
      'Rosedale',
      'Scarborough', 
      'St. Clair West', 
      'Yorkville'
    ],
  },
  {
    area: 'Greater Torotonto Area (GTA)',
    subArea: [
      'Ajax', 
      'Aurora',
      'Brampton', 
      'Caledon', 
      'Clarington',
      'East Gwillimbury',
      'Etobicoke',
      'Halton Hills',
      'Markham', 
      'Milton',
      'Mississauga',
      'Newmarket',
      'North York', 
      'Oakville', 
      'Oshawa', 
      'Pickering',
      'Richmond Hill', 
      'Scugog',
      'Toronto',
      'Uxbridge',
      'Vaughan',
      'Whitby',
      'Whitchurch-Stouffville',
      'York'
    ]
  },
  {
    area: 'Ottawa',
    subArea: [
      'Barrhaven',
      'Bayshore',
      'Byward Market',
      'Centretown', 
      'Gloucester',
      'Kanata',
      'Nepean',
      'Ottawa South', 
      'Orleans',
      'Stittsville',
      'Westboro',
    ],
  },
]
const Locations = () => {
  return (
    <div className='landing-component locations'>
      <h2 className='landing-title'>Locations</h2>
      <div className='locations-wrapper'>
        {locations.map((location, idx) => (
          <FAQ key={idx} question={location.area} answer={location.subArea} location />
        ))}
      </div>
    </div>
  )
}

export default Locations;