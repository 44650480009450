import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import './Carousel.css';

const jessi = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580627075/welcome/core_team.jpg';
const angela = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580415670/welcome/IMG_2896_2_p3uegb.jpg';
const christina = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1580495891/welcome/IMG_2882_se7yvb.jpg';
const rebecca = 'https://res.cloudinary.com/dhze7gimq/image/upload/v1587444858/welcome/team_yoga.jpg';

const items = [jessi, angela, christina, rebecca];

const FamilyCarousel = () => {
  const [mid, setMid] = useState(1);
  const before = mid !== 0 ? mid - 1 : items.length - 1; 
  const after = mid === items.length - 1 ? 0 : mid + 1; 

  const handleLeft = () => {
    if (mid === 0) {
      setMid(items.length - 1);
    } else {
      setMid(mid - 1);
    }
  };

  const handleRight = () => {
    if (mid === items.length - 1) {
      setMid(0);
    } else {
      setMid(mid + 1);
    }
  };

  return (
    <div className='carousel'>
      <Icon name='angle left' onClick={handleLeft} />
      <div className='carousel-img-wrapper'>
        <img src={items[before]} />
        <img className='carousel-middle' src={items[mid]}/>
        <img src={items[after]} />
      </div>
      <Icon name='angle right' onClick={handleRight}/>
    </div>
  )
};

export default FamilyCarousel; 